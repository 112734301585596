import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";
import styled from "styled-components";

import { isHomeSite } from "lib/legacy";

import { useWeb3React } from "@web3-react/core";

import APRLabel from "../APRLabel/APRLabel";
import { HeaderLink } from "../Header/HeaderLink";
import { CFX_TESTNET } from "config/chains";
import { switchNetwork } from "lib/wallets";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { getIcon } from "config/icons";

import MldsLogo from "img/l_mldslogo.png";
import MLPLogo from "img/l_MLPlogo.png";

export default function TokenCard({ showRedirectModal, redirectPopupTimestamp }) {
  const isHome = isHomeSite();
  const { chainId } = useChainId();
  const { active } = useWeb3React();

  const changeNetwork = useCallback(
    (network) => {
      if (network === chainId) {
        return;
      }
      if (!active) {
        setTimeout(() => {
          return switchNetwork(network, active);
        }, 500);
      } else {
        return switchNetwork(network, active);
      }
    },
    [chainId, active]
  );

  const BuyLink = ({ className, to, children, network }) => {
    if (isHome && showRedirectModal) {
      return (
        <HeaderLink
          to={to}
          className={className}
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          {children}
        </HeaderLink>
      );
    }

    return (
      <Link to={to} className={className} onClick={() => changeNetwork(network)}>
        {children}
      </Link>
    );
  };

  return (
    <div className="Home-token-card-options">
      {/* <div className="Home-token-card-option">
        <div className="Home-token-card-option-icon">
          <img src={MldsLogo} width="42" alt="MLDS Icons" /> MLDS
        </div>
        <div className="Home-token-card-option-info">
          <div className="Home-token-card-option-title">
            <Trans>
              MLDS is a utility and governance token, holding MLDS can share the revenue and governance rights of the
              platform.
            </Trans>
          </div>
          <div className="Home-token-card-option-action">
            <div className="buy">
              <BuyLink className="default-btn">
                <Trans>Buy</Trans>
              </BuyLink>
            </div>
          </div>
        </div>
      </div> */}
      <div className="Home-token-card-option">
        <div className="Home-token-card-option-icon">
          <img src={MLPLogo} width="42" alt="MLP Icon" /> MLP
        </div>
        <div className="Home-token-card-option-info">
          <div className="Home-token-card-option-title">
            <Trans>
              MLP is the liquidity provider token, which will index all liquidity asset pools and share the platform's
              revenue.
            </Trans>
          </div>
          <div className="Home-token-card-option-action">
            <div className="buy">
              <BuyLink to="/buy_MLP" className="default-btn" network={CFX_TESTNET}>
                <Trans>Buy</Trans>
              </BuyLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const StyledReadMore = styled.a`
  font-size: 1.6rem;
  font-weight: 400;
  color: #fa9b00;
  border-bottom: 1px solid;
  text-decoration: none;
`;
