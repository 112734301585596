import React, { forwardRef } from "react";
import cx from "classnames";
import "./ExternalLink.scss";

type Props = {
  href: string;
  children: React.ReactNode;
  className?: string;
  onClick?: any;
};

const ExternalLink = forwardRef(({ href, children, className, onClick }: Props, ref) => {
  const classNames = cx("link-underline", className);
  return (
    <a href={href} className={classNames} target="_blank" onClick={onClick} rel="noopener noreferrer">
      {children}
    </a>
  );
});

export default ExternalLink;
