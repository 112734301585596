import { CFX_TESTNET, LOCALHOST } from "./chains";

export const SUBGRAPH_URLS = {
  [CFX_TESTNET]: {
    stats: "",
    referrals: "",
    nissohVault: "",
  },

  [LOCALHOST]: {
    stats: "",
    referrals: "",
  },

  common: {
    chainLink: "https://api.thegraph.com/subgraphs/name/deividask/chainlink",
  },
};
