import React, { useState, useCallback, useEffect, useMemo } from "react";
import { ethers } from "ethers";
import useSWR from "swr";
import cx from "classnames";
import { Trans, t } from "@lingui/macro";
import { BsArrowRight } from "react-icons/bs";
import { Menu } from "@headlessui/react";
import { FaChevronDown } from "react-icons/fa";

import Slider, { SliderTooltip } from "rc-slider";
import "rc-slider/assets/index.css";

import {
  DEFAULT_SLIPPAGE_AMOUNT,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  USD_DECIMALS,
  DUST_USD,
  BASIS_POINTS_DIVISOR,
  MIN_PROFIT_TIME,
  getLiquidationPrice,
  getLeverage,
  getMarginFee,
  PRECISION,
  MARKET,
  STOP,
  DECREASE,
  calculatePositionDelta,
  getDeltaStr,
  getProfitPrice,
  getNextToAmount,
  USDM_DECIMALS,
  adjustForDecimals,
  isAddressZero,
  MAX_ALLOWED_LEVERAGE,
} from "lib/legacy";
import { getChainName, getConstant, IS_NETWORK_DISABLED } from "config/chains";
import { createDecreaseOrder, useHasOutdatedUi } from "domain/legacy";
import { getContract } from "config/contracts";
import { getToken } from "config/tokens";

import PositionManager from "abis/PositionManager.json";
import OrderBook from "abis/OrderBook.json";

import Checkbox from "../Checkbox/Checkbox";
import Tab from "../Tab/Tab";
import Modal from "../Modal/Modal";
import ExchangeInfoRow from "./ExchangeInfoRow";
import Tooltip from "../Tooltip/Tooltip";
import TokenSelector from "./TokenSelector";
import "./PositionSeller.css";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { callContract, contractFetcher } from "lib/contracts";

import { getTokenAmountFroMLP } from "domain/tokens";
import { useGetCalldataUpdateData, getUpdateFee } from "domain/legacy";
import { priceIds } from "config/backend";
import { TRIGGER_PREFIX_ABOVE, TRIGGER_PREFIX_BELOW } from "config/ui";
import { useLocalStorageByChainId, useLocalStorageSerializeKey } from "lib/localStorage";
import { CLOSE_POSITION_RECEIVE_TOKEN_KEY, SLIPPAGE_BPS_KEY } from "config/localStorage";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { usePrevious } from "lib/usePrevious";
import { bigNumberify, expandDecimals, formatAmount, formatAmountFree, parseValue } from "lib/numbers";
import { getTokens, getWrappedToken } from "config/tokens";
import { formatDateTime, getTimeRemaining } from "lib/dates";
import ExternalLink from "components/ExternalLink/ExternalLink";

const { AddressZero } = ethers.constants;
const ORDER_SIZE_DUST_USD = expandDecimals(1, USD_DECIMALS - 1); // $0.10

function shouldSwap(collateralToken, receiveToken) {
  const isCollateralWrapped = collateralToken.isNative;

  const isSameToken =
    collateralToken.address === receiveToken.address || (isCollateralWrapped && receiveToken.isWrapped);

  const isUnwrap = isCollateralWrapped && receiveToken.isNative;

  return !isSameToken && !isUnwrap;
}

function getSwapLimits(infoTokens, fromTokenAddress, toTokenAddress) {
  const fromInfo = getTokenInfo(infoTokens, fromTokenAddress);
  const toInfo = getTokenInfo(infoTokens, toTokenAddress);

  let maxInUsd;
  let maxIn;
  let maxOut;
  let maxOutUsd;

  if (!fromInfo?.maxUsdmAmount) {
    maxInUsd = bigNumberify(0);
    maxIn = bigNumberify(0);
  } else {
    maxInUsd = fromInfo.maxUsdmAmount
      .sub(fromInfo.musdAmount)
      .mul(expandDecimals(1, USD_DECIMALS))
      .div(expandDecimals(1, USDM_DECIMALS));

    maxIn = maxInUsd.mul(expandDecimals(1, fromInfo.decimals)).div(fromInfo.maxPrice).toString();
  }

  if (!toInfo?.poolAmount || !toInfo?.bufferAmount) {
    maxOut = bigNumberify(0);
    maxOutUsd = bigNumberify(0);
  } else {
    maxOut = toInfo.availableAmount.gt(toInfo.poolAmount.sub(toInfo.bufferAmount))
      ? toInfo.poolAmount.sub(toInfo.bufferAmount)
      : toInfo.availableAmount;

    maxOutUsd = getUsd(maxOut, toInfo.address, false, infoTokens);
  }

  return {
    maxIn,
    maxInUsd,
    maxOut,
    maxOutUsd,
  };
}

export default function PositionSeller(props) {
  const {
    pendingPositions,
    setPendingPositions,
    positionsMap,
    positionKey,
    isVisible,
    setIsVisible,
    account,
    library,
    infoTokens,
    setPendingTxns,
    flagOrdersEnabled,
    savedIsPnlInLeverage,
    chainId,
    nativeTokenAddress,
    orders,
    isWaitingForPluginApproval,
    isPluginApproving,
    setOrdersToaOpen,
    isWaitingForPositionManagerApproval,
    isPositionManagerApproving,
    approvePositionManager,
    isHigherSlippageAllowed,
    setIsHigherSlippageAllowed,
    minExecutionFee,
    minExecutionFeeErrorMessage,
    musdSupply,
    totalTokenWeights,
    isContractAccount,
    orderIndex,
  } = props;

  const [savedSlippageAmount] = useLocalStorageSerializeKey([chainId, SLIPPAGE_BPS_KEY], DEFAULT_SLIPPAGE_AMOUNT);
  const [keepLeverage, setKeepLeverage] = useLocalStorageSerializeKey([chainId, "Exchange-keep-leverage"], true);

  const position = positionsMap && positionKey ? positionsMap[positionKey] : undefined;

  const [fromValue, setFromValue] = useState("");

  const [isProfitWarningAccepted, setIsProfitWarningAccepted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const prevIsVisible = usePrevious(isVisible);
  const positionManagerAddress = getContract(chainId, "PositionManager");
  const orderBookAddress = getContract(chainId, "OrderBook");

  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");

  const longOrShortText = position?.isLong ? t`Long` : t`Short`;

  let toTokens = isContractAccount ? getTokens(chainId).filter((t) => !t.isNative) : getTokens(chainId);

  // test
  toTokens = toTokens.filter((token) => token.symbol === "USDT");

  const wrappedToken = getWrappedToken(chainId);

  const [savedRecieveTokenAddress, setSavedRecieveTokenAddress] = useLocalStorageByChainId(
    chainId,
    `${CLOSE_POSITION_RECEIVE_TOKEN_KEY}-${position.indexToken.symbol}-${position?.isLong ? "long" : "short"}`
  );

  const [swapToToken, setSwapToToken] = useState(
    () =>
      // savedRecieveTokenAddress ? toTokens.find((token) => token.address === savedRecieveTokenAddress) : undefined
      toTokens[0]
  );

  let allowedSlippage = savedSlippageAmount;
  if (isHigherSlippageAllowed) {
    allowedSlippage = DEFAULT_HIGHER_SLIPPAGE_AMOUNT;
  }

  let ORDER_OPTIONS = [MARKET, STOP];
  if (orderIndex) {
    ORDER_OPTIONS = [STOP];
  } else {
    ORDER_OPTIONS = [MARKET];
  }
  // const ORDER_OPTIONS = [];
  const ORDER_OPTION_LABELS = {
    [MARKET]: t`Market`,
    [STOP]: t`Trigger`,
  };
  let [orderOption, setOrderOption] = useState(ORDER_OPTIONS[0]);

  if (!flagOrdersEnabled) {
    orderOption = MARKET;
  }

  // const needPositionManagerApproval = !positionManagerApproved && orderOption === MARKET;

  const onOrderOptionChange = (option) => {
    setOrderOption(option);
  };

  const [leverageOption, setLeverageOption] = useState(100);

  const onTriggerPriceChange = (evt) => {
    setTriggerPriceValue(evt.target.value || "");
    // setLeverageOption(val);

    // let price = parseFloat(formatAmount(position.averagePrice, USD_DECIMALS, 2, false));

    // if (position?.isLong) {
    //   if (val > 100) {
    //     let value = price * (1 + (val - 100) / 100);
    //     setTriggerPriceValue(value);
    //   } else {
    //     let value = price * (1 - (100 - val) / 100);
    //     setTriggerPriceValue(value);
    //   }
    // } else {
    //   if (val > 100) {
    //     let value = price * (1 - (val - 100) / 100);
    //     setTriggerPriceValue(value);
    //   } else {
    //     let value = price * (1 + (100 - val) / 100);
    //     setTriggerPriceValue(value);
    //   }
    // }
  };

  const [triggerPriceValue, setTriggerPriceValue] = useState("");
  const triggerPriceUsd = orderOption === MARKET ? 0 : parseValue(triggerPriceValue, USD_DECIMALS);

  const [nextDelta, nextHasProfit = bigNumberify(0)] = useMemo(() => {
    if (!position) {
      return [bigNumberify(0), false];
    }

    if (orderOption !== STOP) {
      return [position.delta, position.hasProfit, position.deltaPercentage];
    }

    if (!triggerPriceUsd) {
      return [bigNumberify(0), false];
    }

    const { delta, hasProfit, deltaPercentage } = calculatePositionDelta(triggerPriceUsd, position);
    return [delta, hasProfit, deltaPercentage];
  }, [position, orderOption, triggerPriceUsd]);

  const existingOrders = useMemo(() => {
    if (orderOption === STOP && (!triggerPriceUsd || triggerPriceUsd.eq(0))) {
      return [];
    }
    if (!orders || !position) {
      return [];
    }

    const ret = [];
    for (const order of orders) {
      // only Stop orders can't be executed without corresponding opened position
      if (order.type !== DECREASE) continue;

      // if user creates Stop-Loss we need only Stop-Loss orders and vice versa
      if (orderOption === STOP) {
        const triggerAboveThreshold = triggerPriceUsd.gt(position.markPrice);
        if (triggerAboveThreshold !== order.triggerAboveThreshold) continue;
      }

      const sameToken =
        order.indexToken === nativeTokenAddress
          ? position.indexToken.isNative
          : order.indexToken === position.indexToken.address;
      if (order.isLong === position.isLong && sameToken) {
        ret.push(order);
      }
    }
    return ret;
  }, [position, orders, triggerPriceUsd, orderOption, nativeTokenAddress]);

  const existingOrder = existingOrders[0];

  // const needOrderBookApproval = orderOption === STOP && !orderBookApproved;

  const isSwapAllowed = orderOption === MARKET;

  // const { data: hasOutdatedUi } = useHasOutdatedUi();

  let collateralToken;
  let receiveToken;
  let maxAmount;
  let maxAmountFormatted;
  let maxAmountFormattedFree;
  let fromAmount;

  let convertedAmount;
  let convertedAmountFormatted;

  let nextLeverage;
  let liquidationPrice;
  let nextLiquidationPrice;
  let isClosing;
  let sizeDelta;

  let nextCollateral;
  let collateralDelta = bigNumberify(0);
  let receiveAmount = bigNumberify(0);
  let convertedReceiveAmount = bigNumberify(0);
  let adjustedDelta = bigNumberify(0);

  let isNotEnoughReceiveTokenLiquidity;
  let isCollateralPoolCapacityExceeded;

  let title;
  let fundingFee;
  let positionFee;
  let swapFeeToken;
  let swapFee;
  let totalFees = bigNumberify(0);

  useEffect(() => {
    if (isSwapAllowed && isContractAccount && isAddressZero(receiveToken.address)) {
      setSwapToToken(wrappedToken);
      setSavedRecieveTokenAddress(wrappedToken.address);
    }
  }, [
    isContractAccount,
    isSwapAllowed,
    nativeTokenSymbol,
    receiveToken?.address,
    wrappedToken,
    setSavedRecieveTokenAddress,
  ]);

  let executionFee = orderOption === STOP ? getConstant(chainId, "DECREASE_ORDER_EXECUTION_GAS_FEE") : minExecutionFee;

  let executionFeeUsd = getUsd(executionFee, nativeTokenAddress, false, infoTokens) || bigNumberify(0);

  if (position) {
    fundingFee = position.fundingFee;
    fromAmount = parseValue(fromValue, USD_DECIMALS);
    sizeDelta = fromAmount;

    title =
      orderOption === STOP
        ? `Close ${longOrShortText} ${position.indexToken.symbol} Trigger`
        : `Close ${longOrShortText} ${position.indexToken.symbol}`;
    collateralToken = position.collateralToken;
    // test
    // collateralToken = getTokenInfo(infoTokens, toTokens[0].address);
    // liquidationPrice = getLiquidationPrice(position);
    liquidationPrice = getLiquidationPrice(position)?.gt(0) ? getLiquidationPrice(position) : bigNumberify(0);

    if (fromAmount) {
      isClosing = position.size.sub(fromAmount).lt(DUST_USD);
      positionFee = getMarginFee(fromAmount);
    }

    if (isClosing) {
      sizeDelta = position.size;
      receiveAmount = position.collateral;
    } else if (orderOption === STOP && sizeDelta && existingOrders.length > 0) {
      let residualSize = position.size;
      for (const order of existingOrders) {
        residualSize = residualSize.sub(order.sizeDelta);
      }
      if (residualSize.sub(sizeDelta).abs().lt(ORDER_SIZE_DUST_USD)) {
        sizeDelta = residualSize;
      }
    }

    if (sizeDelta && position.size.gt(0)) {
      adjustedDelta = nextDelta.mul(sizeDelta).div(position.size);
    }

    if (nextHasProfit) {
      receiveAmount = receiveAmount.add(adjustedDelta);
    } else {
      if (receiveAmount.gt(adjustedDelta)) {
        receiveAmount = receiveAmount.sub(adjustedDelta);
      } else {
        receiveAmount = bigNumberify(0);
      }
    }

    if (keepLeverage && sizeDelta && !isClosing) {
      collateralDelta = sizeDelta.mul(position.collateral).div(position.size);
      // if the position will be realising a loss then reduce collateralDelta by the realised loss
      if (!nextHasProfit) {
        const deductions = adjustedDelta.add(positionFee).add(fundingFee);
        if (collateralDelta.gt(deductions)) {
          collateralDelta = collateralDelta = collateralDelta.sub(deductions);
        } else {
          collateralDelta = bigNumberify(0);
        }
      }
    }

    maxAmount = position.size;
    maxAmountFormatted = formatAmount(maxAmount, USD_DECIMALS, 2, true);
    maxAmountFormattedFree = formatAmountFree(maxAmount, USD_DECIMALS, 2);

    if (fromAmount && collateralToken.maxPrice) {
      convertedAmount = fromAmount.mul(expandDecimals(1, USD_DECIMALS)).div(collateralToken.maxPrice);
      convertedAmountFormatted = formatAmount(convertedAmount, USD_DECIMALS, 4, true);
    }

    totalFees = totalFees.add(positionFee || bigNumberify(0)).add(fundingFee || bigNumberify(0));

    receiveAmount = receiveAmount.add(collateralDelta);

    if (sizeDelta) {
      if (receiveAmount.gt(totalFees)) {
        receiveAmount = receiveAmount.sub(totalFees);
      } else {
        receiveAmount = bigNumberify(0);
      }
    }

    receiveToken = isSwapAllowed && swapToToken ? swapToToken : collateralToken;

    if (isSwapAllowed && isContractAccount && isAddressZero(receiveToken.address)) {
      receiveToken = wrappedToken;
    }

    // Calculate swap fees
    if (isSwapAllowed && swapToToken) {
      const { feeBasisPoints } = getNextToAmount(
        chainId,
        convertedAmount,
        collateralToken.address,
        receiveToken.address,
        infoTokens,
        undefined,
        undefined,
        musdSupply,
        totalTokenWeights,
        true
      );

      if (feeBasisPoints) {
        swapFee = receiveAmount.mul(feeBasisPoints).div(BASIS_POINTS_DIVISOR);
        swapFeeToken = getTokenAmountFroMLP(infoTokens, collateralToken.address, swapFee);
        totalFees = totalFees.add(swapFee || bigNumberify(0));
        receiveAmount = receiveAmount.sub(swapFee);
      }
    }

    // For Shorts trigger orders the collateral is a stable coin, it should not depend on the triggerPrice
    if (orderOption === STOP && position.isLong) {
      convertedReceiveAmount = getTokenAmountFroMLP(infoTokens, receiveToken.address, receiveAmount, {
        overridePrice: triggerPriceUsd,
      });
    } else {
      convertedReceiveAmount = getTokenAmountFroMLP(infoTokens, receiveToken.address, receiveAmount);
    }

    // Check swap limits (max in / max out)
    if (isSwapAllowed && shouldSwap(collateralToken, receiveToken)) {
      const collateralInfo = getTokenInfo(infoTokens, collateralToken.address);
      const receiveTokenInfo = getTokenInfo(infoTokens, receiveToken.address);

      isNotEnoughReceiveTokenLiquidity =
        receiveTokenInfo.availableAmount.lt(convertedReceiveAmount) ||
        receiveTokenInfo.bufferAmount.gt(receiveTokenInfo.poolAmount.sub(convertedReceiveAmount));

      if (
        collateralInfo.maxUsdmAmount &&
        collateralInfo.maxUsdmAmount.gt(0) &&
        collateralInfo.musdAmount &&
        collateralInfo.maxPrice
      ) {
        const musdFromAmount = adjustForDecimals(receiveAmount, USD_DECIMALS, USDM_DECIMALS);
        const nextUsdmAmount = collateralInfo.musdAmount.add(musdFromAmount);

        if (nextUsdmAmount.gt(collateralInfo.maxUsdmAmount)) {
          isCollateralPoolCapacityExceeded = true;
        }
      }
    }

    if (isClosing) {
      nextCollateral = bigNumberify(0);
    } else {
      if (position.collateral) {
        nextCollateral = position.collateral;
        if (collateralDelta && collateralDelta.gt(0)) {
          nextCollateral = position.collateral.sub(collateralDelta);
        } else if (position.delta && position.delta.gt(0) && sizeDelta) {
          if (!position.hasProfit) {
            nextCollateral = nextCollateral.sub(adjustedDelta);
          }
        }
      }
    }

    if (fromAmount) {
      if (!isClosing && !keepLeverage) {
        nextLeverage = getLeverage({
          size: position.size,
          sizeDelta,
          collateral: position.collateral,
          entryFundingRate: position.entryFundingRate,
          cumulativeFundingRate: position.cumulativeFundingRate,
          hasProfit: nextHasProfit,
          delta: nextDelta,
          includeDelta: savedIsPnlInLeverage,
        });
        nextLiquidationPrice = getLiquidationPrice({
          isLong: position.isLong,
          size: position.size,
          sizeDelta,
          collateral: position.collateral,
          averagePrice: position.averagePrice,
          entryFundingRate: position.entryFundingRate,
          cumulativeFundingRate: position.cumulativeFundingRate,
          delta: nextDelta,
          hasProfit: nextHasProfit,
          includeDelta: true,
        });
      }
    }
  }

  const [deltaStr, deltaPercentageStr] = useMemo(() => {
    if (!position || !position.markPrice || position.collateral.eq(0)) {
      return ["-", "-"];
    }
    if (orderOption !== STOP) {
      const { pendingDelta, pendingDeltaPercentage, hasProfit } = calculatePositionDelta(
        position.markPrice,
        position,
        fromAmount
      );
      const { deltaStr, deltaPercentageStr } = getDeltaStr({
        delta: pendingDelta,
        deltaPercentage: pendingDeltaPercentage,
        hasProfit,
      });
      return [deltaStr, deltaPercentageStr];
    }
    if (!triggerPriceUsd || triggerPriceUsd.eq(0)) {
      return ["-", "-"];
    }

    const { pendingDelta, pendingDeltaPercentage, hasProfit } = calculatePositionDelta(
      triggerPriceUsd,
      position,
      fromAmount
    );

    const { deltaStr, deltaPercentageStr } = getDeltaStr({
      delta: pendingDelta,
      deltaPercentage: pendingDeltaPercentage,
      hasProfit,
    });
    return [deltaStr, deltaPercentageStr];
  }, [position, triggerPriceUsd, orderOption, fromAmount]);

  const getError = () => {
    if (isSwapAllowed && isContractAccount && isAddressZero(receiveToken?.address)) {
      return t`${nativeTokenSymbol} can not be sent to smart contract addresses. Select another token.`;
    }
    if (IS_NETWORK_DISABLED[chainId]) {
      if (orderOption === STOP) return [t`Trigger order disabled, pending ${getChainName(chainId)} upgrade`];
      return [t`Position close disabled, pending ${getChainName(chainId)} upgrade`];
    }
    // if (hasOutdatedUi) {
    //   return t`Page outdated, please refresh`;
    // }
    if (!fromAmount) {
      return t`Enter an amount`;
    }
    if (nextLeverage && nextLeverage.eq(0)) {
      return t`Enter an amount`;
    }
    if (orderOption === STOP) {
      if (!triggerPriceUsd || triggerPriceUsd.eq(0)) {
        return t`Enter Price`;
      }
      if (position.isLong && triggerPriceUsd.lte(liquidationPrice)) {
        return t`Price below Liq. Price`;
      }
      if (!position.isLong && triggerPriceUsd.gte(liquidationPrice)) {
        return t`Price above Liq. Price`;
      }

      if (profitPrice && nextDelta.eq(0) && nextHasProfit) {
        return t`Invalid price, see warning`;
      }
    }

    if (isNotEnoughReceiveTokenLiquidity) {
      return t`Insufficient receive token liquidity`;
    }

    if (isCollateralPoolCapacityExceeded) {
      return t`${collateralToken.symbol} pool exceeded, can only Receive ${collateralToken.symbol}`;
    }

    if (!isClosing && position && position.size && fromAmount) {
      if (position.size.sub(fromAmount).lt(expandDecimals(10, USD_DECIMALS))) {
        return t`Leftover position below 10 USD`;
      }
      if (nextCollateral && nextCollateral.lt(expandDecimals(5, USD_DECIMALS))) {
        return t`Leftover collateral below 5 USD`;
      }
    }

    if (position && position.size && position.size.lt(fromAmount)) {
      return t`Max close amount exceeded`;
    }

    if (nextLeverage && nextLeverage.lt(1.1 * BASIS_POINTS_DIVISOR)) {
      return t`Min leverage: 1.1x`;
    }

    if (nextLeverage && nextLeverage.gt(MAX_ALLOWED_LEVERAGE)) {
      return t`Max leverage: ${(MAX_ALLOWED_LEVERAGE / BASIS_POINTS_DIVISOR).toFixed(1)}x`;
    }

    if (hasPendingProfit && orderOption !== STOP && !isProfitWarningAccepted) {
      return t`Forfeit profit not checked`;
    }
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isSubmitting) {
      return false;
    }
    // if (needOrderBookApproval && isWaitingForPluginApproval) {
    //   return false;
    // }
    if (isPluginApproving) {
      return false;
    }
    // if (needPositionManagerApproval && isWaitingForPositionManagerApproval) {
    //   return false;
    // }
    if (isPositionManagerApproving) {
      return false;
    }

    return true;
  };

  const hasPendingProfit = MIN_PROFIT_TIME > 0 && position.delta.eq(0) && position.pendingDelta.gt(0);

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }

    if (orderOption === STOP) {
      if (isSubmitting) return t`Creating Trigger Order...`;

      // if (needOrderBookApproval && isWaitingForPluginApproval) {
      //   return t`Enabling Orders...`;
      // }
      // if (isPluginApproving) {
      //   return t`Enabling Orders...`;
      // }
      // if (needOrderBookApproval) {
      //   return t`Enable Orders`;
      // }

      return t`Create Trigger Order`;
    }

    // if (needPositionManagerApproval && isWaitingForPositionManagerApproval) {
    //   return t`Enabling Leverage...`;
    // }

    // if (isPositionManagerApproving) {
    //   return t`Enabling Leverage...`;
    // }

    // if (needPositionManagerApproval) {
    //   return t`Enable Leverage`;
    // }

    if (hasPendingProfit) {
      return t`Close without profit`;
    }
    return isSubmitting ? t`Closing...` : t`Close`;
  };

  const resetForm = () => {
    // setFromValue(maxAmountFormattedFree);
    setIsProfitWarningAccepted(false);
  };

  useEffect(() => {
    if (prevIsVisible !== isVisible) {
      resetForm();
    }
  }, [prevIsVisible, isVisible]);

  const { data: index } = useSWR(account && ["", chainId, orderBookAddress, "increaseOrdersIndex"], {
    fetcher: contractFetcher(library, OrderBook, [account]),
  });

  const onClickPrimary = async () => {
    // if (needOrderBookApproval) {
    //   setOrdersToaOpen(true);
    //   return;
    // }
    const isShow = localStorage.getItem(JSON.stringify([chainId, "show-orders-toa"]));

    if (index.toString() === "0" && !isShow && orderIndex) {
      setOrdersToaOpen(true);
      return;
    }

    // if (needPositionManagerApproval) {
    //   approvePositionManager({
    //     sentMsg: t`Enable leverage sent.`,
    //     failMsg: t`Enable leverage failed.`,
    //   });
    //   return;
    // }

    setIsSubmitting(true);

    const collateralTokenAddress = position.collateralToken.isNative
      ? nativeTokenAddress
      : position.collateralToken.address;
    const indexTokenAddress = position.indexToken.isNative ? nativeTokenAddress : position.indexToken.address;

    if (orderOption === STOP) {
      const triggerAboveThreshold = triggerPriceUsd.gt(position.markPrice);

      createDecreaseOrder(
        chainId,
        library,
        indexTokenAddress,
        sizeDelta,
        collateralTokenAddress,
        collateralDelta,
        position.isLong,
        triggerPriceUsd,
        triggerAboveThreshold,
        {
          sentMsg: t`Order submitted!`,
          successMsg: t`Order created!`,
          failMsg: t`Order creation failed.`,
          setPendingTxns,
        }
      )
        .then(() => {
          setFromValue("");
          setIsVisible(false);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
      return;
    }

    const priceBasisPoints = position.isLong
      ? BASIS_POINTS_DIVISOR - allowedSlippage
      : BASIS_POINTS_DIVISOR + allowedSlippage;
    const refPrice = position.isLong ? position.indexToken.minPrice : position.indexToken.maxPrice;
    let priceLimit = refPrice.mul(priceBasisPoints).div(BASIS_POINTS_DIVISOR);
    const minProfitExpiration = position.lastIncreasedTime + MIN_PROFIT_TIME;
    const minProfitTimeExpired = parseInt(Date.now() / 1000) > minProfitExpiration;

    if (nextHasProfit && !minProfitTimeExpired && !isProfitWarningAccepted) {
      if ((position.isLong && priceLimit.lt(profitPrice)) || (!position.isLong && priceLimit.gt(profitPrice))) {
        priceLimit = profitPrice;
      }
    }

    const tokenAddress0 = collateralTokenAddress === AddressZero ? nativeTokenAddress : collateralTokenAddress;

    const path = [tokenAddress0];

    const isUnwrap = receiveToken.address === AddressZero;
    const isSwap = receiveToken.address !== tokenAddress0;

    if (isSwap) {
      if (isUnwrap && tokenAddress0 !== nativeTokenAddress) {
        path.push(nativeTokenAddress);
      } else if (!isUnwrap) {
        path.push(receiveToken.address);
      }
    }

    const withdrawETH = isUnwrap && !isContractAccount;

    const address = [...path, indexTokenAddress];
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const calldata_updateData = await useGetCalldataUpdateData(chainId, address);

    const params = [
      path, // _path
      indexTokenAddress, // _indexToken
      collateralDelta, // _collateralDelta
      sizeDelta, // _sizeDelta
      position.isLong, // _isLong
      account, // _receiver
      priceLimit, // _acceptablePrice
      0, // _minOut
      // minExecutionFee, // _executionFee
      withdrawETH, // _withdrawETH
      calldata_updateData,
      // AddressZero, // _callbackTarget
    ];
    const sizeDeltaUsd = formatAmount(sizeDelta, USD_DECIMALS, 2);
    const successMsg = t`Requested decrease of ${position.indexToken.symbol} ${longOrShortText} by ${sizeDeltaUsd} USD.`;

    const contract = new ethers.Contract(positionManagerAddress, PositionManager.abi, library.getSigner());
    let value = await getUpdateFee(chainId, library, calldata_updateData);

    callContract(chainId, contract, "decreasePosition", params, {
      value,
      sentMsg: t`Close submitted!`,
      successMsg,
      failMsg: t`Close failed.`,
      setPendingTxns,
      // for Arbitrum, sometimes the successMsg shows after the position has already been executed
      // hide the success message for Arbitrum as a workaround
      // hideSuccessMsg: chainId === ARBITRUM,
      hideSuccessMsg: false,
    })
      .then(async (res) => {
        setFromValue("");
        setIsVisible(false);

        let nextSize = position.size.sub(sizeDelta);

        pendingPositions[position.key] = {
          updatedAt: Date.now(),
          pendingChanges: {
            size: nextSize,
          },
        };

        setPendingPositions({ ...pendingPositions });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const renderExistingOrderWarning = useCallback(() => {
    if (!existingOrder) {
      return;
    }
    const indexToken = getTokenInfo(infoTokens, existingOrder.indexToken);
    const sizeInToken = formatAmount(
      existingOrder.sizeDelta.mul(PRECISION).div(existingOrder.triggerPrice),
      USD_DECIMALS,
      4,
      true
    );
    const prefix = existingOrder.triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
    return (
      <div className="Confirmation-box-warning">
        <Trans>
          You have an active order to decrease {longOrShortText} {sizeInToken} {indexToken.symbol} ($
          {formatAmount(existingOrder.sizeDelta, USD_DECIMALS, 2, true)}) at {prefix}{" "}
          {formatAmount(existingOrder.triggerPrice, USD_DECIMALS, 2, true)}
        </Trans>
      </div>
    );
  }, [existingOrder, infoTokens, longOrShortText]);

  function renderMinProfitWarning() {
    if (MIN_PROFIT_TIME === 0) {
      return null;
    }

    if (profitPrice && nextDelta.eq(0) && nextHasProfit) {
      const minProfitExpiration = position.lastIncreasedTime + MIN_PROFIT_TIME;

      if (orderOption === MARKET) {
        return (
          <div className="Confirmation-box-warning">
            <Trans>
              Reducing the position at the current price will forfeit a&nbsp;
              <ExternalLink href="https://docs.mold.trade/">pending profit</ExternalLink> of {deltaStr}. <br />
            </Trans>
            <Trans>
              <br />
              Profit price: {position.isLong ? ">" : "<"} ${formatAmount(profitPrice, USD_DECIMALS, 2, true)}. This rule
              applies for the next {getTimeRemaining(minProfitExpiration)}, until {formatDateTime(minProfitExpiration)}.
            </Trans>
          </div>
        );
      }
      return (
        <div className="Confirmation-box-warning">
          <Trans>
            This order will forfeit a&nbsp;
            <ExternalLink href="https://docs.mold.trade/">profit</ExternalLink> of {deltaStr}. <br />
          </Trans>
          <Trans>
            Profit price: {position.isLong ? ">" : "<"} ${formatAmount(profitPrice, USD_DECIMALS, 2, true)}. This rule
            applies for the next {getTimeRemaining(minProfitExpiration)}, until {formatDateTime(minProfitExpiration)}.
          </Trans>
        </div>
      );
    }
  }

  const profitPrice = getProfitPrice(orderOption === MARKET ? position.markPrice : triggerPriceUsd, position);

  let triggerPricePrefix;
  if (triggerPriceUsd) {
    triggerPricePrefix = triggerPriceUsd.gt(position.markPrice) ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
  }

  const shouldShowExistingOrderWarning = false;

  if (orderOption === STOP && !triggerPriceUsd) {
    receiveAmount = bigNumberify(0);
    convertedReceiveAmount = bigNumberify(0);
  }

  const leverageSliderHandle = (props) => {
    const { value, dragging, index, ...restProps } = props;

    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${(parseFloat(value) - 100).toFixed(2)}%`}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Slider.Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };

  return (
    <div className="PositionEditor">
      {position && (
        <Modal
          className="PositionSeller-modal"
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label={title}
          allowContentTouchMove
        >
          {/* {flagOrdersEnabled && (
            <Tab
              options={ORDER_OPTIONS}
              option={orderOption}
              optionLabels={ORDER_OPTION_LABELS}
              onChange={onOrderOptionChange}
            />
          )} */}
          <div className="Exchange-swap-section">
            <div className="Exchange-swap-section-top">
              <div className="muted">
                {convertedAmountFormatted && (
                  <div className="Exchange-swap-usd">
                    <Trans>
                      Close: {convertedAmountFormatted} {position.collateralToken.symbol}
                    </Trans>
                  </div>
                )}
                {!convertedAmountFormatted && t`Close`}
              </div>
              {maxAmount && (
                <div className="muted align-right clickable" onClick={() => setFromValue(maxAmountFormattedFree)}>
                  Max: {"$"}
                  {maxAmountFormatted}
                </div>
              )}
            </div>
            <div className="Exchange-swap-section-bottom">
              <div className="Exchange-swap-input-container">
                <input
                  type="number"
                  min="0"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={fromValue}
                  onChange={(e) => setFromValue(e.target.value)}
                />
                {fromValue !== maxAmountFormattedFree && (
                  <div
                    className="Exchange-swap-max"
                    onClick={() => {
                      setFromValue(maxAmountFormattedFree);
                    }}
                  >
                    <Trans>MAX</Trans>
                  </div>
                )}
              </div>
              <div className="PositionEditor-token-symbol">USD</div>
            </div>
          </div>
          {orderOption === STOP && (
            <div className="Exchange-swap-section">
              <div className="Exchange-swap-section-top">
                <div className="trigger-menu">Price</div>
                <div
                  className="muted align-right clickable"
                  onClick={() => {
                    setTriggerPriceValue(formatAmountFree(position.markPrice, USD_DECIMALS, 2));
                  }}
                >
                  Mark Price: ${formatAmount(position.markPrice, USD_DECIMALS, 2, false)}
                </div>
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={triggerPriceValue}
                    onChange={onTriggerPriceChange}
                  />
                </div>
                <div className="PositionEditor-token-symbol">USD</div>
                {/* <Slider
                  min={0}
                  max={200}
                  step={0.1}
                  value={leverageOption}
                  marks={{ 0: "-100%", 100: formatAmount(position.markPrice, USD_DECIMALS, 2, false), 200: "+100%" }}
                  handle={leverageSliderHandle}
                  onChange={(value) => onTriggerPriceChange(value)}
                  startPoint={100}
                /> */}
              </div>
              {/* <p>Trigger Price: {triggerPriceValue && triggerPriceValue.toFixed(2)}</p> */}
            </div>
          )}
          {renderMinProfitWarning()}
          {shouldShowExistingOrderWarning && renderExistingOrderWarning()}
          <div className="PositionEditor-info-box">
            {minExecutionFeeErrorMessage && (
              <div className="Confirmation-box-warning">{minExecutionFeeErrorMessage}</div>
            )}
            {hasPendingProfit && orderOption !== STOP && (
              <div className="PositionEditor-accept-profit-warning">
                <Checkbox isChecked={isProfitWarningAccepted} setIsChecked={setIsProfitWarningAccepted}>
                  <span className="muted">Forfeit profit</span>
                </Checkbox>
              </div>
            )}
            <div className="PositionEditor-keep-leverage-settings">
              <Checkbox isChecked={keepLeverage} setIsChecked={setKeepLeverage}>
                <span className="muted font-sm">
                  <Trans>Keep leverage at {formatAmount(position.leverage, 4, 2)}x</Trans>
                </span>
              </Checkbox>
            </div>
            {orderOption === MARKET && (
              <div className="PositionEditor-allow-higher-slippage">
                <Checkbox isChecked={isHigherSlippageAllowed} setIsChecked={setIsHigherSlippageAllowed}>
                  <span className="muted font-sm">
                    <Trans>Allow up to 1% slippage</Trans>
                  </span>
                </Checkbox>
              </div>
            )}
            {orderOption === MARKET && (
              <div>
                <ExchangeInfoRow label={t`Allowed Slippage`}>
                  <Tooltip
                    handle={`${formatAmount(allowedSlippage, 2, 2)}%`}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <Trans>
                          You can change this in the settings menu on the top right of the page.
                          <br />
                          <br />
                          Note that a low allowed slippage, e.g. less than 0.5%, may result in failed orders if prices
                          are volatile.
                        </Trans>
                      );
                    }}
                  />
                </ExchangeInfoRow>
              </div>
            )}
            {orderOption === STOP && (
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <Trans>Trigger Price</Trans>
                </div>
                <div className="align-right">
                  {!triggerPriceUsd && "-"}
                  {triggerPriceUsd && `${triggerPricePrefix} ${formatAmount(triggerPriceUsd, USD_DECIMALS, 4, true)}`}
                </div>
              </div>
            )}
            <div className="Exchange-info-row top-line">
              <div className="Exchange-info-label">
                <Trans>Mark Price</Trans>
              </div>
              <div className="align-right">${formatAmount(position.markPrice, USD_DECIMALS, 4, true)}</div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>Entry Price</Trans>
              </div>
              <div className="align-right">${formatAmount(position.averagePrice, USD_DECIMALS, 4, true)}</div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>Liq. Price</Trans>
              </div>
              <div className="align-right">
                {isClosing && orderOption !== STOP && "-"}
                {(!isClosing || orderOption === STOP) && (
                  <div>
                    {(!nextLiquidationPrice || nextLiquidationPrice.eq(liquidationPrice)) && (
                      <div>{`$${formatAmount(liquidationPrice, USD_DECIMALS, 4, true)}`}</div>
                    )}
                    {nextLiquidationPrice && !nextLiquidationPrice.eq(liquidationPrice) && (
                      <div>
                        <div className="inline-block muted">
                          ${formatAmount(liquidationPrice, USD_DECIMALS, 4, true)}
                          <BsArrowRight className="transition-arrow" />
                        </div>
                        ${formatAmount(nextLiquidationPrice, USD_DECIMALS, 4, true)}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="Exchange-info-row top-line">
              <div className="Exchange-info-label">
                <Trans>Size</Trans>
              </div>
              <div className="align-right">
                {position && position.size && fromAmount && (
                  <div>
                    <div className="inline-block muted">
                      ${formatAmount(position.size, USD_DECIMALS, 2, true)}
                      <BsArrowRight className="transition-arrow" />
                    </div>
                    ${formatAmount(position.size.sub(fromAmount), USD_DECIMALS, 2, true)}
                  </div>
                )}
                {position && position.size && !fromAmount && (
                  <div>${formatAmount(position.size, USD_DECIMALS, 2, true)}</div>
                )}
              </div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>Collateral ({collateralToken.symbol})</Trans>
              </div>
              <div className="align-right">
                {nextCollateral && !nextCollateral.eq(position.collateral) ? (
                  <div>
                    <div className="inline-block muted">
                      ${formatAmount(position.collateral, USD_DECIMALS, 2, true)}
                      <BsArrowRight className="transition-arrow" />
                    </div>
                    ${formatAmount(nextCollateral, USD_DECIMALS, 2, true)}
                  </div>
                ) : (
                  `$${formatAmount(position.collateral, USD_DECIMALS, 4, true)}`
                )}
              </div>
            </div>
            {!keepLeverage && (
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <Trans>Leverage</Trans>
                </div>
                <div className="align-right">
                  {isClosing && "-"}
                  {!isClosing && (
                    <div>
                      {!nextLeverage && <div>{formatAmount(position.leverage, 4, 2)}x</div>}
                      {nextLeverage && (
                        <div>
                          <div className="inline-block muted">
                            {formatAmount(position.leverage, 4, 2)}x
                            <BsArrowRight className="transition-arrow" />
                          </div>
                          {formatAmount(nextLeverage, 4, 2)}x
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>PnL</Trans>
              </div>
              <div className="align-right">
                {deltaStr} ({deltaPercentageStr})
              </div>
            </div>

            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>Fees</Trans>
              </div>
              <div className="align-right">
                <Tooltip
                  position="right-top"
                  className="PositionSeller-fees-tooltip"
                  handle={
                    <div>
                      {totalFees ? `$${formatAmount(totalFees.add(executionFeeUsd), USD_DECIMALS, 2, true)}` : "-"}
                    </div>
                  }
                  renderContent={() => (
                    <div>
                      {fundingFee && (
                        <StatsTooltipRow
                          label={t`Borrow Fee`}
                          value={formatAmount(fundingFee, USD_DECIMALS, 2, true)}
                        />
                      )}

                      {positionFee && (
                        <StatsTooltipRow
                          label={t`Closing Fee`}
                          value={formatAmount(positionFee, USD_DECIMALS, 2, true)}
                        />
                      )}

                      {swapFee && (
                        <StatsTooltipRow
                          label={t`Swap Fee`}
                          showDollar={false}
                          value={`${formatAmount(swapFeeToken, collateralToken.decimals, 5)} ${collateralToken.symbol}
                           ($${formatAmount(swapFee, USD_DECIMALS, 2, true)})`}
                        />
                      )}

                      {orderOption === STOP && (
                        <StatsTooltipRow
                          label={t`Execution Fee`}
                          showDollar={false}
                          value={`${formatAmount(executionFee, 18, 5, true)} ${nativeTokenSymbol} ($${formatAmount(
                            executionFeeUsd,
                            USD_DECIMALS,
                            2
                          )})`}
                        />
                      )}

                      {/* <div className="PositionSeller-fee-item">
                        <Trans>
                          <ExternalLink href="https://docs.mold.trade/">More Info</ExternalLink> about fees.
                        </Trans>
                      </div> */}
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="Exchange-info-row PositionSeller-receive-row top-line">
              <div className="Exchange-info-label">
                <Trans>Receive</Trans>
              </div>

              {!isSwapAllowed && receiveToken && (
                <div className="align-right PositionSelector-selected-receive-token">
                  {formatAmount(convertedReceiveAmount, receiveToken.decimals, 4, true)}
                  &nbsp;{receiveToken.symbol} ($
                  {formatAmount(receiveAmount, USD_DECIMALS, 2, true)})
                </div>
              )}

              {isSwapAllowed && receiveToken && (
                <div className="align-right">
                  <TokenSelector
                    // Scroll lock lead to side effects
                    // if it applied on modal inside another modal
                    disableBodyScrollLock={true}
                    className={cx("PositionSeller-token-selector", {
                      warning: isNotEnoughReceiveTokenLiquidity || isCollateralPoolCapacityExceeded,
                    })}
                    label={t`Receive`}
                    showBalances={false}
                    chainId={chainId}
                    tokenAddress={receiveToken.address}
                    onSelectToken={(token) => {
                      setSwapToToken(token);
                      setSavedRecieveTokenAddress(token.address);
                    }}
                    tokens={toTokens}
                    getTokenState={(tokenOptionInfo) => {
                      if (!shouldSwap(collateralToken, tokenOptionInfo)) {
                        return;
                      }

                      const convertedTokenAmount = getTokenAmountFroMLP(
                        infoTokens,
                        tokenOptionInfo.address,
                        receiveAmount
                      );

                      const isNotEnoughLiquidity =
                        tokenOptionInfo.availableAmount.lt(convertedTokenAmount) ||
                        tokenOptionInfo.bufferAmount.gt(tokenOptionInfo.poolAmount.sub(convertedTokenAmount));

                      if (isNotEnoughLiquidity) {
                        const { maxIn, maxOut, maxInUsd, maxOutUsd } = getSwapLimits(
                          infoTokens,
                          collateralToken.address,
                          tokenOptionInfo.address
                        );

                        const collateralInfo = getTokenInfo(infoTokens, collateralToken.address);

                        return {
                          disabled: true,
                          message: (
                            <div>
                              <Trans>Insufficient Available Liquidity to swap to {tokenOptionInfo.symbol}:</Trans>
                              <br />
                              <br />
                              <StatsTooltipRow
                                label={t`Max ${collateralInfo.symbol} in`}
                                value={[
                                  `${formatAmount(maxIn, collateralInfo.decimals, 0, true)} ${collateralInfo.symbol}`,
                                  `($${formatAmount(maxInUsd, USD_DECIMALS, 0, true)})`,
                                ]}
                              />
                              <br />
                              <StatsTooltipRow
                                label={t`Max ${tokenOptionInfo.symbol} out`}
                                value={[
                                  `${formatAmount(maxOut, tokenOptionInfo.decimals, 2, true)} ${
                                    tokenOptionInfo.symbol
                                  }`,
                                  `($${formatAmount(maxOutUsd, USD_DECIMALS, 2, true)})`,
                                ]}
                              />
                            </div>
                          ),
                        };
                      }
                    }}
                    infoTokens={infoTokens}
                    showTokenImgInDropdown={true}
                    selectedTokenLabel={
                      <span className="PositionSelector-selected-receive-token">
                        {formatAmount(convertedReceiveAmount, receiveToken.decimals, 4, true)}&nbsp;
                        {receiveToken.symbol} (${formatAmount(receiveAmount, USD_DECIMALS, 2, true)})
                      </span>
                    }
                  />
                </div>
              )}
            </div>
          </div>
          <div className="Exchange-swap-button-container">
            <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
              {getPrimaryText()}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}
