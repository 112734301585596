import { Helmet, HelmetProvider } from "react-helmet-async";

function SEO(props) {
  const { children, ...customMeta } = props;
  const meta = {
    title: "MOLD | Decentralized Perpetual Exchange",
    description: "",
    image: "",
    type: "exchange",
    ...customMeta,
  };
  return (
    <HelmetProvider>
      <>
        <Helmet>
          <title>{meta.title}</title>
          <meta name="robots" content="follow, index" />
          <meta content={meta.description} name="description" />
          <meta property="og:type" content={meta.type} />
          <meta property="og:site_name" content="MOLD" />
          <meta property="og:description" content={meta.description} />
          <meta property="og:title" content={meta.title} />
          <meta property="og:image" content={meta.image} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@mold_trade" />
          <meta name="twitter:title" content={meta.title} />
          <meta name="twitter:description" content={meta.description} />
          <meta name="twitter:image" content={meta.image} />
        </Helmet>
        {children}
      </>
    </HelmetProvider>
  );
}

export default SEO;
