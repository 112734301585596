import React, { useCallback, useEffect, useState } from "react";
import { ethers } from "ethers";

import { keccak256 } from "ethers/lib/utils";
import { MerkleTree } from "merkletreejs";

import { useWeb3React } from "@web3-react/core";

import { getContract } from "config/contracts";
import { CFX_MAINNET, IS_NETWORK_DISABLED } from "config/chains";
import { callContract } from "lib/contracts";
import { useChainId } from "lib/chains";
import { switchNetwork } from "lib/wallets";

import { getWhitelist } from "domain/legacy";

import MBOX from "abis/MBOX.json";

import Footer from "components/Footer/Footer";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { HeaderLink } from "components/Header/HeaderLink";

import nft_title from "img/nft_title.png";

import "./ClaimEsMold.css";

export default function NFT(props) {
  const { setPendingTxns, connectWallet } = props;

  const { account, active, library } = useWeb3React();
  const { chainId } = useChainId();

  const changeNetwork = useCallback(
    (network) => {
      if (network === chainId) {
        return;
      }
      if (!active) {
        setTimeout(() => {
          return switchNetwork(network, active);
        }, 500);
      } else {
        return switchNetwork(network, active);
      }
    },
    [chainId, active]
  );

  const [inputValue, setInputValue] = useState(1);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const NFTAddress = getContract(chainId, "NFT");

  const mint = async () => {
    setIsSubmitting(true);

    const whitelist = await getWhitelist();

    const leafNodes = whitelist && whitelist.map((address) => keccak256(address));
    const tree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
    const proof = tree.getHexProof(keccak256(account));

    const contract = new ethers.Contract(NFTAddress, MBOX.abi, library.getSigner());
    contract.connect(account);
    const method = "mint";
    const params = [account, inputValue, proof];

    callContract(chainId, contract, method, params, {
      sentMsg: "Mint submitted.",
      failMsg: "Mint failed.",
      successMsg: `Mint success!`,
      setPendingTxns,
    })
      .then(async () => {})
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const isPrimaryEnabled = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return false;
    }
    if (!active) {
      return true;
    }

    if (isSubmitting) {
      return false;
    }

    return true;
  };

  const onClickPrimary = () => {
    if (!active) {
      connectWallet();
      return;
    }
    if (chainId !== CFX_MAINNET) {
      changeNetwork(CFX_MAINNET);
      return;
    }
    mint();
  };

  const add = useCallback(() => {
    setInputValue(inputValue + 1);
  }, [inputValue]);

  const sub = useCallback(() => {
    if (inputValue <= 1) return;
    setInputValue(inputValue - 1);
  }, [inputValue]);

  return (
    <div className="nft">
      <div className="default-container nft_box">
        <div className="nft-right">
          <div className="nft_title">
            <img src={nft_title} alt="title" />
          </div>
          <p className="description">
            {/* Trade perpetual futures of cryptos, stocks, and commodities with leverage and earn real yields */}
          </p>
          <div className="mint_btn">
            <input
              type="number"
              min="0"
              placeholder="0.0"
              className="Exchange-swap-input"
              value={inputValue}
              readOnly
            />
            <button onClick={add}>+</button>
            <button onClick={sub}>-</button>
          </div>
          <button className="default-btn" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            Mint Now
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}
