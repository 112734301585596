import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";
import { ethers } from "ethers";

import { USD_DECIMALS, PRECISION } from "lib/legacy";

import { getContract, XGMT_EXCLUDED_ACCOUNTS } from "config/contracts";

import Reader from "abis/Reader.json";
import Token from "abis/Token.json";
import YieldToken from "abis/YieldToken.json";
import YieldFarm from "abis/YieldFarm.json";

import Modal from "components/Modal/Modal";
import Footer from "components/Footer/Footer";

import "./Stake.css";
import { t, Trans } from "@lingui/macro";
import { CHAIN_ID, getExplorerUrl } from "config/chains";
import { contractFetcher } from "lib/contracts";
import { approveTokens } from "domain/tokens";
import { helperToast } from "lib/helperToast";
import { getInjectedHandler } from "lib/wallets";
import { bigNumberify, expandDecimals, formatAmount, formatAmountFree, formatKeyAmount, parseValue } from "lib/numbers";
import { getTokenBySymbol } from "config/tokens";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";

const BASIS_POINTS_DIVISOR = 10000;
const HOURS_PER_YEAR = 8760;

const { AddressZero } = ethers.constants;

function getBalanceAndSupplyData(balances) {
  if (!balances || balances.length === 0) {
    return {};
  }

  const keys = [
    "usdm",
    "gmt",
    "xgmt",
    "gmtUsdm",
    "xgmtUsdm",
    "gmtUsdmFarm",
    "xgmtUsdmFarm",
    "autoUsdm",
    "autoUsdmFarm",
  ];
  const balanceData = {};
  const supplyData = {};
  const propsLength = 2;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    balanceData[key] = balances[i * propsLength];
    supplyData[key] = balances[i * propsLength + 1];
  }

  return { balanceData, supplyData };
}

function getStakingData(stakingInfo) {
  if (!stakingInfo || stakingInfo.length === 0) {
    return;
  }

  const keys = [
    "usdm",
    "xgmt",
    "gmtUsdmFarmXgmt",
    "gmtUsdmFarmNative",
    "xgmtUsdmFarmXgmt",
    "xgmtUsdmFarmNative",
    "autoUsdmFarmXgmt",
    "autoUsdmFarmNative",
  ];
  const data = {};
  const propsLength = 2;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    data[key] = {
      claimable: stakingInfo[i * propsLength],
      tokensPerInterval: stakingInfo[i * propsLength + 1],
    };
  }

  return data;
}

function getTotalStakedData(totalStakedInfo) {
  if (!totalStakedInfo || totalStakedInfo.length === 0) {
    return;
  }

  const keys = ["usdm", "xgmt"];
  const data = {};

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    data[key] = totalStakedInfo[i];
  }

  return data;
}

function getPairData(pairInfo) {
  const keys = ["gmtUsdm", "xgmtUsdm", "bnbBusd", "autoUsdm"];
  if (!pairInfo || pairInfo.length === 0 || pairInfo.length !== keys.length * 2) {
    return;
  }

  const data = {};
  const propsLength = 2;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    data[key] = {
      balance0: pairInfo[i * propsLength],
      balance1: pairInfo[i * propsLength + 1],
    };
  }

  return data;
}

function getProcessedData(balanceData, supplyData, stakingData, totalStakedData, pairData, xgmtSupply) {
  if (!balanceData || !supplyData || !stakingData || !totalStakedData || !pairData || !xgmtSupply) {
    return {};
  }

  if (!supplyData.gmtUsdm || !supplyData.xgmtUsdm || !supplyData.autoUsdm) {
    return {};
  }

  // const gmtPrice = pairData.gmtUsdm.balance1.mul(PRECISION).div(pairData.gmtUsdm.balance0)
  const xgmtPrice = pairData.xgmtUsdm.balance0.eq(0)
    ? bigNumberify(0)
    : pairData.xgmtUsdm.balance1.mul(PRECISION).div(pairData.xgmtUsdm.balance0);
  const gmtUsdmPrice = supplyData.gmtUsdm.eq(0)
    ? bigNumberify(0)
    : pairData.gmtUsdm.balance1.mul(PRECISION).mul(2).div(supplyData.gmtUsdm);
  const xgmtUsdmPrice = supplyData.xgmtUsdm.eq(0)
    ? bigNumberify(0)
    : pairData.xgmtUsdm.balance1.mul(PRECISION).mul(2).div(supplyData.xgmtUsdm);
  const bnbPrice = pairData.bnbBusd.balance1.mul(PRECISION).div(pairData.bnbBusd.balance0);
  const autoUsdmPrice = supplyData.autoUsdm.eq(0)
    ? bigNumberify(0)
    : pairData.autoUsdm.balance1.mul(PRECISION).mul(2).div(supplyData.autoUsdm);

  const usdmAnnualRewardsUsd = stakingData.usdm.tokensPerInterval
    .mul(bnbPrice)
    .mul(HOURS_PER_YEAR)
    .div(expandDecimals(1, 18));
  const xgmtAnnualRewardsUsd = stakingData.xgmt.tokensPerInterval
    .mul(bnbPrice)
    .mul(HOURS_PER_YEAR)
    .div(expandDecimals(1, 18));

  const gmtUsdmAnnualRewardsXmgtUsd = stakingData.gmtUsdmFarmXgmt.tokensPerInterval
    .mul(xgmtPrice)
    .mul(HOURS_PER_YEAR)
    .div(expandDecimals(1, 18));
  const gmtUsdmAnnualRewardsNativeUsd = stakingData.gmtUsdmFarmNative.tokensPerInterval
    .mul(bnbPrice)
    .mul(HOURS_PER_YEAR)
    .div(expandDecimals(1, 18));
  const gmtUsdmTotalAnnualRewardsUsd = gmtUsdmAnnualRewardsXmgtUsd.add(gmtUsdmAnnualRewardsNativeUsd);

  const xgmtUsdmAnnualRewardsXmgtUsd = stakingData.xgmtUsdmFarmXgmt.tokensPerInterval
    .mul(xgmtPrice)
    .mul(HOURS_PER_YEAR)
    .div(expandDecimals(1, 18));
  const xgmtUsdmAnnualRewardsNativeUsd = stakingData.xgmtUsdmFarmNative.tokensPerInterval
    .mul(bnbPrice)
    .mul(HOURS_PER_YEAR)
    .div(expandDecimals(1, 18));
  const xgmtUsdmTotalAnnualRewardsUsd = xgmtUsdmAnnualRewardsXmgtUsd.add(xgmtUsdmAnnualRewardsNativeUsd);

  const autoUsdmAnnualRewardsXgmtUsd = stakingData.autoUsdmFarmXgmt.tokensPerInterval
    .mul(xgmtPrice)
    .mul(HOURS_PER_YEAR)
    .div(expandDecimals(1, 18));
  const autoUsdmAnnualRewardsNativeUsd = stakingData.autoUsdmFarmNative.tokensPerInterval
    .mul(bnbPrice)
    .mul(HOURS_PER_YEAR)
    .div(expandDecimals(1, 18));
  const autoUsdmTotalAnnualRewardsUsd = autoUsdmAnnualRewardsXgmtUsd.add(autoUsdmAnnualRewardsNativeUsd);

  const data = {};
  data.usdmBalance = balanceData.usdm;
  data.usdmSupply = supplyData.usdm;
  data.usdmTotalStaked = totalStakedData.usdm;
  data.usdmTotalStakedUsd = totalStakedData.usdm.mul(PRECISION).div(expandDecimals(1, 18));
  data.usdmSupplyUsd = supplyData.usdm.mul(PRECISION).div(expandDecimals(1, 18));
  data.usdmApr = data.usdmTotalStaked.eq(0)
    ? undefined
    : usdmAnnualRewardsUsd
        .mul(BASIS_POINTS_DIVISOR)
        .div(totalStakedData.usdm)
        .mul(expandDecimals(1, 18))
        .div(PRECISION);
  data.usdmRewards = stakingData.usdm.claimable;

  data.xgmtBalance = balanceData.xgmt;
  data.xgmtBalanceUsd = balanceData.xgmt.mul(xgmtPrice).div(expandDecimals(1, 18));
  data.xgmtSupply = xgmtSupply;
  data.xgmtTotalStaked = totalStakedData.xgmt;
  data.xgmtTotalStakedUsd = totalStakedData.xgmt.mul(xgmtPrice).div(expandDecimals(1, 18));
  data.xgmtSupplyUsd = xgmtSupply.mul(xgmtPrice).div(expandDecimals(1, 18));
  data.xgmtApr = data.xgmtSupplyUsd.eq(0)
    ? bigNumberify(0)
    : xgmtAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(data.xgmtTotalStakedUsd);
  data.xgmtRewards = stakingData.xgmt.claimable;

  data.gmtUsdmFarmBalance = balanceData.gmtUsdmFarm;

  data.gmtUsdmBalance = balanceData.gmtUsdm;
  data.gmtUsdmBalanceUsd = balanceData.gmtUsdm.mul(gmtUsdmPrice).div(expandDecimals(1, 18));
  data.gmtUsdmSupply = supplyData.gmtUsdm;
  data.gmtUsdmSupplyUsd = supplyData.gmtUsdm.mul(gmtUsdmPrice).div(expandDecimals(1, 18));
  data.gmtUsdmStaked = balanceData.gmtUsdmFarm;
  data.gmtUsdmStakedUsd = balanceData.gmtUsdmFarm.mul(gmtUsdmPrice).div(expandDecimals(1, 18));
  data.gmtUsdmFarmSupplyUsd = supplyData.gmtUsdmFarm.mul(gmtUsdmPrice).div(expandDecimals(1, 18));
  data.gmtUsdmApr = data.gmtUsdmSupplyUsd.eq(0)
    ? bigNumberify(0)
    : data.gmtUsdmFarmSupplyUsd.eq(0)
    ? undefined
    : gmtUsdmTotalAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(data.gmtUsdmSupplyUsd);
  data.gmtUsdmXgmtRewards = stakingData.gmtUsdmFarmXgmt.claimable;
  data.gmtUsdmNativeRewards = stakingData.gmtUsdmFarmNative.claimable;
  data.gmtUsdmTotalRewards = data.gmtUsdmXgmtRewards.add(data.gmtUsdmNativeRewards);
  data.gmtUsdmTotalStaked = supplyData.gmtUsdmFarm;
  data.gmtUsdmTotalStakedUsd = supplyData.gmtUsdmFarm.mul(gmtUsdmPrice).div(expandDecimals(1, 18));

  data.xgmtUsdmBalance = balanceData.xgmtUsdm;
  data.xgmtUsdmFarmBalance = balanceData.xgmtUsdmFarm;
  data.xgmtUsdmBalanceUsd = balanceData.xgmtUsdm.mul(xgmtUsdmPrice).div(expandDecimals(1, 18));
  data.xgmtUsdmSupply = supplyData.xgmtUsdm;
  data.xgmtUsdmSupplyUsd = supplyData.xgmtUsdm.mul(xgmtUsdmPrice).div(expandDecimals(1, 18));
  data.xgmtUsdmStaked = balanceData.xgmtUsdmFarm;
  data.xgmtUsdmStakedUsd = balanceData.xgmtUsdmFarm.mul(xgmtUsdmPrice).div(expandDecimals(1, 18));
  data.xgmtUsdmFarmSupplyUsd = supplyData.xgmtUsdmFarm.mul(xgmtUsdmPrice).div(expandDecimals(1, 18));
  data.xgmtUsdmApr = data.xgmtUsdmFarmSupplyUsd.eq(0)
    ? undefined
    : xgmtUsdmTotalAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(data.xgmtUsdmFarmSupplyUsd);
  data.xgmtUsdmXgmtRewards = stakingData.xgmtUsdmFarmXgmt.claimable;
  data.xgmtUsdmNativeRewards = stakingData.xgmtUsdmFarmNative.claimable;
  data.xgmtUsdmTotalRewards = data.xgmtUsdmXgmtRewards.add(data.xgmtUsdmNativeRewards);
  data.xgmtUsdmTotalStaked = supplyData.xgmtUsdmFarm;
  data.xgmtUsdmTotalStakedUsd = supplyData.xgmtUsdmFarm.mul(xgmtUsdmPrice).div(expandDecimals(1, 18));

  data.autoUsdmBalance = balanceData.autoUsdm;
  data.autoUsdmFarmBalance = balanceData.autoUsdmFarm;
  data.autoUsdmBalanceUsd = balanceData.autoUsdm.mul(autoUsdmPrice).div(expandDecimals(1, 18));
  data.autoUsdmStaked = balanceData.autoUsdmFarm;
  data.autoUsdmStakedUsd = balanceData.autoUsdmFarm.mul(autoUsdmPrice).div(expandDecimals(1, 18));
  data.autoUsdmFarmSupplyUsd = supplyData.autoUsdmFarm.mul(autoUsdmPrice).div(expandDecimals(1, 18));
  data.autoUsdmApr = data.autoUsdmFarmSupplyUsd.eq(0)
    ? bigNumberify(0)
    : autoUsdmTotalAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(data.autoUsdmFarmSupplyUsd);
  data.autoUsdmXgmtRewards = stakingData.autoUsdmFarmXgmt.claimable;
  data.autoUsdmNativeRewards = stakingData.autoUsdmFarmNative.claimable;
  data.autoUsdmTotalRewards = data.autoUsdmXgmtRewards.add(data.autoUsdmNativeRewards);
  data.autoUsdmTotalStaked = supplyData.autoUsdmFarm;
  data.autoUsdmTotalStakedUsd = supplyData.autoUsdmFarm.mul(autoUsdmPrice).div(expandDecimals(1, 18));

  data.totalStakedUsd = data.usdmTotalStakedUsd
    .add(data.xgmtTotalStakedUsd)
    .add(data.gmtUsdmTotalStakedUsd)
    .add(data.xgmtUsdmTotalStakedUsd)
    .add(data.autoUsdmTotalStakedUsd);

  return data;
}

function StakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    title,
    maxAmount,
    value,
    setValue,
    active,
    account,
    library,
    stakingTokenSymbol,
    stakingTokenAddress,
    farmAddress,
    chainId,
  } = props;
  const [isStaking, setIsStaking] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  const { data: tokenAllowance, mutate: updateTokenAllowance } = useSWR(
    [active, chainId, stakingTokenAddress, "allowance", account, farmAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  useEffect(() => {
    if (active) {
      library.on("block", () => {
        updateTokenAllowance(undefined, true);
      });
      return () => {
        library.removeAllListeners("block");
      };
    }
  }, [active, library, updateTokenAllowance]);

  let amount = parseValue(value, 18);
  const needApproval = tokenAllowance && amount && amount.gt(tokenAllowance);

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return t`Enter an amount`;
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return t`Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: stakingTokenAddress,
        spender: farmAddress,
        chainId: CHAIN_ID,
      });
      return;
    }

    setIsStaking(true);
    const contract = new ethers.Contract(farmAddress, YieldFarm.abi, library.getSigner());
    contract
      .stake(amount)
      .then(async (res) => {
        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash;
        helperToast.success(
          <div>
            <Trans>
              Stake submitted! <ExternalLink href={txUrl}>View status.</ExternalLink>
            </Trans>
            <br />
          </div>
        );
        setIsVisible(false);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
        helperToast.error(t`Stake failed`);
      })
      .finally(() => {
        setIsStaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isStaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isApproving) {
      return t`Approving ${stakingTokenSymbol}...`;
    }
    if (needApproval) {
      return t`Approve ${stakingTokenSymbol}`;
    }
    if (isStaking) {
      return t`Staking...`;
    }
    return t`Stake`;
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <div className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">
                <Trans>Stake</Trans>
              </div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              <Trans>Max: {formatAmount(maxAmount, 18, 4, true)}</Trans>
            </div>
          </div>
          <div className="Exchange-swap-section-bottom">
            <div>
              <input
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <div className="PositionEditor-token-symbol">{stakingTokenSymbol}</div>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function UnstakeModal(props) {
  const { isVisible, setIsVisible, title, maxAmount, value, setValue, library, stakingTokenSymbol, farmAddress } =
    props;
  const [isUnstaking, setIsUnstaking] = useState(false);

  let amount = parseValue(value, 18);

  const getError = () => {
    if (!amount) {
      return t`Enter an amount`;
    }
    if (amount.gt(maxAmount)) {
      return t`Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    setIsUnstaking(true);
    const contract = new ethers.Contract(farmAddress, YieldFarm.abi, library.getSigner());
    contract
      .unstake(amount)
      .then(async (res) => {
        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash;
        helperToast.success(
          <div>
            <Trans>
              Unstake submitted! <ExternalLink href={txUrl}>View status.</ExternalLink>
            </Trans>
            <br />
          </div>
        );
        setIsVisible(false);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
        helperToast.error(t`Unstake failed`);
      })
      .finally(() => {
        setIsUnstaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isUnstaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isUnstaking) {
      return t`Unstaking...`;
    }
    return t`Unstake`;
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <div className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">
                <Trans>Unstake</Trans>
              </div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              <Trans>Max: {formatAmount(maxAmount, 18, 4, true)}</Trans>
            </div>
          </div>
          <div className="Exchange-swap-section-bottom">
            <div>
              <input
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <div className="PositionEditor-token-symbol">{stakingTokenSymbol}</div>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default function StakeV1() {
  const { chainId } = useChainId();
  const [isStakeModalVisible, setIsStakeModalVisible] = useState(false);
  const [stakeModalTitle, setStakeModalTitle] = useState("");
  const [stakeModalMaxAmount, setStakeModalMaxAmount] = useState(undefined);
  const [stakeValue, setStakeValue] = useState("");
  const [stakingTokenAddress, setStakingTokenAddress] = useState("");
  const [stakingFarmAddress, setStakingFarmAddress] = useState("");

  const [isUnstakeModalVisible, setIsUnstakeModalVisible] = useState(false);
  const [unstakeModalTitle, setUnstakeModalTitle] = useState("");
  const [unstakeModalMaxAmount, setUnstakeModalMaxAmount] = useState(undefined);
  const [unstakeValue, setUnstakeValue] = useState("");
  const [unstakingFarmAddress, setUnstakingFarmAddress] = useState("");

  const { activate, active, account, library, deactivate } = useWeb3React();
  const connectWallet = getInjectedHandler(activate, deactivate);

  const readerAddress = getContract(CHAIN_ID, "Reader");
  const ammFactoryAddressV2 = getContract(CHAIN_ID, "AmmFactoryV2");
  const usdmAddress = getContract(CHAIN_ID, "USDM");
  const gmtAddress = getContract(CHAIN_ID, "GMT");
  const xgmtAddress = getContract(CHAIN_ID, "XGMT");
  const autoAddress = getContract(CHAIN_ID, "AUTO");
  const nativeTokenAddress = getContract(CHAIN_ID, "NATIVE_TOKEN");
  const busdAddress = getTokenBySymbol(CHAIN_ID, "BUSD").address;

  const gmtUsdmPairAddress = getContract(CHAIN_ID, "GMT_USDM_PAIR");
  const xgmtUsdmPairAddress = getContract(CHAIN_ID, "XGMT_USDM_PAIR");
  const autoUsdmPairAddress = getContract(CHAIN_ID, "AUTO_USDM_PAIR");
  const gmtUsdmFarmAddress = getContract(CHAIN_ID, "GMT_USDM_FARM");
  const xgmtUsdmFarmAddress = getContract(CHAIN_ID, "XGMT_USDM_FARM");
  const autoUsdmFarmAddress = getContract(CHAIN_ID, "AUTO_USDM_FARM");

  const usdmYieldTracker = getContract(CHAIN_ID, "USDM_YIELD_TRACKER");
  const xgmtYieldTracker = getContract(CHAIN_ID, "XGMT_YIELD_TRACKER");
  const gmtUsdmFarmTrackerXgmt = getContract(CHAIN_ID, "GMT_USDM_FARM_TRACKER_XGMT");
  const gmtUsdmFarmTrackerNative = getContract(CHAIN_ID, "GMT_USDM_FARM_TRACKER_NATIVE");
  const xgmtUsdmFarmTrackerXgmt = getContract(CHAIN_ID, "XGMT_USDM_FARM_TRACKER_XGMT");
  const xgmtUsdmFarmTrackerNative = getContract(CHAIN_ID, "XGMT_USDM_FARM_TRACKER_NATIVE");
  const autoUsdmFarmTrackerXgmt = getContract(CHAIN_ID, "AUTO_USDM_FARM_TRACKER_XGMT");
  const autoUsdmFarmTrackerNative = getContract(CHAIN_ID, "AUTO_USDM_FARM_TRACKER_NATIVE");

  const tokens = [
    usdmAddress,
    gmtAddress,
    xgmtAddress,
    gmtUsdmPairAddress,
    xgmtUsdmPairAddress,
    gmtUsdmFarmAddress,
    xgmtUsdmFarmAddress,
    autoUsdmPairAddress,
    autoUsdmFarmAddress,
  ];

  const yieldTrackers = [
    usdmYieldTracker,
    xgmtYieldTracker,
    gmtUsdmFarmTrackerXgmt,
    gmtUsdmFarmTrackerNative,
    xgmtUsdmFarmTrackerXgmt,
    xgmtUsdmFarmTrackerNative,
    autoUsdmFarmTrackerXgmt,
    autoUsdmFarmTrackerNative,
  ];

  const pairTokens = [
    gmtAddress,
    usdmAddress,
    xgmtAddress,
    usdmAddress,
    nativeTokenAddress,
    busdAddress,
    autoAddress,
    usdmAddress,
  ];

  const yieldTokens = [usdmAddress, xgmtAddress];

  const { data: xgmtSupply, mutate: updateXgmtSupply } = useSWR(
    [active, chainId, readerAddress, "getTokenSupply", xgmtAddress],
    {
      fetcher: contractFetcher(library, Reader, [XGMT_EXCLUDED_ACCOUNTS]),
    }
  );

  const { data: balances, mutate: updateBalances } = useSWR(
    ["Stake:balances", chainId, readerAddress, "getTokenBalancesWithSupplies", account || AddressZero],
    {
      fetcher: contractFetcher(library, Reader, [tokens]),
    }
  );

  const { data: stakingInfo, mutate: updateStakingInfo } = useSWR(
    [active, chainId, readerAddress, "getStakingInfo", account || AddressZero],
    {
      fetcher: contractFetcher(library, Reader, [yieldTrackers]),
    }
  );

  const { data: totalStakedInfo, mutate: updateTotalStakedInfo } = useSWR(
    [active, chainId, readerAddress, "getTotalStaked"],
    {
      fetcher: contractFetcher(library, Reader, [yieldTokens]),
    }
  );

  const { data: pairInfo, mutate: updatePairInfo } = useSWR(
    [active, chainId, readerAddress, "getPairInfo", ammFactoryAddressV2],
    {
      fetcher: contractFetcher(library, Reader, [pairTokens]),
    }
  );

  const { balanceData, supplyData } = getBalanceAndSupplyData(balances);
  const stakingData = getStakingData(stakingInfo);
  const pairData = getPairData(pairInfo);
  const totalStakedData = getTotalStakedData(totalStakedInfo);

  const processedData = getProcessedData(balanceData, supplyData, stakingData, totalStakedData, pairData, xgmtSupply);

  const buyXgmtUrl = `https://exchange.pancakeswap.finance/#/swap?outputCurrency=${xgmtAddress}&inputCurrency=${usdmAddress}`;
  const buyGmtUrl = `https://exchange.pancakeswap.finance/#/swap?outputCurrency=${gmtAddress}&inputCurrency=${usdmAddress}`;

  const addGmtUsdmLpUrl = `https://exchange.pancakeswap.finance/#/add/${gmtAddress}/${usdmAddress}`;
  const addXgmtUsdmLpUrl = `https://exchange.pancakeswap.finance/#/add/${xgmtAddress}/${usdmAddress}`;

  const buyAutoUrl = `https://exchange.pancakeswap.finance/#/swap?outputCurrency=${autoAddress}&inputCurrency=${nativeTokenAddress}`;
  const addAutoUsdmLpUrl = `https://exchange.pancakeswap.finance/#/add/${autoAddress}/${usdmAddress}`;

  useEffect(() => {
    if (active) {
      library.on("block", () => {
        updateXgmtSupply(undefined, true);
        updateBalances(undefined, true);
        updateStakingInfo(undefined, true);
        updateTotalStakedInfo(undefined, true);
        updatePairInfo(undefined, true);
      });
      return () => {
        library.removeAllListeners("block");
      };
    }
  }, [active, library, updateXgmtSupply, updateBalances, updateStakingInfo, updateTotalStakedInfo, updatePairInfo]);

  const claim = (farmAddress, rewards) => {
    if (!active || !account) {
      helperToast.error(t`Wallet not yet connected`);
      return;
    }
    if (chainId !== CHAIN_ID) {
      helperToast.error(t`Incorrect Network`);
      return;
    }
    if (!rewards || rewards.eq(0)) {
      helperToast.error(t`No rewards to claim yet`);
      return;
    }

    const contract = new ethers.Contract(farmAddress, YieldToken.abi, library.getSigner());
    contract
      .claim(account)
      .then(async (res) => {
        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash;
        helperToast.success(
          <div>
            <Trans>
              Claim submitted! <ExternalLink href={txUrl}>View status.</ExternalLink>
            </Trans>
            <br />
          </div>
        );
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
        helperToast.error(t`Claim failed`);
      });
  };

  const showUnstakeGmtUsdmModal = () => {
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle("Unstake GMT-USDM");
    setUnstakeModalMaxAmount(processedData.gmtUsdmFarmBalance);
    setUnstakeValue("");
    setUnstakingFarmAddress(gmtUsdmFarmAddress);
  };

  const showUnstakeXgmtUsdmModal = () => {
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle("Unstake xGMT-USDM");
    setUnstakeModalMaxAmount(processedData.xgmtUsdmFarmBalance);
    setUnstakeValue("");
    setUnstakingFarmAddress(xgmtUsdmFarmAddress);
  };

  const showStakeAutoUsdmModal = () => {
    setIsStakeModalVisible(true);
    setStakeModalTitle("Stake AUTO-USDM");
    setStakeModalMaxAmount(processedData.autoUsdmBalance);
    setStakeValue("");
    setStakingTokenAddress(autoUsdmPairAddress);
    setStakingFarmAddress(autoUsdmFarmAddress);
  };

  const showUnstakeAutoUsdmModal = () => {
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle("Unstake AUTO-USDM");
    setUnstakeModalMaxAmount(processedData.autoUsdmFarmBalance);
    setUnstakeValue("");
    setUnstakingFarmAddress(autoUsdmFarmAddress);
  };

  const hasFeeDistribution = true;

  return (
    <div className="Stake Page page-layout">
      <StakeModal
        isVisible={isStakeModalVisible}
        setIsVisible={setIsStakeModalVisible}
        title={stakeModalTitle}
        maxAmount={stakeModalMaxAmount}
        value={stakeValue}
        setValue={setStakeValue}
        active={active}
        account={account}
        library={library}
        stakingTokenAddress={stakingTokenAddress}
        farmAddress={stakingFarmAddress}
      />
      <UnstakeModal
        isVisible={isUnstakeModalVisible}
        setIsVisible={setIsUnstakeModalVisible}
        title={unstakeModalTitle}
        maxAmount={unstakeModalMaxAmount}
        value={unstakeValue}
        setValue={setUnstakeValue}
        active={active}
        account={account}
        library={library}
        farmAddress={unstakingFarmAddress}
      />
      <div className="Stake-title App-hero">
        <div className="Stake-title-primary App-hero-primary">
          ${formatKeyAmount(processedData, "totalStakedUsd", 30, 0, true)}
        </div>
        <div className="Stake-title-secondary">
          <Trans>Total Assets Staked</Trans>
        </div>
      </div>
      {/* <div className="Stake-note">
        <Trans>
          The Gambit protocol is in beta, please read the&nbsp;
          <ExternalLink href="https://gambit.gitbook.io/gambit/staking">staking details</ExternalLink>
          &nbsp; before participating.
        </Trans>
      </div> */}
      <div className="App-warning Stake-warning">
        <Trans>
          The <Link to="/migrate">MOLD migration</Link> is in progress, please migrate your GMT, xGMT, GMT-USDM and
          xGMT-USDM tokens.
          <br />
          USDM tokens will continue to function as before and do not need to be migrated.
        </Trans>
      </div>
      <div className="Stake-cards">
        <div className="App-card primary">
          <div className="Stake-card-title App-card-title">USDM</div>
          <div className="Stake-card-bottom App-card-content">
            <div className="Stake-info App-card-row">
              <div className="label">
                <Trans>APR</Trans>
              </div>
              <div>
                {!hasFeeDistribution && "TBC"}
                {hasFeeDistribution && `${formatKeyAmount(processedData, "usdmApr", 2, 2, true)}%`}
              </div>
            </div>
            <div className="Stake-info App-card-row">
              <div className="label">
                <Trans>Staked</Trans>
              </div>
              <div>
                {formatKeyAmount(processedData, "usdmBalance", 18, 2, true)} ($
                {formatKeyAmount(processedData, "usdmBalance", 18, 2, true)})
              </div>
            </div>
            <div className="Stake-info App-card-row">
              <div className="label">
                <Trans>Wallet</Trans>
              </div>
              <div>
                {formatKeyAmount(processedData, "usdmBalance", 18, 2, true)} ($
                {formatKeyAmount(processedData, "usdmBalance", 18, 2, true)})
              </div>
            </div>
            <div className="App-card-row">
              <div className="label">
                <Trans>Rewards</Trans>
              </div>
              <div>
                {!hasFeeDistribution && "TBC"}
                {hasFeeDistribution && `${formatKeyAmount(processedData, "usdmRewards", 18, 8, true)} WBNB`}
              </div>
            </div>
            <div className="Stake-info App-card-row">
              <div className="label">
                <Trans>Total Staked</Trans>
              </div>
              <div>
                {formatKeyAmount(processedData, "usdmTotalStaked", 18, 2, true)} ($
                {formatKeyAmount(processedData, "usdmTotalStakedUsd", 30, 2, true)})
              </div>
            </div>
            <div className="Stake-info App-card-row">
              <div className="label">
                <Trans>Total Supply</Trans>
              </div>
              <div>
                {formatKeyAmount(processedData, "usdmSupply", 18, 2, true)} ($
                {formatKeyAmount(processedData, "usdmSupplyUsd", 30, 2, true)})
              </div>
            </div>
            <div className="App-card-options">
              <Link className="App-button-option App-card-option" to="/trade">
                Get USDM
              </Link>
              {active && (
                <button
                  className="App-button-option App-card-option"
                  onClick={() => claim(usdmAddress, processedData.usdmRewards)}
                >
                  <Trans>Claim</Trans>
                </button>
              )}
              {!active && (
                <button className="App-button-option App-card-option" onClick={connectWallet}>
                  <Trans>Connect Wallet</Trans>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="App-card">
          <div className="Stake-card-title App-card-title">xGMT</div>
          <div className="Stake-card-bottom App-card-content">
            <div className="Stake-info App-card-row">
              <div className="label">APR</div>
              <div>
                0.00% (
                <Link to="/migrate">
                  <Trans>Migrate</Trans>
                </Link>
                )
              </div>
            </div>
            <div className="Stake-info App-card-row">
              <div className="label">
                <Trans>Staked</Trans>
              </div>
              <div>
                {formatKeyAmount(processedData, "xgmtBalance", 18, 2, true)} ($
                {formatKeyAmount(processedData, "xgmtBalanceUsd", USD_DECIMALS, 2, true)})
              </div>
            </div>
            <div className="Stake-info App-card-row">
              <div className="label">
                <Trans>Wallet</Trans>
              </div>
              <div>
                {formatKeyAmount(processedData, "xgmtBalance", 18, 2, true)} ($
                {formatKeyAmount(processedData, "xgmtBalanceUsd", USD_DECIMALS, 2, true)})
              </div>
            </div>
            <div className="App-card-row">
              <div className="label">
                <Trans>Rewards</Trans>
              </div>
              <div>
                {!hasFeeDistribution && "TBC"}
                {hasFeeDistribution && `${formatKeyAmount(processedData, "xgmtRewards", 18, 8, true)} WBNB`}
              </div>
            </div>
            <div className="Stake-info App-card-row">
              <div className="label">
                <Trans>Total Staked</Trans>
              </div>
              <div>
                {formatKeyAmount(processedData, "xgmtTotalStaked", 18, 2, true)} ($
                {formatKeyAmount(processedData, "xgmtTotalStakedUsd", USD_DECIMALS, 2, true)})
              </div>
            </div>
            <div className="Stake-info App-card-row">
              <div className="label">
                <Trans>Total Supply</Trans>
              </div>
              <div>
                {formatKeyAmount(processedData, "xgmtSupply", 18, 2, true)} ($
                {formatKeyAmount(processedData, "xgmtSupplyUsd", USD_DECIMALS, 2, true)})
              </div>
            </div>
            <div className="App-card-options">
              <ExternalLink className="App-button-option App-card-option" href={buyXgmtUrl}>
                Get xGMT
              </ExternalLink>
              {active && (
                <button
                  className="App-button-option App-card-option"
                  onClick={() => claim(xgmtAddress, processedData.xgmtRewards)}
                >
                  <Trans>Claim</Trans>
                </button>
              )}
              {!active && (
                <button className="App-button-option App-card-option" onClick={connectWallet}>
                  <Trans>Connect Wallet</Trans>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="App-card">
          <div className="Stake-card-title App-card-title">GMT-USDM LP</div>
          <div className="Stake-card-bottom App-card-content">
            <div className="Stake-info App-card-row">
              <div className="label">
                <Trans>APR</Trans>
              </div>
              <div>
                0.00% (
                <Link to="/migrate">
                  <Trans>Migrate</Trans>
                </Link>
                )
              </div>
            </div>
            <div className="Stake-info App-card-row">
              <div className="label">
                <Trans>Staked</Trans>
              </div>
              <div>
                {formatKeyAmount(processedData, "gmtUsdmStaked", 18, 4, true)} ($
                {formatKeyAmount(processedData, "gmtUsdmStakedUsd", 30, 2, true)})
              </div>
            </div>
            <div className="Stake-info App-card-row">
              <div className="label">
                <Trans>Wallet</Trans>
              </div>
              <div>
                {formatKeyAmount(processedData, "gmtUsdmBalance", 18, 2, true)} ($
                {formatKeyAmount(processedData, "gmtUsdmBalanceUsd", USD_DECIMALS, 2, true)})
              </div>
            </div>
            <div className="App-card-row">
              <div className="label">
                <Trans>Rewards</Trans>
              </div>
              <div>
                {hasFeeDistribution &&
                  processedData.gmtUsdmNativeRewards &&
                  processedData.gmtUsdmNativeRewards.gt(0) &&
                  `${formatKeyAmount(processedData, "gmtUsdmNativeRewards", 18, 8, true)} WBNB, `}
                {formatKeyAmount(processedData, "gmtUsdmXgmtRewards", 18, 4, true)} xGMT
              </div>
            </div>
            <div className="Stake-info App-card-row">
              <div className="label">
                <Trans>Total Staked</Trans>
              </div>
              <div>
                {formatKeyAmount(processedData, "gmtUsdmTotalStaked", 18, 4, true)} ($
                {formatKeyAmount(processedData, "gmtUsdmTotalStakedUsd", 30, 2, true)})
              </div>
            </div>
            <div className="App-card-options">
              <ExternalLink className="App-button-option App-card-option" href={buyGmtUrl}>
                Get GMT
              </ExternalLink>
              <ExternalLink className="App-button-option App-card-option" href={addGmtUsdmLpUrl}>
                <Trans>Create</Trans>
              </ExternalLink>
              {active && (
                <button className="App-button-option App-card-option" onClick={() => showUnstakeGmtUsdmModal()}>
                  <Trans>Unstake</Trans>
                </button>
              )}
              {active && (
                <button
                  className="App-button-option App-card-option"
                  onClick={() => claim(gmtUsdmFarmAddress, processedData.gmtUsdmTotalRewards)}
                >
                  <Trans>Claim</Trans>
                </button>
              )}
              {!active && (
                <button className="App-button-option App-card-option" onClick={connectWallet}>
                  <Trans>Connect Wallet</Trans>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="App-card">
          <div className="Stake-card-title App-card-title">xGMT-USDM LP</div>
          <div className="Stake-card-bottom App-card-content">
            <div className="Stake-info App-card-row">
              <div className="label">
                <Trans>APR</Trans>
              </div>
              <div>
                0.00% (
                <Link to="/migrate">
                  <Trans>Migrate</Trans>
                </Link>
                )
              </div>
            </div>
            <div className="Stake-info App-card-row">
              <div className="label">
                <Trans>Staked</Trans>
              </div>
              <div>
                {formatKeyAmount(processedData, "xgmtUsdmStaked", 18, 4, true)} ($
                {formatKeyAmount(processedData, "xgmtUsdmStakedUsd", 30, 2, true)})
              </div>
            </div>
            <div className="Stake-info App-card-row">
              <div className="label">
                <Trans>Wallet</Trans>
              </div>
              <div>
                {formatKeyAmount(processedData, "xgmtUsdmBalance", 18, 2, true)} ($
                {formatKeyAmount(processedData, "xgmtUsdmBalanceUsd", USD_DECIMALS, 2, true)})
              </div>
            </div>
            <div className="App-card-row">
              <div className="label">
                <Trans>Rewards</Trans>
              </div>
              <div>
                {hasFeeDistribution &&
                  processedData.xgmtUsdmNativeRewards &&
                  processedData.xgmtUsdmNativeRewards.gt(0) &&
                  `${formatKeyAmount(processedData, "xgmtUsdmNativeRewards", 18, 8, true)} WBNB, `}
                {formatKeyAmount(processedData, "xgmtUsdmXgmtRewards", 18, 4, true)} xGMT
              </div>
            </div>
            <div className="Stake-info App-card-row">
              <div className="label">
                <Trans>Total Staked</Trans>
              </div>
              <div>
                {formatKeyAmount(processedData, "xgmtUsdmTotalStaked", 18, 4, true)} ($
                {formatKeyAmount(processedData, "xgmtUsdmTotalStakedUsd", 30, 2, true)})
              </div>
            </div>
            <div className="App-card-options">
              <ExternalLink className="App-button-option App-card-option" href={buyXgmtUrl}>
                Get xGMT
              </ExternalLink>
              <ExternalLink className="App-button-option App-card-option" href={addXgmtUsdmLpUrl}>
                <Trans>Create</Trans>
              </ExternalLink>
              {active && (
                <button className="App-button-option App-card-option" onClick={() => showUnstakeXgmtUsdmModal()}>
                  <Trans>Unstake</Trans>
                </button>
              )}
              {active && (
                <button
                  className="App-button-option App-card-option"
                  onClick={() => claim(xgmtUsdmFarmAddress, processedData.xgmtUsdmTotalRewards)}
                >
                  <Trans>Claim</Trans>
                </button>
              )}
              {!active && (
                <button className="App-button-option App-card-option" onClick={connectWallet}>
                  <Trans>Connect Wallet</Trans>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="App-card">
          <div className="Stake-card-title App-card-title">AUTO-USDM LP</div>
          <div className="Stake-card-bottom App-card-content">
            <div className="Stake-info App-card-row">
              <div className="label">
                <Trans>APR</Trans>
              </div>
              <div>{formatKeyAmount(processedData, "autoUsdmApr", 2, 2, true)}%</div>
            </div>
            <div className="Stake-info App-card-row">
              <div className="label">
                <Trans>Staked</Trans>
              </div>
              <div>
                {formatKeyAmount(processedData, "autoUsdmStaked", 18, 4, true)} ($
                {formatKeyAmount(processedData, "autoUsdmStakedUsd", 30, 2, true)})
              </div>
            </div>
            <div className="Stake-info App-card-row">
              <div className="label">
                <Trans>Wallet</Trans>
              </div>
              <div>
                {formatKeyAmount(processedData, "autoUsdmBalance", 18, 2, true)} ($
                {formatKeyAmount(processedData, "autoUsdmBalanceUsd", USD_DECIMALS, 2, true)})
              </div>
            </div>
            <div className="App-card-row">
              <div className="label">
                <Trans>Rewards</Trans>
              </div>
              <div>{formatKeyAmount(processedData, "autoUsdmXgmtRewards", 18, 4, true)} xGMT</div>
            </div>
            <div className="Stake-info App-card-row">
              <div className="label">
                <Trans>Total Staked</Trans>
              </div>
              <div>
                {formatKeyAmount(processedData, "autoUsdmTotalStaked", 18, 4, true)} ($
                {formatKeyAmount(processedData, "autoUsdmTotalStakedUsd", 30, 2, true)})
              </div>
            </div>
            <div className="App-card-options">
              <ExternalLink className="App-button-option App-card-option" href={buyAutoUrl}>
                Get AUTO
              </ExternalLink>
              <ExternalLink className="App-button-option App-card-option" href={addAutoUsdmLpUrl}>
                <Trans>Create</Trans>
              </ExternalLink>
              {active && (
                <button className="App-button-option App-card-option" onClick={() => showStakeAutoUsdmModal()}>
                  <Trans>Stake</Trans>
                </button>
              )}
              {active && (
                <button className="App-button-option App-card-option" onClick={() => showUnstakeAutoUsdmModal()}>
                  <Trans>Unstake</Trans>
                </button>
              )}
              {active && (
                <button
                  className="App-button-option App-card-option"
                  onClick={() => claim(autoUsdmFarmAddress, processedData.autoUsdmTotalRewards)}
                >
                  <Trans>Claim</Trans>
                </button>
              )}
              {!active && (
                <button className="App-button-option App-card-option" onClick={connectWallet}>
                  <Trans>Connect Wallet</Trans>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
