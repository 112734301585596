import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useWeb3React } from "@web3-react/core";
import { Trans, t } from "@lingui/macro";
import useSWR from "swr";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import TooltipComponent from "components/Tooltip/Tooltip";

import hexToRgba from "hex-to-rgba";
import { BigNumber, ethers } from "ethers";

import {
  USD_DECIMALS,
  MOLD_DECIMALS,
  MLP_DECIMALS,
  BASIS_POINTS_DIVISOR,
  DEFAULT_MAX_USDM_AMOUNT,
  getPageTitle,
  importImage,
  arrayURLFetcher,
} from "lib/legacy";
import { useTotalMoldInLiquidity, useMldsPrice, useTotalMoldStaked, useTotalMoldSupply } from "domain/legacy";
import useFeesSummary from "domain/useFeesSummary";

import { getContract } from "config/contracts";

import VaultV2 from "abis/VaultV2.json";
import ReaderV2 from "abis/ReaderV2.json";
import MlpManager from "abis/MlpManager.json";

import Footer from "components/Footer/Footer";

import "./DashboardV2.css";
import SectionBg from "img/section_bg.png";
import Chart from "img/chart.png";
import BoxBg1 from "img/section_block_bg1.png";
import BoxBg2 from "img/section_block_bg2.png";
import TotalIco1 from "img/total_ico1.png";
import TotalIco2 from "img/total_ico2.png";
import Aum from "img/aum.png";
import Trades from "img/ic_user.svg";
import Long from "img/long.png";
import Short from "img/short.png";
import S_MLP from "img/s_MLPlogo.png";
import L_MLP from "img/l_MLPlogo.png";
import L_mlds from "img/l_mldslogo.png";
import MldsWhite from "img/mlds_white.png";
import Tokens from "img/tokens.png";

import AssetDropdown from "./AssetDropdown";
import ExternalLink from "components/ExternalLink/ExternalLink";
import SEO from "components/Common/SEO";
import useTotalVolume from "domain/useTotalVolume";
import StatsTooltip from "components/StatsTooltip/StatsTooltip";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import Button from "components/Button/Button";

import { CFX_TESTNET, getChainName } from "config/chains";
import { getServerUrl, MOLD_STATS_API_URL } from "config/backend";
import { contractFetcher } from "lib/contracts";
import { useInfoTokens } from "domain/tokens";
import { getTokenBySymbol, getWhitelistedTokens, MLP_POOL_COLORS } from "config/tokens";
import {
  bigNumberify,
  expandDecimals,
  formatAmount,
  formatKeyAmount,
  numberWithCommas,
  toThousands,
} from "lib/numbers";
import { useChainId } from "lib/chains";
import { formatDate } from "lib/dates";
import { getIcons } from "config/icons";

const ACTIVE_CHAIN_IDS = [CFX_TESTNET];

const { AddressZero } = ethers.constants;

function getVolumeInfo(hourlyVolumes) {
  if (!hourlyVolumes || hourlyVolumes.length === 0) {
    return {};
  }
  const dailyVolumes = hourlyVolumes.map((hourlyVolume) => {
    const secondsPerHour = 60 * 60;
    const minTime = parseInt(Date.now() / 1000 / secondsPerHour) * secondsPerHour - 24 * secondsPerHour;
    const info = {};
    let totalVolume = bigNumberify(0);
    for (let i = 0; i < hourlyVolume.length; i++) {
      const item = hourlyVolume[i].data;
      if (parseInt(item.timestamp) < minTime) {
        break;
      }

      if (!info[item.token]) {
        info[item.token] = bigNumberify(0);
      }

      info[item.token] = info[item.token].add(item.volume);
      totalVolume = totalVolume.add(item.volume);
    }
    info.totalVolume = totalVolume;
    return info;
  });
  return dailyVolumes.reduce(
    (acc, cv, index) => {
      acc.totalVolume = acc.totalVolume.add(cv.totalVolume);
      acc[ACTIVE_CHAIN_IDS[index]] = cv;
      return acc;
    },
    { totalVolume: bigNumberify(0) }
  );
}

function getPositionStats(positionStats) {
  if (!positionStats || positionStats.length === 0) {
    return null;
  }
  return positionStats.reduce(
    (acc, cv, i) => {
      acc.totalLongPositionSizes = acc.totalLongPositionSizes.add(cv.totalLongPositionSizes);
      acc.totalShortPositionSizes = acc.totalShortPositionSizes.add(cv.totalShortPositionSizes);
      acc[ACTIVE_CHAIN_IDS[i]] = cv;
      return acc;
    },
    {
      totalLongPositionSizes: bigNumberify(0),
      totalShortPositionSizes: bigNumberify(0),
    }
  );
}

function getCurrentFeesUsd(tokenAddresses, fees, infoTokens) {
  if (!fees || !infoTokens) {
    return bigNumberify(0);
  }

  let currentFeesUsd = bigNumberify(0);
  for (let i = 0; i < tokenAddresses.length; i++) {
    const tokenAddress = tokenAddresses[i];
    const tokenInfo = infoTokens[tokenAddress];
    if (!tokenInfo || !tokenInfo.contractMinPrice) {
      continue;
    }

    const feeUsd = fees[i].mul(tokenInfo.contractMinPrice).div(expandDecimals(1, tokenInfo.decimals));
    currentFeesUsd = currentFeesUsd.add(feeUsd);
  }

  return currentFeesUsd;
}

export default function DashboardV2() {
  const { active, library } = useWeb3React();
  const { chainId } = useChainId();
  // const totalVolume = useTotalVolume();
  const { data: profile } = useSWR(`${MOLD_STATS_API_URL}/dashboard?id=${chainId}`, {
    fetcher: arrayURLFetcher,
  });

  const totalVolume = profile?.length ? parseInt(profile[0].TotalTrade) : 0;
  const totalUser = profile?.length ? parseInt(profile[0].TotalUser) : 0;
  const longPositionsSize = profile?.length ? parseInt(profile[0].TotalLong) : 0;
  const shortPositionSize = profile?.length ? parseInt(profile[0].TotalShort) : 0;

  // const chainName = getChainName(chainId);
  // const currentIcons = getIcons(chainId);

  // const { data: positionStats } = useSWR(
  //   ACTIVE_CHAIN_IDS.map((chainId) => getServerUrl(chainId, "/position_stats")),
  //   {
  //     fetcher: arrayURLFetcher,
  //   }
  // );

  // const { data: hourlyVolumes } = useSWR(
  //   ACTIVE_CHAIN_IDS.map((chainId) => getServerUrl(chainId, "/hourly_volume")),
  //   {
  //     fetcher: arrayURLFetcher,
  //   }
  // );

  // const currentVolumeInfo = getVolumeInfo(hourlyVolumes);

  // const positionStatsInfo = getPositionStats(positionStats);

  function getWhitelistedTokenAddresses(chainId) {
    const whitelistedTokens = getWhitelistedTokens(chainId);
    return whitelistedTokens.map((token) => token.address);
  }

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isTempHidden);
  // const tokenList = whitelistedTokens;
  const visibleTokens = tokenList.filter((t) => !t.isTempHidden);

  const readerAddress = getContract(chainId, "Reader");
  const vaultAddress = getContract(chainId, "Vault");
  const mlpManagerAddress = getContract(chainId, "MlpManager");

  const moldAddress = getContract(chainId, "MOLD");
  const mlpAddress = getContract(chainId, "MLP");
  const usdmAddress = getContract(chainId, "USDM");

  const tokensForSupplyQuery = [moldAddress, mlpAddress, usdmAddress];

  const { data: aums } = useSWR([`Dashboard:getAums:${active}`, chainId, mlpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, MlpManager),
  });

  const { data: totalSupplies } = useSWR(
    [`Dashboard:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
    }
  );

  const { data: totalTokenWeights } = useSWR(
    [`MlpSwap:totalTokenWeights:${active}`, chainId, vaultAddress, "totalTokenWeights"],
    {
      fetcher: contractFetcher(library, VaultV2),
    }
  );

  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);
  const { infoTokens: infoTokensCFX_TESTNET } = useInfoTokens(null, CFX_TESTNET, active, undefined, undefined);

  const { data: currentFees } = useSWR(
    infoTokensCFX_TESTNET[AddressZero].contractMinPrice ? "Dashboard:currentFees" : null,
    {
      fetcher: () => {
        return Promise.all(
          ACTIVE_CHAIN_IDS.map((chainId) =>
            contractFetcher(null, ReaderV2, [getWhitelistedTokenAddresses(chainId)])(
              `Dashboard:fees:${chainId}`,
              chainId,
              getContract(chainId, "Reader"),
              "getFees",
              getContract(chainId, "Vault")
            )
          )
        ).then((fees) => {
          return fees.reduce(
            (acc, cv, i) => {
              const feeUSD = getCurrentFeesUsd(
                getWhitelistedTokenAddresses(ACTIVE_CHAIN_IDS[i]),
                cv,
                infoTokensCFX_TESTNET
              );
              acc[ACTIVE_CHAIN_IDS[i]] = feeUSD;
              acc.total = acc.total.add(feeUSD);
              return acc;
            },
            { total: bigNumberify(0) }
          );
        });
      },
    }
  );

  const eth = infoTokens[getTokenBySymbol(chainId, "WETH").address];

  const totalFees = ACTIVE_CHAIN_IDS.map((chainId) => {
    if (currentFees && currentFees[chainId]) {
      return currentFees[chainId].div(expandDecimals(1, USD_DECIMALS));
    }
  })
    .map((v) => Math.round(v))
    .reduce(
      (acc, cv, i) => {
        acc[ACTIVE_CHAIN_IDS[i]] = cv;
        acc.total = acc.total + cv;
        return acc;
      },
      { total: 0 }
    );

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  let mlpPrice;
  let mlpSupply;
  let mlpMarketCap;
  if (aum && totalSupplies && totalSupplies[3]) {
    mlpSupply = totalSupplies[3];
    mlpPrice =
      aum && aum.gt(0) && mlpSupply.gt(0)
        ? aum.mul(expandDecimals(1, MLP_DECIMALS)).div(mlpSupply)
        : expandDecimals(1, USD_DECIMALS);
    mlpMarketCap = mlpPrice.mul(mlpSupply).div(expandDecimals(1, MLP_DECIMALS));
  }

  const ethFloorPriceFund = expandDecimals(350 + 148 + 384, 18);
  const mlpFloorPriceFund = expandDecimals(660001, 18);
  const usdcFloorPriceFund = expandDecimals(784598 + 200000, 30);

  let totalFloorPriceFundUsd;

  if (eth && eth.contractMinPrice && mlpPrice) {
    const ethFloorPriceFundUsd = ethFloorPriceFund.mul(eth.contractMinPrice).div(expandDecimals(1, eth.decimals));
    const mlpFloorPriceFundUsd = mlpFloorPriceFund.mul(mlpPrice).div(expandDecimals(1, 18));

    totalFloorPriceFundUsd = ethFloorPriceFundUsd.add(mlpFloorPriceFundUsd).add(usdcFloorPriceFund);
  }

  let adjustedUsdmSupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdmAmount) {
      adjustedUsdmSupply = adjustedUsdmSupply.add(tokenInfo.usdmAmount);
    }
  }

  const getWeightText = (tokenInfo) => {
    if (
      !tokenInfo.weight ||
      !tokenInfo.usdmAmount ||
      !adjustedUsdmSupply ||
      adjustedUsdmSupply.eq(0) ||
      !totalTokenWeights
    ) {
      return "...";
    }

    const currentWeightBps = tokenInfo.usdmAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdmSupply);
    // use add(1).div(10).mul(10) to round numbers up
    const targetWeightBps = tokenInfo.weight.mul(BASIS_POINTS_DIVISOR).div(totalTokenWeights).add(1).div(10).mul(10);

    const weightText = `${formatAmount(currentWeightBps, 2, 2, false)}% / ${formatAmount(
      targetWeightBps,
      2,
      2,
      false
    )}%`;

    return (
      <TooltipComponent
        handle={weightText}
        position="right-bottom"
        renderContent={() => {
          return (
            <>
              <StatsTooltipRow
                label={t`Current Weight`}
                value={`${formatAmount(currentWeightBps, 2, 2, false)}%`}
                showDollar={false}
              />
              <StatsTooltipRow
                label={t`Target Weight`}
                value={`${formatAmount(targetWeightBps, 2, 2, false)}%`}
                showDollar={false}
              />
              <br />
              {currentWeightBps.lt(targetWeightBps) && (
                <div className="text-white">
                  <Trans>
                    {tokenInfo.symbol} is below its target weight.
                    <br />
                    <br />
                    Get lower fees to{" "}
                    <Link to="/buy_MLP" target="_blank" rel="noopener noreferrer">
                      buy MLP
                    </Link>{" "}
                    with {tokenInfo.symbol}, and to{" "}
                    <Link to="/trade" target="_blank" rel="noopener noreferrer">
                      swap
                    </Link>{" "}
                    {tokenInfo.symbol} for other tokens.
                  </Trans>
                </div>
              )}
              {currentWeightBps.gt(targetWeightBps) && (
                <div className="text-white">
                  <Trans>
                    {tokenInfo.symbol} is above its target weight.
                    <br />
                    <br />
                    Get lower fees to{" "}
                    <Link to="/trade" target="_blank" rel="noopener noreferrer">
                      swap
                    </Link>{" "}
                    tokens for {tokenInfo.symbol}.
                  </Trans>
                </div>
              )}
              <br />
              <div>
                <ExternalLink href="https://docs.mold.trade/">
                  <Trans>More Info</Trans>
                </ExternalLink>
              </div>
            </>
          );
        }}
      />
    );
  };

  let stakedPercent = 0;

  // if (totalMoldSupply && !totalMoldSupply.isZero() && !totalStakedMold.isZero()) {
  //   stakedPercent = totalStakedMold.mul(100).div(totalMoldSupply).toNumber();
  // }

  let liquidityPercent = 0;

  // if (totalMoldSupply && !totalMoldSupply.isZero() && totalMoldInLiquidity) {
  //   liquidityPercent = totalMoldInLiquidity.mul(100).div(totalMoldSupply).toNumber();
  // }

  let notStakedPercent = 100 - stakedPercent - liquidityPercent;

  let moldDistributionData = [
    {
      name: t`staked`,
      value: stakedPercent,
      color: "#4353fa",
    },
    {
      name: t`in liquidity`,
      value: liquidityPercent,
      color: "#0598fa",
    },
    {
      name: t`not staked`,
      value: notStakedPercent,
      color: "#5c0af5",
    },
  ];

  // const totalStatsStartDate = chainId === AVALANCHE ? t`06 Jan 2022` : t`01 Sep 2021`;
  const totalStatsStartDate = t`01 Sep 2021`;

  let stableMlp = 0;
  let totalMlp = 0;

  let mlpPool = tokenList.map((token) => {
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo.usdmAmount && adjustedUsdmSupply && adjustedUsdmSupply.gt(0)) {
      const currentWeightBps = tokenInfo.usdmAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdmSupply);
      if (tokenInfo.isStable) {
        stableMlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
      }
      totalMlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
      return {
        fullname: token.name,
        name: token.symbol,
        value: parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`),
      };
    }
    return null;
  });

  let stablePercentage = totalMlp > 0 ? ((stableMlp * 100) / totalMlp).toFixed(2) : "0.0";

  mlpPool = mlpPool.filter(function (element) {
    return element !== null;
  });

  mlpPool = mlpPool.sort(function (a, b) {
    if (a.value < b.value) return 1;
    else return -1;
  });

  const [mlpActiveIndex, setMLPActiveIndex] = useState(null);

  const onMLPPoolChartEnter = (_, index) => {
    setMLPActiveIndex(index);
  };

  const onMLPPoolChartLeave = (_, index) => {
    setMLPActiveIndex(null);
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="stats-label">
          <div className="stats-label-color" style={{ backgroundColor: payload[0].color }}></div>
          {payload[0].value}% {payload[0].name}
        </div>
      );
    }

    return null;
  };

  return (
    <SEO title={getPageTitle("Dashboard")}>
      <div className="section-background">
        <img src={SectionBg} alt="" />
      </div>
      <div className="default-container DashboardV2 page-layout">
        <div className="section-title-block">
          <div className="section-title-icon"></div>
          <div className="section-title-content">
            <div className="Page-title">
              <img width="46" src={Chart} alt="Network Icon" /> <Trans>Stats</Trans>
            </div>
          </div>
        </div>
        <StyledGrid>
          <StyledTotal>
            <StyledFlexBox>
              <img src={TotalIco1} alt="volume" />
              <p>Total Volume</p>
            </StyledFlexBox>
            <StyledBalance>
              <p>${toThousands(totalVolume)}</p>
              {/* <p>+$527,432</p> */}
            </StyledBalance>
            <StyledFlexBox>
              <img src={TotalIco2} alt="fees" />
              <p>Total Fees</p>
            </StyledFlexBox>
            <StyledBalance>
              <p>${numberWithCommas(totalFees?.[chainId])}</p>
              {/* <p>+$527,432</p> */}
            </StyledBalance>
            <Link to="/trade" className="default-btn">
              <Trans>Start Trading</Trans>
            </Link>
          </StyledTotal>

          <StyledAum className="grid-box">
            <div>
              <StyledFlexBox>
                <img src={Aum} alt="aum" />
                <p>AUM</p>
              </StyledFlexBox>
              <StyledData>
                <div>
                  <div className="flex">
                    <img src={S_MLP} alt="" />
                    MLP Vault Balance
                  </div>
                  <p>${formatAmount(aum, USD_DECIMALS, 0, true)}</p>
                </div>
              </StyledData>
            </div>
            <div className="trades">
              <StyledFlexBox>
                <img src={Trades} alt="trades" />
                <p>Traders</p>
              </StyledFlexBox>
              <StyledData>
                <div>
                  <div className="flex">
                    <img src={S_MLP} alt="" />
                    MLP Vault Balance
                  </div>
                  <p>{toThousands(totalUser)}</p>
                </div>
              </StyledData>
            </div>
          </StyledAum>
          <StyledPosition className="grid-box">
            <div>
              <StyledFlexBox>
                <img src={Long} alt="volume" />
                <p>Long Positions</p>
              </StyledFlexBox>
              <p className="balance">${toThousands(longPositionsSize)}</p>
            </div>
            <div>
              <StyledFlexBox>
                <img src={Short} alt="volume" />
                <p>Short Positions</p>
              </StyledFlexBox>
              <p className="balance">${toThousands(shortPositionSize)}</p>
            </div>
          </StyledPosition>
        </StyledGrid>

        <div className="DashboardV2-content">
          <div className="Tab-title-section">
            <StyledTitle className="Page-title">
              <img src={Tokens} width="24" alt="Network Icon" />
              <div>
                <Trans>Tokens</Trans>
                <div className="Page-description">
                  <Trans>Platform and MOLD index tokens.</Trans>
                </div>
              </div>
            </StyledTitle>
          </div>
          <div className="DashboardV2-token-cards">
            <div className="stats-wrapper stats-wrapper--mold">
              {/* <StyledCard>
                <StyledCardTitle>
                  <div className="App-card-title-mark">
                    <div className="App-card-title-mark-icon">
                      <img src={MldsWhite} width="35" alt="MLDS Icon" />
                    </div>
                    <div className="App-card-title-mark-info">
                      <div className="App-card-title-mark-title">MLDS</div>
                      <div className="App-card-title-mark-subtitle">MLDS</div>
                    </div>
                    <div>
                      <AssetDropdown assetSymbol="MLDS" />
                    </div>
                  </div>
                </StyledCardTitle>
                <StyledStatsBlock className="stats-block">
                  <div className="App-card-content">
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Price</Trans>
                      </div>
                      <div>
                        {!mldsPrice && "..."}
                        {mldsPrice && (
                          <TooltipComponent
                            position="right-bottom"
                            className="nowrap"
                            handle={"$" + formatAmount(mldsPrice, USD_DECIMALS, 2, true)}
                            renderContent={() => (
                              <>
                                <StatsTooltipRow
                                  label={t`Price on Arbitrum`}
                                  value={formatAmount(mldsPriceFromArbitrum, USD_DECIMALS, 2, true)}
                                  showDollar={true}
                                />
                                <StatsTooltipRow
                                  label={t`Price on Avalanche`}
                                  value={formatAmount(mldsPriceFromAvalanche, USD_DECIMALS, 2, true)}
                                  showDollar={true}
                                />
                              </>
                            )}
                          />
                        )}
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Supply</Trans>
                      </div>
                      <div>{formatAmount(totalMoldSupply, MOLD_DECIMALS, 0, true)} MLDS</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Total Staked</Trans>
                      </div>
                      <div>
                        <TooltipComponent
                          position="right-bottom"
                          className="nowrap"
                          handle={`$${formatAmount(stakedMoldSupplyUsd, USD_DECIMALS, 0, true)}`}
                          renderContent={() => (
                            <StatsTooltip
                              title={t`Staked`}
                              arbitrumValue={arbitrumStakedMold}
                              avaxValue={avaxStakedMold}
                              total={totalStakedMold}
                              decimalsForConversion={MOLD_DECIMALS}
                              showDollar={false}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Market Cap</Trans>
                      </div>
                      <div>${formatAmount(moldMarketCap, USD_DECIMALS, 0, true)}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Staking APR</Trans>
                      </div>
                      <div>{formatAmount(moldMarketCap, USD_DECIMALS, 0, true)}%</div>
                    </div>
                  </div>
                </StyledStatsBlock>
                <div className="stats-piechart" onMouseLeave={onMOLDDistributionChartLeave}>
                  {moldDistributionData.length > 0 && (
                    <PieChart width={210} height={210}>
                      <Pie
                        data={moldDistributionData}
                        cx={100}
                        cy={100}
                        innerRadius={73}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        startAngle={90}
                        endAngle={-270}
                        paddingAngle={2}
                        onMouseEnter={onMOLDDistributionChartEnter}
                        onMouseOut={onMOLDDistributionChartLeave}
                        onMouseLeave={onMOLDDistributionChartLeave}
                      >
                        {moldDistributionData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={entry.color}
                            style={{
                              filter:
                                moldActiveIndex === index
                                  ? `drop-shadow(0px 0px 6px ${hexToRgba(entry.color, 0.7)})`
                                  : "none",
                              cursor: "pointer",
                            }}
                            stroke={entry.color}
                            strokeWidth={moldActiveIndex === index ? 1 : 1}
                          />
                        ))}
                      </Pie>
                      <text x={"50%"} y={"50%"} fill="white" textAnchor="middle" dominantBaseline="middle">
                        <Trans>Distribution</Trans>
                      </text>
                      <Tooltip content={<CustomTooltip />} />
                    </PieChart>
                  )}
                </div>
              </StyledCard> */}
              <StyledCard className="mlp-card">
                <StyledCardTitle className="mlp-card-title">
                  <div className="App-card-title-mark">
                    <div className="App-card-title-mark-icon">
                      <img src={L_MLP} width="35" alt="MLP Icon" />
                    </div>
                    <div className="App-card-title-mark-info">
                      <div className="App-card-title-mark-title">MLP</div>
                      <div className="App-card-title-mark-subtitle">MLP</div>
                    </div>
                    <div>
                      <AssetDropdown assetSymbol="MLP" />
                    </div>
                  </div>
                </StyledCardTitle>
                <StyledStatsBlock className="stats-block">
                  <div className="App-card-content">
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Price</Trans>
                      </div>
                      <div>${formatAmount(mlpPrice, USD_DECIMALS, 3, true)}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Supply</Trans>
                      </div>
                      <div>{formatAmount(mlpSupply, MLP_DECIMALS, 0, true)} MLP</div>
                    </div>
                    {/* <div className="App-card-row">
                      <div className="label">
                        <Trans>Total Staked</Trans>
                      </div>
                      <div>${formatAmount(mlpMarketCap, USD_DECIMALS, 0, true)}</div>
                    </div> */}
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Market Cap</Trans>
                      </div>
                      <div>${formatAmount(mlpMarketCap, USD_DECIMALS, 0, true)}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Stablecoin Percentage</Trans>
                      </div>
                      <div>{stablePercentage}%</div>
                    </div>
                  </div>
                  <div className="stats-piechart" onMouseOut={onMLPPoolChartLeave}>
                    {mlpPool.length > 0 && (
                      <PieChart width={160} height={160}>
                        <Pie
                          data={mlpPool}
                          innerRadius={73}
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="value"
                          startAngle={90}
                          endAngle={-270}
                          onMouseEnter={onMLPPoolChartEnter}
                          onMouseOut={onMLPPoolChartLeave}
                          onMouseLeave={onMLPPoolChartLeave}
                          paddingAngle={2}
                        >
                          {mlpPool.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={MLP_POOL_COLORS[entry.name]}
                              style={{
                                filter:
                                  mlpActiveIndex === index
                                    ? `drop-shadow(0px 0px 6px ${hexToRgba(MLP_POOL_COLORS[entry.name], 0.7)})`
                                    : "none",
                                cursor: "pointer",
                              }}
                              stroke={MLP_POOL_COLORS[entry.name]}
                              strokeWidth={mlpActiveIndex === index ? 1 : 1}
                            />
                          ))}
                        </Pie>
                        <text x={"50%"} y={"50%"} fill="white" textAnchor="middle" dominantBaseline="middle">
                          MLP Pool
                        </text>
                        <Tooltip content={<CustomTooltip />} />
                      </PieChart>
                    )}
                  </div>
                </StyledStatsBlock>
              </StyledCard>
            </div>
            <StyledCard className="token-table-wrapper App-card">
              <div className="App-card-title">
                <img src={L_mlds} width="35" alt="logo" /> <Trans>MOLD Index Composition</Trans>
              </div>

              <StyledTable className="token-table">
                <thead>
                  <tr>
                    <th>
                      <Trans>TOKEN</Trans>
                    </th>
                    <th>
                      <Trans>PRICE</Trans>
                    </th>
                    <th>
                      <Trans>POOL</Trans>
                    </th>
                    <th>
                      <Trans>WEIGHT</Trans>
                    </th>
                    <th>
                      <Trans>UTILIZATION</Trans>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {visibleTokens.map((token) => {
                    const tokenInfo = infoTokens[token.address];
                    let utilization = bigNumberify(0);
                    if (tokenInfo && tokenInfo.reservedAmount && tokenInfo.poolAmount && tokenInfo.poolAmount.gt(0)) {
                      utilization = tokenInfo.reservedAmount.mul(BASIS_POINTS_DIVISOR).div(tokenInfo.poolAmount);
                    }
                    let maxUsdmAmount = DEFAULT_MAX_USDM_AMOUNT;
                    if (tokenInfo.maxUsdmAmount && tokenInfo.maxUsdmAmount.gt(0)) {
                      maxUsdmAmount = tokenInfo.maxUsdmAmount;
                    }
                    const tokenImage = importImage("ic_" + token.symbol.toLowerCase() + "_40.svg");

                    return (
                      <tr key={token.symbol}>
                        <td>
                          <div className="token-symbol-wrapper">
                            <div className="App-card-title-info">
                              <div className="App-card-title-info-icon">
                                <img src={tokenImage} alt={token.symbol} width="40" />
                              </div>
                              <div className="App-card-title-info-text">
                                <div className="App-card-info-title">{token.name}</div>
                                <div className="App-card-info-subtitle">{token.symbol}</div>
                              </div>
                              <div>
                                <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>${formatKeyAmount(tokenInfo, "minPrice", USD_DECIMALS, 2, true)}</td>
                        <td>
                          <TooltipComponent
                            handle={`$${formatKeyAmount(tokenInfo, "managedUsd", USD_DECIMALS, 0, true)}`}
                            position="right-bottom"
                            className="nowrap"
                            renderContent={() => {
                              return (
                                <>
                                  <StatsTooltipRow
                                    label={t`Pool Amount`}
                                    value={`${formatKeyAmount(tokenInfo, "managedAmount", token.decimals, 0, true)} ${
                                      token.symbol
                                    }`}
                                    showDollar={false}
                                  />
                                  <StatsTooltipRow
                                    label={t`Target Min Amount`}
                                    value={`${formatKeyAmount(tokenInfo, "bufferAmount", token.decimals, 0, true)} ${
                                      token.symbol
                                    }`}
                                    showDollar={false}
                                  />
                                  <StatsTooltipRow
                                    label={t`Max ${tokenInfo.symbol} Capacity`}
                                    value={formatAmount(maxUsdmAmount, 18, 0, true)}
                                    showDollar={true}
                                  />
                                </>
                              );
                            }}
                          />
                        </td>
                        <td>{getWeightText(tokenInfo)}</td>
                        <td>{formatAmount(utilization, 2, 2, false)}%</td>
                      </tr>
                    );
                  })}
                </tbody>
              </StyledTable>
            </StyledCard>
            <div className="token-grid">
              {visibleTokens.map((token) => {
                const tokenInfo = infoTokens[token.address];
                let utilization = bigNumberify(0);
                if (tokenInfo && tokenInfo.reservedAmount && tokenInfo.poolAmount && tokenInfo.poolAmount.gt(0)) {
                  utilization = tokenInfo.reservedAmount.mul(BASIS_POINTS_DIVISOR).div(tokenInfo.poolAmount);
                }
                let maxUsdmAmount = DEFAULT_MAX_USDM_AMOUNT;
                if (tokenInfo.maxUsdmAmount && tokenInfo.maxUsdmAmount.gt(0)) {
                  maxUsdmAmount = tokenInfo.maxUsdmAmount;
                }

                const tokenImage = importImage("ic_" + token.symbol.toLowerCase() + "_24.svg");
                return (
                  <div className="App-card" key={token.symbol}>
                    <div className="App-card-title">
                      <div className="mobile-token-card">
                        <img src={tokenImage} alt={token.symbol} width="20px" />
                        <div className="token-symbol-text">{token.symbol}</div>
                        <div>
                          <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
                        </div>
                      </div>
                    </div>
                    <div className="App-card-divider"></div>
                    <div className="App-card-content">
                      <div className="App-card-row">
                        <div className="label">
                          <Trans>Price</Trans>
                        </div>
                        <div>${formatKeyAmount(tokenInfo, "minPrice", USD_DECIMALS, 2, true)}</div>
                      </div>
                      <div className="App-card-row">
                        <div className="label">
                          <Trans>Pool</Trans>
                        </div>
                        <div>
                          <TooltipComponent
                            handle={`$${formatKeyAmount(tokenInfo, "managedUsd", USD_DECIMALS, 0, true)}`}
                            position="right-bottom"
                            renderContent={() => {
                              return (
                                <>
                                  <StatsTooltipRow
                                    label={t`Pool Amount`}
                                    value={`${formatKeyAmount(tokenInfo, "managedAmount", token.decimals, 0, true)} ${
                                      token.symbol
                                    }`}
                                    showDollar={false}
                                  />
                                  <StatsTooltipRow
                                    label={t`Target Min Amount`}
                                    value={`${formatKeyAmount(tokenInfo, "bufferAmount", token.decimals, 0, true)} ${
                                      token.symbol
                                    }`}
                                    showDollar={false}
                                  />
                                  <StatsTooltipRow
                                    label={t`Max ${tokenInfo.symbol} Capacity`}
                                    value={formatAmount(maxUsdmAmount, 18, 0, true)}
                                  />
                                </>
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="App-card-row">
                        <div className="label">
                          <Trans>Weight</Trans>
                        </div>
                        <div>{getWeightText(tokenInfo)}</div>
                      </div>
                      <div className="App-card-row">
                        <div className="label">
                          <Trans>Utilization</Trans>
                        </div>
                        <div>{formatAmount(utilization, 2, 2, false)}%</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </SEO>
  );
}

const StyledGrid = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-auto-rows: 20.3rem;
  .grid-box {
    width: 77.9rem;
    height: 20.3rem;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    grid-column: 2;
  }
  .grid-box > div {
    min-width: 21rem;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    .grid-box {
      width: 100%;
      height: auto;
    }
    .grid-box > div {
      min-width: auto;
    }
  }
`;

const StyledTotal = styled.div`
  width: 50rem;
  height: 42.5rem;
  background: url(${BoxBg1});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 3rem 4.8rem;
  font-weight: 500;
  color: #ffffff;
  grid-row: 1 / 2;
  grid-column: 1;
  .default-btn {
    width: 100%;
    font-size: 2rem;
    font-weight: 500;
    height: 4.4rem;
    justify-content: center;
    span {
      margin: 0 auto;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 3rem;
  }
`;

const StyledFlexBox = styled.div`
  display: flex;
  align-items: center;
  font-size: 2.2rem;
  img {
    margin-right: 1.2rem;
    width: 3rem;
    height: 3rem;
  }
`;

const StyledBalance = styled.div`
  font-weight: 600;
  margin-top: 2rem;
  margin-bottom: 3.5rem;
  p {
    font-size: 3.2rem;
    margin-bottom: 0.5rem;
  }
`;

const StyledAum = styled.div`
  background: url(${BoxBg2});
  padding: 3.8rem 8.2rem;
  grid-row: 1;
  display: flex;
  justify-content: space-between;
  .trades .flex {
    visibility: hidden;
  }
  @media (max-width: 768px) {
    padding: 3rem;
    flex-direction: column;
  }
`;

const StyledData = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  p: first-of-type {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  p: last-of-type {
    font-size: 3.2rem;
    margin-top: 0.5rem;
  }
  .flex {
    img {
      margin-right: 0.5rem;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    > div {
      margin-bottom: 2rem;
    }
  }
`;

const StyledPosition = styled.div`
  background: url(${BoxBg2});
  padding: 5.4rem 8.2rem;
  grid-row: 2;
  display: flex;
  justify-content: space-between;
  .balance {
    font-size: 3.2rem;
    margin-top: 2rem;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 3rem;
    > div {
      margin-bottom: 2rem;
    }
  }
`;

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 6.7rem;
    height: 6.7rem;
    margin-left: 4rem;
    margin-right: 1.2rem;
  }
  @media (max-width: 768px) {
    img {
      display: none;
    }
  }
`;

const StyledCard = styled.div`
  border: 1px solid #694f18;
  border-radius: 1rem;
  background: #191620;
  &.mlp-card {
    .stats-block {
      display: flex;
    }
    .App-card-content {
      flex: 1;
    }
  }
  .mlp-card-title {
    background: rgba(109, 212, 0, 0.43);
  }
  .App-card-title {
    font-size: 2.8rem;
    color: #fa9b00;
  }
  &.token-table-wrapper {
    padding: 4.7rem 5rem 6.5rem;
  }
  @media (max-width: 768px) {
    .stats-block {
      flex-direction: column;
    }
    .stats-piechart {
      margin-top: 2rem;
    }
  }
`;

const StyledCardTitle = styled.div`
  width: 30.5rem;
  height: 5.9rem;
  background: linear-gradient(to right, rgba(255, 21, 115, 0.43), rgba(255, 207, 12, 0.43));
  border-radius: 1rem 10rem 10rem 0;
  font-size: 1.8rem;
  line-height: 2.1rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .App-card-title-mark-icon img {
    margin-left: 4.8rem;
  }
  @media (max-width: 768px) {
    img {
      margin-left: 2rem;
    }
  }
`;

const StyledStatsBlock = styled.div`
  padding: 2.7rem 4.5rem;
  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const StyledTable = styled.table`
  // border-collapse: collapse;
  // thead .divider {
  //   border-bottom: 1px solid #a6a3b8;
  // }
`;
