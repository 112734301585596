/*
 * @Author: your name
 * @Date: 2021-11-29 17:56:47
 * @LastEditTime: 2022-11-07 10:09:50
 * @LastEditors: Errett Smalley ErrettSmalley@protonmail.com
 * @FilePath: /Capricorn_front_end/src/views/Faucet/index.tsx
 */
import React, { useState, useCallback, useMemo } from "react";
import { ethers } from "ethers";
import styled from "styled-components";
import { useWeb3React } from "@web3-react/core";

import { useChainId } from "lib/chains";
import { callContract } from "lib/contracts";
import Token from "abis/Token.json";
import { getTokenBySymbol } from "config/tokens";

import FaucetBg from "img/section_bg.png";
import Footer from "components/Footer/Footer";

type Props = {
  setPendingTxns?: (txns: any) => void;
};
const Faucet: React.FC<Props> = ({ setPendingTxns }) => {
  const { account, library } = useWeb3React();
  const { chainId } = useChainId();

  const [address, setAddress] = useState(account);
  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setAddress(e.currentTarget.value);
    },
    [setAddress]
  );
  useMemo(() => setAddress(account), [account]);
  const successMsg = "Transaction completed!";

  const handleFaucet = async (name: string) => {
    const token = getTokenBySymbol(chainId, name);
    const contract = new ethers.Contract(token.address, Token.abi, library.getSigner());

    callContract(chainId, contract, "faucet", [], {
      sentMsg: "Faucet submitted!",
      successMsg,
      failMsg: "Faucet failed.",
      setPendingTxns,
    })
      .then(async (res) => {})
      .finally(() => {});
  };

  return (
    <StyledWrap>
      <StyledContent>
        <StyledTitle>
          <p>
            <i className="fa-solid fa-faucet-drip"></i>
          </p>
          {/* <p>Click The Link To Claim The Examination Token</p>
          <p className="link">
            Method 1:{" "}
            <a href="https://CFX_TESTNETfaucet.com/" rel="noreferrer" target="_blank">
              https://CFX_TESTNETfaucet.com/
            </a>
          </p> */}
        </StyledTitle>
        <StyledBox className="title">Claim Test Token</StyledBox>
        {/* <StyledBox>
          <input onChange={handleChange} placeholder="address" value={address || ""} />
        </StyledBox> */}
        <StyledBox>
          {/* <button className="default-btn" onClick={() => handleFaucet("WMATIC")}>
            Get WMATIC
          </button>
          <button className="default-btn" onClick={() => handleFaucet("WETH")}>
            Get WETH
          </button> */}
          <button className="default-btn" onClick={() => handleFaucet("USDT")}>
            Get USDT
          </button>
          {/* <button className="default-btn" onClick={() => handleFaucet("WBTC")}>
            Get WBTC
          </button> */}
        </StyledBox>
      </StyledContent>
      <Footer />
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  width: 100%;
  background: url(${FaucetBg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const StyledContent = styled.div`
  width: 70rem;
  height: 45rem;
  border-radius: 10px;
  padding: 4rem 0;
  text-align: center;
  color: #fff;
  font-weight: bold;
  background: #000;
  border: 1px solid #694f18;
  margin: 4rem auto;
  > p {
    margin: 5rem 0 1rem;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const StyledTitle = styled.div`
  font-size: 2.2rem;
  margin: 2rem 0 4rem;
  text-align: center;
  i {
    color: #ffb72e;
    font-size: 4rem;
  }
  p {
    font-family: Aleo;
    margin-bottom: 1.3rem;
  }
  .link {
    font-size: 2rem;
  }
  @media (max-width: 768px) {
    font-size: 2rem;
    margin: 2rem 0 1.5rem;
    .fa-shower {
      margin-right: 1rem;
    }
  }
`;

const StyledBox = styled.div`
  font-family: Aleo;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-bottom: 3rem;
  button {
    font-family: Aleo;
    width: 15rem;
    height: 4rem;
    margin-left: 1rem;
    justify-content: center;
  }
  input {
    width: 60%;
    background: #2d250d;
    border-radius: 1rem;
    box-shadow: inset 0 0 3rem 0.5rem hsl(0deg 0% 100% / 1%);
  }
  &.title {
    font-size: 3rem;
  }
  @media (max-width: 768px) {
    margin-bottom: 1rem;
    flex-direction: column;
    button {
      height: 1.6rem;
      margin-top: 1rem;
    }
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
  font-weight: 700;
  padding: 5px 10px;
  cursor: pointer;
  color: ${(props) => props.theme.color.primary.main};
  @media (max-width: 768px) {
    font-size: 1rem;
    margin-top: 1rem;
    margin-left: 0px;
    padding: 0;
  }
`;
export default Faucet;
