import React from "react";
import styled from "styled-components";

import Footer from "components/Footer/Footer";
import "./Home.css";

import simpleSwapIcon from "img/ic_simpleswaps.png";
import costIcon from "img/ic_cost.png";
import liquidityIcon from "img/ic_liquidity.png";
import totaluserIcon from "img/ic_totaluser.png";

import statsIcon from "img/ic_stats.png";
import tradingIcon from "img/ic_trading.png";
import secureIcon from "img/ic_secure.png";

import useSWR from "swr";

import { USD_DECIMALS, getTotalVolumeSum, arrayURLFetcher } from "lib/legacy";
import { useChainId } from "lib/chains";

import { useUserStat } from "domain/legacy";

import HomeInfoBg from "img/home_info_bg.png";

import TokenCard from "components/TokenCard/TokenCard";
import { Trans } from "@lingui/macro";
import { HeaderLink } from "components/Header/HeaderLink";
import { getServerUrl, MOLD_STATS_API_URL } from "config/backend";
import { bigNumberify, formatAmount, numberWithCommas, toThousands } from "lib/numbers";

export default function Home({ showRedirectModal, redirectPopupTimestamp }) {
  const { chainId } = useChainId();

  const { data: profile } = useSWR(`${MOLD_STATS_API_URL}/dashboard?id=${chainId}`, {
    fetcher: arrayURLFetcher,
  });

  const totalVolume = profile?.length ? parseInt(profile[0].TotalTrade) : 0;
  const totalInterest = profile?.length ? parseInt(profile[0].TotalLongAndShort) : 0;
  const totalUsers = profile?.length ? parseInt(profile[0].TotalUser) : 0;
  const LaunchExchangeButton = () => {
    return (
      <HeaderLink
        className="default-btn"
        to="/trade"
        redirectPopupTimestamp={redirectPopupTimestamp}
        showRedirectModal={showRedirectModal}
      >
        <Trans>Launch App</Trans>
      </HeaderLink>
    );
  };

  return (
    <div className="Home">
      <StyledHomeTop className="Home-top">
        {/* <div className="Home-top-image"></div> */}
        <div className="Home-title-section-container default-container">
          <div className="Home-title-section">
            <div className="Home-title">
              <Trans>
                Decentralized
                <br />
                Derivatives Platform
              </Trans>
            </div>
            <div className="Home-description">
              <Trans>
                Trade perpetual futures of cryptos, stocks, and commodities with leverage and earn real yields
              </Trans>
            </div>
            <LaunchExchangeButton />
          </div>
        </div>
        <div className="Home-latest-info-container default-container">
          <StyledHomeInfo className="Home-latest-info-block">
            <img src={tradingIcon} alt="Total Trading Volume Icon" className="Home-latest-info__icon" />
            <div className="Home-latest-info-content">
              <div className="Home-latest-info__title">
                <Trans>Total Trading Volume</Trans>
              </div>
              <div className="Home-latest-info__value">${toThousands(totalVolume)}</div>
              {/* <div className="Home-latest-info__value">${formatAmount(totalVolumeSum, USD_DECIMALS, 0, true)}</div> */}
            </div>
          </StyledHomeInfo>
          <StyledHomeInfo className="Home-latest-info-block">
            <img src={statsIcon} alt="Open Interest Icon" className="Home-latest-info__icon" />
            <div className="Home-latest-info-content">
              <div className="Home-latest-info__title">
                <Trans>Open Interest</Trans>
              </div>
              <div className="Home-latest-info__value">${toThousands(totalInterest)}</div>
              {/* <div className="Home-latest-info__value">${formatAmount(openInterest, USD_DECIMALS, 0, true)}</div> */}
            </div>
          </StyledHomeInfo>
          <StyledHomeInfo className="Home-latest-info-block">
            <img src={totaluserIcon} alt="Total Users Icon" className="Home-latest-info__icon" />
            <div className="Home-latest-info-content">
              <div className="Home-latest-info__title">
                <Trans>Total Users</Trans>
              </div>
              <div className="Home-latest-info__value">{toThousands(totalUsers)}</div>
              {/* <div className="Home-latest-info__value">{numberWithCommas(totalUsers.toFixed(0))}</div> */}
            </div>
          </StyledHomeInfo>
        </div>
      </StyledHomeTop>
      <div className="Home-benefits-section">
        <div className="Home-benefits default-container">
          <StyledBenefit className="Home-benefit">
            <img src={liquidityIcon} alt="Diversified Products Icon" className="Home-benefit-icon-symbol" />
            <div className="Home-benefit-icon">
              <div className="Home-benefit-title">
                <Trans>Diversified Products</Trans>
              </div>
            </div>
            <div className="Home-benefit-description">
              <Trans>Provide various derivatives such as cryptocurrency perpetual, foreign currency and stocks</Trans>
            </div>
          </StyledBenefit>
          <StyledBenefit className="Home-benefit">
            <img src={costIcon} alt="Substantial Yield Icon" className="Home-benefit-icon-symbol" />
            <div className="Home-benefit-icon">
              <div className="Home-benefit-title">
                <Trans>Substantial Yield</Trans>
              </div>
            </div>
            <div className="Home-benefit-description">
              <Trans>Value captured from the platform given back to users generously and fairly</Trans>
            </div>
          </StyledBenefit>
          <StyledBenefit className="Home-benefit">
            <img src={simpleSwapIcon} alt="Social Drive Icon" className="Home-benefit-icon-symbol" />
            <div className="Home-benefit-icon">
              <div className="Home-benefit-title">
                <Trans>Social Drive</Trans>
              </div>
            </div>
            <div className="Home-benefit-description">
              <Trans>Increase trading ability based on user's social relationship</Trans>
            </div>
          </StyledBenefit>
          <StyledBenefit className="Home-benefit">
            <img src={secureIcon} alt="Secure Asset Icon" className="Home-benefit-icon-symbol" />

            <div className="Home-benefit-icon">
              <div className="Home-benefit-title">
                <Trans>Secure Asset</Trans>
              </div>
            </div>
            <div className="Home-benefit-description">
              <Trans>Assets are managed by the user's own wallet instead of custody</Trans>
            </div>
          </StyledBenefit>
        </div>
      </div>
      {/* <div className="Home-cta-section">
        <div className="Home-cta-container default-container">
          <StyledCtaInfo className="Home-cta-info">
            <div className="Home-cta-info__title">
              <Trans>Available on your preferred network</Trans>
            </div>
            <div className="Home-cta-info__description">
              <Trans>MOLD is currently live on Arbitrum and Avalanche.</Trans>
            </div>
          </StyledCtaInfo>
          <StyledCtaInfo className="Home-cta-info">
            <StyledOptions>
              <img src={arbitrumIcon} width="96" alt="Arbitrum Icon" />
            </StyledOptions>
            <div className="Home-cta-option-info">
              <div className="Home-cta-option-title">Arbitrum</div>
              <LaunchExchangeButton />
            </div>
          </StyledCtaInfo>
          <StyledCtaInfo className="Home-cta-info">
            <StyledOptions>
              <img src={avaxIcon} width="96" alt="Avalanche Icon" />
            </StyledOptions>
            <div className="Home-cta-option-info">
              <div className="Home-cta-option-title">Avalanche</div>
              <LaunchExchangeButton />
            </div>
          </StyledCtaInfo>
        </div>
      </div> */}
      <div className="Home-token-card-section">
        <div className="Home-token-card-container default-container">
          <div className="Home-token-card-info">
            <div className="Home-token-card-info__title">
              <Trans>Ecosystem Tokens</Trans>
            </div>
          </div>
          <TokenCard showRedirectModal={showRedirectModal} redirectPopupTimestamp={redirectPopupTimestamp} />
        </div>
      </div>

      <Footer showRedirectModal={showRedirectModal} redirectPopupTimestamp={redirectPopupTimestamp} />
    </div>
  );
}

const StyledHomeTop = styled.div`
  .default-btn {
    border-radius: 2.2rem;
  }
`;

const StyledHomeInfo = styled.div`
  border-radius: 2.4rem;
  background: url(${HomeInfoBg});
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 41rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
  margin: 1.6rem 0;
`;

const StyledBenefit = styled.div`
  img {
    height: 7rem;
  }
`;

const StyledCtaInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 8rem;
  .Home-cta-option-info {
    display: flex;
    align-items: center;
  }
  .Home-cta-option-title {
    margin-right: 3rem;
    text-align: center;
  }
  .default-btn {
    font-size: 1.8rem;
    width: 15rem;
    height: 4rem;
    border-radius: 8px;
    border: 1px solid #fa9b00;
    background: none;
    color: #fa9b00 !important;
    justify-content: center;
  }
`;

const StyledOptions = styled.div``;
