import { getContract } from "config/contracts";
import useSWR from "swr";
import { contractFetcher } from "lib/contracts";
import Reader from "abis/Reader.json";
import {
  BASIS_POINTS_DIVISOR,
  DEFAULT_MAX_USDM_AMOUNT,
  MAX_PRICE_DEVIATION_BASIS_POINTS,
  USD_DECIMALS,
  // MUSD_ADDRESS,
  arrayURLFetcher,
} from "lib/legacy";
import { getServerUrl, PYTH_API_URL, priceIds } from "config/backend";
import { InfoTokens, Token, TokenInfo } from "./types";
import { BigNumber } from "ethers";
import { bigNumberify, expandDecimals, expandStringToDecimals } from "lib/numbers";
import { getTokens, getWhitelistedTokens } from "config/tokens";
import { Web3Provider } from "@ethersproject/providers";

export function useInfoTokens(
  library: Web3Provider,
  chainId: number,
  active: boolean,
  tokenBalances?: BigNumber[],
  fundingRateInfo?: BigNumber[],
  vaultPropsLength?: number
) {
  const tokens = getTokens(chainId);
  const ReaderAddress = getContract(chainId, "Reader");
  const vaultAddress = getContract(chainId, "Vault");
  const positionManagerAddress = getContract(chainId, "PositionManager");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const whitelistedTokenAddresses = whitelistedTokens.map((token) => token.address);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: BTCPriceDatas } = useSWR(`${PYTH_API_URL}/api/latest_price_feeds?ids[]=${priceIds.BTC}`, {
    fetcher: arrayURLFetcher,
  });

  const BTCPrice = BTCPriceDatas?.[0]?.[0].price;

  const { data: vaultTokenInfo } = useSWR<BigNumber[], any>(
    [`useInfoTokens:${active}${BTCPrice}`, chainId, ReaderAddress, "getVaultTokenInfo"],
    {
      fetcher: contractFetcher(library, Reader, [
        vaultAddress,
        positionManagerAddress,
        nativeTokenAddress,
        whitelistedTokenAddresses,
      ]),
    }
  );

  // const indexPricesUrl = getServerUrl(chainId, "/prices");

  // const { data: indexPrices } = useSWR([indexPricesUrl], {
  //   // @ts-ignore spread args incorrect type
  //   fetcher: (...args) => fetch(...args).then((res) => res.json()),
  //   refreshInterval: 500,
  //   refreshWhenHidden: true,
  // });

  return {
    infoTokens: getInfoTokens(
      tokens,
      tokenBalances,
      whitelistedTokens,
      vaultTokenInfo,
      fundingRateInfo,
      vaultPropsLength,
      nativeTokenAddress
    ),
  };
}

function getInfoTokens(
  tokens: Token[],
  tokenBalances: BigNumber[] | undefined,
  whitelistedTokens: Token[],
  vaultTokenInfo: BigNumber[] | undefined,
  fundingRateInfo: BigNumber[] | undefined,
  vaultPropsLength: number | undefined,
  // indexPrices: { [address: string]: BigNumber },
  nativeTokenAddress: string
): InfoTokens {
  if (!vaultPropsLength) {
    vaultPropsLength = 12;
  }
  const fundingRatePropsLength = 2;
  const infoTokens: InfoTokens = {};

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: BTCPriceDatas } = useSWR(`${PYTH_API_URL}/api/latest_price_feeds?ids[]=${priceIds.BTC}`, {
    fetcher: arrayURLFetcher,
  });

  const BTCPrice = BTCPriceDatas?.[0]?.[0].price;
  const BTCMaxPrice: BigNumber = BTCPrice
    ? expandStringToDecimals(parseFloat(BTCPrice.price) + parseFloat(BTCPrice.conf), BTCPrice.expo)
    : expandDecimals(1, USD_DECIMALS);
  const BTCMinPrice: BigNumber = BTCPrice
    ? expandStringToDecimals(parseFloat(BTCPrice.price) - parseFloat(BTCPrice.conf), BTCPrice.expo)
    : expandDecimals(1, USD_DECIMALS);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: ETHPriceDatas } = useSWR(`${PYTH_API_URL}/api/latest_price_feeds?ids[]=${priceIds.ETH}`, {
    fetcher: arrayURLFetcher,
  });

  const ETHPrice = ETHPriceDatas?.[0]?.[0].price;
  const ETHMaxPrice: BigNumber = ETHPrice
    ? expandStringToDecimals(parseFloat(ETHPrice.price) + parseFloat(ETHPrice.conf), ETHPrice.expo)
    : expandDecimals(1, USD_DECIMALS);
  const ETHMinPrice: BigNumber = ETHPrice
    ? expandStringToDecimals(parseFloat(ETHPrice.price) - parseFloat(ETHPrice.conf), ETHPrice.expo)
    : expandDecimals(1, USD_DECIMALS);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: USDCPriceDatas } = useSWR(`${PYTH_API_URL}/api/latest_price_feeds?ids[]=${priceIds.USDT}`, {
    fetcher: arrayURLFetcher,
  });

  const USDCPrice = USDCPriceDatas?.[0]?.[0].price;
  const USDCMaxPrice: BigNumber = USDCPrice
    ? expandStringToDecimals(parseFloat(USDCPrice.price) + parseFloat(USDCPrice.conf), USDCPrice.expo)
    : expandDecimals(1, USD_DECIMALS);
  const USDCMinPrice: BigNumber = USDCPrice
    ? expandStringToDecimals(parseFloat(USDCPrice.price) - parseFloat(USDCPrice.conf), USDCPrice.expo)
    : expandDecimals(1, USD_DECIMALS);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: ARBPriceDatas } = useSWR(`${PYTH_API_URL}/api/latest_price_feeds?ids[]=${priceIds.ARB}`, {
    fetcher: arrayURLFetcher,
  });

  const ARBPrice = ARBPriceDatas?.[0]?.[0].price;
  const ARBMaxPrice: BigNumber = ARBPrice
    ? expandStringToDecimals(parseFloat(ARBPrice.price) + parseFloat(ARBPrice.conf), ARBPrice.expo)
    : expandDecimals(1, USD_DECIMALS);
  const ARBMinPrice: BigNumber = ARBPrice
    ? expandStringToDecimals(parseFloat(ARBPrice.price) - parseFloat(ARBPrice.conf), ARBPrice.expo)
    : expandDecimals(1, USD_DECIMALS);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: DOGEPriceDatas } = useSWR(`${PYTH_API_URL}/api/latest_price_feeds?ids[]=${priceIds.DOGE}`, {
    fetcher: arrayURLFetcher,
  });

  const DOGEPrice = DOGEPriceDatas?.[0]?.[0].price;
  const DOGEMaxPrice: BigNumber = DOGEPrice
    ? expandStringToDecimals(parseFloat(DOGEPrice.price) + parseFloat(DOGEPrice.conf), DOGEPrice.expo)
    : expandDecimals(1, USD_DECIMALS);
  const DOGEMinPrice: BigNumber = DOGEPrice
    ? expandStringToDecimals(parseFloat(DOGEPrice.price) - parseFloat(DOGEPrice.conf), DOGEPrice.expo)
    : expandDecimals(1, USD_DECIMALS);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: FILPriceDatas } = useSWR(`${PYTH_API_URL}/api/latest_price_feeds?ids[]=${priceIds.FIL}`, {
    fetcher: arrayURLFetcher,
  });

  const FILPrice = FILPriceDatas?.[0]?.[0].price;
  const FILMaxPrice: BigNumber = FILPrice
    ? expandStringToDecimals(parseFloat(FILPrice.price) + parseFloat(FILPrice.conf), FILPrice.expo)
    : expandDecimals(1, USD_DECIMALS);
  const FILMinPrice: BigNumber = FILPrice
    ? expandStringToDecimals(parseFloat(FILPrice.price) - parseFloat(FILPrice.conf), FILPrice.expo)
    : expandDecimals(1, USD_DECIMALS);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: LTCPriceDatas } = useSWR(`${PYTH_API_URL}/api/latest_price_feeds?ids[]=${priceIds.LTC}`, {
    fetcher: arrayURLFetcher,
  });

  const LTCPrice = LTCPriceDatas?.[0]?.[0].price;
  const LTCMaxPrice: BigNumber = LTCPrice
    ? expandStringToDecimals(parseFloat(LTCPrice.price) + parseFloat(LTCPrice.conf), LTCPrice.expo)
    : expandDecimals(1, USD_DECIMALS);
  const LTCMinPrice: BigNumber = LTCPrice
    ? expandStringToDecimals(parseFloat(LTCPrice.price) - parseFloat(LTCPrice.conf), LTCPrice.expo)
    : expandDecimals(1, USD_DECIMALS);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: MSFTPriceDatas } = useSWR(`${PYTH_API_URL}/api/latest_price_feeds?ids[]=${priceIds.MSFT}`, {
    fetcher: arrayURLFetcher,
  });

  const MSFTPrice = MSFTPriceDatas?.[0]?.[0].price;
  const MSFTMaxPrice: BigNumber = MSFTPrice
    ? expandStringToDecimals(parseFloat(MSFTPrice.price) + parseFloat(MSFTPrice.conf), MSFTPrice.expo)
    : expandDecimals(1, USD_DECIMALS);
  const MSFTMinPrice: BigNumber = MSFTPrice
    ? expandStringToDecimals(parseFloat(MSFTPrice.price) - parseFloat(MSFTPrice.conf), MSFTPrice.expo)
    : expandDecimals(1, USD_DECIMALS);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: TSLAPriceDatas } = useSWR(`${PYTH_API_URL}/api/latest_price_feeds?ids[]=${priceIds.TSLA}`, {
    fetcher: arrayURLFetcher,
  });

  const TSLAPrice = TSLAPriceDatas?.[0]?.[0].price;
  const TSLAMaxPrice: BigNumber = TSLAPrice
    ? expandStringToDecimals(parseFloat(TSLAPrice.price) + parseFloat(TSLAPrice.conf), TSLAPrice.expo)
    : expandDecimals(1, USD_DECIMALS);
  const TSLAMinPrice: BigNumber = TSLAPrice
    ? expandStringToDecimals(parseFloat(TSLAPrice.price) - parseFloat(TSLAPrice.conf), TSLAPrice.expo)
    : expandDecimals(1, USD_DECIMALS);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: CFXPriceDatas } = useSWR(`${PYTH_API_URL}/api/latest_price_feeds?ids[]=${priceIds.CFX}`, {
    fetcher: arrayURLFetcher,
  });

  const CFXPrice = CFXPriceDatas?.[0]?.[0].price;
  const CFXMaxPrice: BigNumber = CFXPrice
    ? expandStringToDecimals(parseFloat(CFXPrice.price) + parseFloat(CFXPrice.conf), CFXPrice.expo)
    : expandDecimals(1, USD_DECIMALS);
  const CFXMinPrice: BigNumber = CFXPrice
    ? expandStringToDecimals(parseFloat(CFXPrice.price) - parseFloat(CFXPrice.conf), CFXPrice.expo)
    : expandDecimals(1, USD_DECIMALS);

  for (let i = 0; i < tokens.length; i++) {
    const token = JSON.parse(JSON.stringify(tokens[i])) as TokenInfo;

    if (tokenBalances) {
      token.balance = tokenBalances[i];
    }

    // if (token.address === MUSD_ADDRESS) {
    //   token.minPrice = expandDecimals(1, USD_DECIMALS);
    //   token.maxPrice = expandDecimals(1, USD_DECIMALS);
    // }

    infoTokens[token.address] = token;
  }

  for (let i = 0; i < whitelistedTokens.length; i++) {
    const token = JSON.parse(JSON.stringify(whitelistedTokens[i])) as TokenInfo;

    if (vaultTokenInfo) {
      token.poolAmount = vaultTokenInfo[i * vaultPropsLength];
      token.reservedAmount = vaultTokenInfo[i * vaultPropsLength + 1];
      token.availableAmount = token.poolAmount.sub(token.reservedAmount);
      token.usdmAmount = vaultTokenInfo[i * vaultPropsLength + 2];
      // token.redemptionAmount = vaultTokenInfo[i * vaultPropsLength + 3];
      token.weight = vaultTokenInfo[i * vaultPropsLength + 3];
      token.bufferAmount = vaultTokenInfo[i * vaultPropsLength + 4];
      token.maxUsdmAmount = vaultTokenInfo[i * vaultPropsLength + 5];
      token.globalShortSize = vaultTokenInfo[i * vaultPropsLength + 6];
      token.maxGlobalShortSize = vaultTokenInfo[i * vaultPropsLength + 7];
      token.maxGlobalLongSize = vaultTokenInfo[i * vaultPropsLength + 8];
      token.guaranteedUsd = vaultTokenInfo[i * vaultPropsLength + 9];
      token.minProfitBasisPoint = vaultTokenInfo[i * vaultPropsLength + 10];
      token.minProfitTime = vaultTokenInfo[i * vaultPropsLength + 11];
      switch (token.symbol) {
        case "BTC":
          token.minPrice = BTCMinPrice;
          token.maxPrice = BTCMaxPrice;
          break;
        case "WBTC":
          token.minPrice = BTCMinPrice;
          token.maxPrice = BTCMaxPrice;
          break;
        case "WETH":
          token.minPrice = ETHMinPrice;
          token.maxPrice = ETHMaxPrice;
          break;
        case "USDT":
          token.minPrice = USDCMinPrice;
          token.maxPrice = USDCMaxPrice;
          break;
        case "ARB":
          token.minPrice = ARBMinPrice;
          token.maxPrice = ARBMaxPrice;
          break;
        case "DOGE":
          token.minPrice = DOGEMinPrice;
          token.maxPrice = DOGEMaxPrice;
          break;
        case "FIL":
          token.minPrice = FILMinPrice;
          token.maxPrice = FILMaxPrice;
          break;
        case "LTC":
          token.minPrice = LTCMinPrice;
          token.maxPrice = LTCMaxPrice;
          break;
        case "MSFT":
          token.minPrice = MSFTMinPrice;
          token.maxPrice = MSFTMaxPrice;
          break;
        case "TSLA":
          token.minPrice = TSLAMinPrice;
          token.maxPrice = TSLAMaxPrice;
          break;
        case "CFX":
          token.minPrice = CFXMinPrice;
          token.maxPrice = CFXMaxPrice;
          break;
        default:
          token.minPrice = expandDecimals(1, USD_DECIMALS);
          token.maxPrice = expandDecimals(1, USD_DECIMALS);
          break;
      }

      // save minPrice and maxPrice as setTokenUsingIndexPrices may override it
      token.contractMinPrice = token.minPrice;
      token.contractMaxPrice = token.maxPrice;

      token.maxAvailableShort = bigNumberify(0)!;

      token.hasMaxAvailableShort = false;
      if (token.maxGlobalShortSize.gt(0)) {
        token.hasMaxAvailableShort = true;
        if (token.maxGlobalShortSize.gt(token.globalShortSize)) {
          token.maxAvailableShort = token.maxGlobalShortSize.sub(token.globalShortSize);
        }
      }

      if (token.maxUsdmAmount.eq(0)) {
        token.maxUsdmAmount = DEFAULT_MAX_USDM_AMOUNT;
      }

      token.availableUsd = token.isStable
        ? token.poolAmount.mul(token.minPrice).div(expandDecimals(1, token.decimals))
        : token.availableAmount.mul(token.minPrice).div(expandDecimals(1, token.decimals));

      token.maxAvailableLong = bigNumberify(0)!;
      token.hasMaxAvailableLong = false;
      if (token.maxGlobalLongSize.gt(0)) {
        token.hasMaxAvailableLong = true;

        if (token.maxGlobalLongSize.gt(token.guaranteedUsd)) {
          const remainingLongSize = token.maxGlobalLongSize.sub(token.guaranteedUsd);
          token.maxAvailableLong = remainingLongSize.lt(token.availableUsd) ? remainingLongSize : token.availableUsd;
        }
      } else {
        token.maxAvailableLong = token.availableUsd;
      }

      token.maxLongCapacity =
        token.maxGlobalLongSize.gt(0) && token.maxGlobalLongSize.lt(token.availableUsd.add(token.guaranteedUsd))
          ? token.maxGlobalLongSize
          : token.availableUsd.add(token.guaranteedUsd);

      token.managedUsd = token.availableUsd.add(token.guaranteedUsd);
      token.managedAmount = token.managedUsd.mul(expandDecimals(1, token.decimals)).div(token.minPrice);

      // setTokenUsingIndexPrices(token, indexPrices, nativeTokenAddress);
    }

    if (fundingRateInfo) {
      token.fundingRate = fundingRateInfo[i * fundingRatePropsLength];
      token.cumulativeFundingRate = fundingRateInfo[i * fundingRatePropsLength + 1];
    }

    if (infoTokens[token.address]) {
      token.balance = infoTokens[token.address].balance;
    }

    infoTokens[token.address] = token;
  }

  return infoTokens;
}

function setTokenUsingIndexPrices(
  token: TokenInfo,
  indexPrices: { [address: string]: BigNumber },
  nativeTokenAddress: string
) {
  if (!indexPrices) {
    return;
  }

  const tokenAddress = token.isNative ? nativeTokenAddress : token.address;

  const indexPrice = indexPrices[tokenAddress];

  if (!indexPrice) {
    return;
  }

  const indexPriceBn = bigNumberify(indexPrice)!;

  if (indexPriceBn.eq(0)) {
    return;
  }

  const spread = token.maxPrice!.sub(token.minPrice!);
  const spreadBps = spread.mul(BASIS_POINTS_DIVISOR).div(token.maxPrice!.add(token.minPrice!).div(2));

  if (spreadBps.gt(MAX_PRICE_DEVIATION_BASIS_POINTS - 50)) {
    // only set one of the values as there will be a spread between the index price and the Chainlink price
    if (indexPriceBn.gt(token.minPrimaryPrice!)) {
      token.maxPrice = indexPriceBn;
    } else {
      token.minPrice = indexPriceBn;
    }
    return;
  }

  const halfSpreadBps = spreadBps.div(2).toNumber();
  token.maxPrice = indexPriceBn.mul(BASIS_POINTS_DIVISOR + halfSpreadBps).div(BASIS_POINTS_DIVISOR);
  token.minPrice = indexPriceBn.mul(BASIS_POINTS_DIVISOR - halfSpreadBps).div(BASIS_POINTS_DIVISOR);
}
