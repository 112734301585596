import { ethers } from "ethers";
import { sample } from "lodash";
import { NetworkMetadata } from "lib/wallets";
import { isDevelopment } from "./env";

const { parseEther } = ethers.utils;

export const ETH_MAINNET = 1;
export const LOCALHOST = 31337;
export const MUMBAI = 80001;
export const CFX_TESTNET = 71;
export const CFX_MAINNET = 1030;

// TODO take it from web3
export const DEFAULT_CHAIN_ID = CFX_TESTNET;
export const CHAIN_ID = DEFAULT_CHAIN_ID;

export const SUPPORTED_CHAIN_IDS = [CFX_TESTNET, CFX_MAINNET];

if (isDevelopment()) {
  SUPPORTED_CHAIN_IDS.push(LOCALHOST);
}

export const IS_NETWORK_DISABLED = {};

export const CHAIN_NAMES_MAP = {
  [LOCALHOST]: "LOCALHOST",
  [MUMBAI]: "MUMBAI",
  [CFX_TESTNET]: "Conflux eSpace(Testnet)",
  [CFX_MAINNET]: "Conflux eSpace",
};

export const GAS_PRICE_ADJUSTMENT_MAP = {
  [MUMBAI]: "0",
  [CFX_TESTNET]: "0",
  [CFX_MAINNET]: "0",
};

export const MAX_GAS_PRICE_MAP = {
  // [MUMBAI]: "200000000000", // 200 gwei
  // [CFX_TESTNET]: "200000000000",
};

export const HIGH_EXECUTION_FEES_MAP = {
  [MUMBAI]: 3, // 3 USD
  [CFX_TESTNET]: 3,
  [CFX_MAINNET]: 3,
};

const constants = {
  [LOCALHOST]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "WETH",
    defaultCollateralSymbol: "WETH",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: false,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
  },
  [MUMBAI]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "WETH",
    defaultCollateralSymbol: "WETH",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: false,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
  },
  [CFX_TESTNET]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "CFX",
    defaultCollateralSymbol: "USDT",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: false,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.03"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.03"),
  },
  [CFX_MAINNET]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "CFX",
    defaultCollateralSymbol: "USDT",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: false,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.03"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.03"),
  },
};

const ALCHEMY_WHITELISTED_DOMAINS = ["mold.trade", "app.mold.trade"];

export const RPC_PROVIDERS = {
  [ETH_MAINNET]: ["https://rpc.ankr.com/eth"],
  [LOCALHOST]: ["http://10.206.48.200:8545/"],
  [MUMBAI]: ["https://rpc.ankr.com/polygon_mumbai"],
  [CFX_TESTNET]: ["https://evmtestnet.confluxrpc.com"],
  [CFX_MAINNET]: ["https://evm.confluxrpc.com"],
};

export const FALLBACK_PROVIDERS = {
  [MUMBAI]: ["https://polygon-mumbai.g.alchemy.com/v2/R-JVRM38kKZdaw9EP9lQkwmU6WgZeTmL"],
  [CFX_TESTNET]: ["https://evmtestnet.confluxrpc.com"],
  [CFX_MAINNET]: ["https://evm.confluxrpc.com"],
};

export const NETWORK_METADATA: { [chainId: number]: NetworkMetadata } = {
  [LOCALHOST]: {
    chainId: "0x" + LOCALHOST.toString(16),
    chainName: "LOCALHOST",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[LOCALHOST],
    blockExplorerUrls: ["https://testnet.bscscan.com/"],
  },
  [MUMBAI]: {
    chainId: "0x" + MUMBAI.toString(16),
    chainName: "MUMBAI",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[MUMBAI],
    blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
  },
  [CFX_TESTNET]: {
    chainId: "0x" + CFX_TESTNET.toString(16),
    chainName: "CFX_TESTNET",
    nativeCurrency: {
      name: "CFX",
      symbol: "CFX",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[CFX_TESTNET],
    blockExplorerUrls: ["https://evmtestnet.confluxscan.net/"],
  },
  [CFX_MAINNET]: {
    chainId: "0x" + CFX_MAINNET.toString(16),
    chainName: "CFX_MAINNET",
    nativeCurrency: {
      name: "CFX",
      symbol: "CFX",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[CFX_MAINNET],
    blockExplorerUrls: ["https://evm.confluxscan.net/"],
  },
};

export const getConstant = (chainId: number, key: string) => {
  if (!constants[chainId]) {
    throw new Error(`Unsupported chainId ${chainId}`);
  }

  if (!(key in constants[chainId])) {
    throw new Error(`Key ${key} does not exist for chainId ${chainId}`);
  }

  return constants[chainId][key];
};

export function getChainName(chainId: number) {
  return CHAIN_NAMES_MAP[chainId];
}

export function getDefaultArbitrumRpcUrl() {
  return "";
}

export function getRpcUrl(chainId: number): string | undefined {
  return sample(RPC_PROVIDERS[chainId]);
}

export function getFallbackRpcUrl(chainId: number): string | undefined {
  return sample(FALLBACK_PROVIDERS[chainId]);
}

export function getAlchemyHttpUrl() {
  if (ALCHEMY_WHITELISTED_DOMAINS.includes(window.location.host)) {
    return "";
  }
  return "";
}

export function getAlchemyWsUrl() {
  if (ALCHEMY_WHITELISTED_DOMAINS.includes(window.location.host)) {
    return "";
  }
  return "";
}

export function getExplorerUrl(chainId) {
  if (chainId === 3) {
    return "https://ropsten.etherscan.io/";
  } else if (chainId === 42) {
    return "https://kovan.etherscan.io/";
  } else if (chainId === MUMBAI) {
    return "https://mumbai.polygonscan.com/";
  } else if (chainId === CFX_TESTNET) {
    return "https://evmtestnet.confluxscan.net/";
  } else if (chainId === CFX_MAINNET) {
    return "https://evm.confluxscan.net/";
  }
  return "https://etherscan.io/";
}

export function getHighExecutionFee(chainId) {
  return HIGH_EXECUTION_FEES_MAP[chainId] || 3;
}

export function isSupportedChain(chainId) {
  return SUPPORTED_CHAIN_IDS.includes(chainId);
}
