import "./RedirectModal.css";
import styled from "styled-components";
import Modal from "../Modal/Modal";
import Checkbox from "../Checkbox/Checkbox";
import { t, Trans } from "@lingui/macro";
import ExternalLink from "components/ExternalLink/ExternalLink";
import Coming from "img/coming.png";
import Twitter from "img/twitter1.png";

export function RedirectPopupModal({
  redirectModalVisible,
  setRedirectModalVisible,
  appRedirectUrl,
  setRedirectPopupTimestamp,
  setShouldHideRedirectModal,
  shouldHideRedirectModal,
}) {
  const onClickAgree = () => {
    if (shouldHideRedirectModal) {
      setRedirectPopupTimestamp(Date.now());
    }
  };

  return (
    <Modal
      className="RedirectModal"
      isVisible={redirectModalVisible}
      setIsVisible={setRedirectModalVisible}
      label={t`Launch App`}
    >
      <Trans>You are leaving MOLD.trade and will be redirected to a third party, independent website.</Trans>
      <br />
      <br />
      <Trans>
        Alternative links can be found in the <ExternalLink href="https://docs.mold.trade/">docs</ExternalLink>.
        <br />
        <br />
      </Trans>
      <div className="mb-sm">
        <Checkbox isChecked={shouldHideRedirectModal} setIsChecked={setShouldHideRedirectModal}>
          <Trans>Don't show this message again for 30 days.</Trans>
        </Checkbox>
      </div>
      <a href={appRedirectUrl} className="App-cta Exchange-swap-button" onClick={onClickAgree}>
        <Trans>Agree</Trans>
      </a>
    </Modal>
  );
}

const StyledContent = styled.div`
  position: relative;
  background: url(${Coming});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 2rem;
  height: 100%;
`;

const StyledTwitter = styled.a`
  position: absolute;
  left: 50%;
  bottom: 2rem;
  transform: translateX(-50%);
  img {
    width: 4rem;
    opacity: 0.9;
  }
`;
