import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { Trans, t } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import styled from "styled-components";

import Modal from "components/Modal/Modal";
import Checkbox from "components/Checkbox/Checkbox";
import Tooltip from "components/Tooltip/Tooltip";
import Footer from "components/Footer/Footer";

import Vault from "abis/Vault.json";
import ReaderV2 from "abis/ReaderV2.json";
import Vester from "abis/Vester.json";
import RewardRouter from "abis/RewardRouter.json";
import RewardReader from "abis/RewardReader.json";
import Token from "abis/Token.json";
import MlpManager from "abis/MlpManager.json";

import Earn from "img/earn.png";
import EarnBg from "img/earn_bg.png";

import { ethers } from "ethers";
import {
  MLP_DECIMALS,
  USD_DECIMALS,
  BASIS_POINTS_DIVISOR,
  PLACEHOLDER_ACCOUNT,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getVestingData,
  getStakingData,
  getProcessedData,
  getPageTitle,
} from "lib/legacy";
import { useMldsPrice, useTotalMoldStaked, useTotalMoldSupply } from "domain/legacy";
import { CFX_TESTNET, getChainName, getConstant } from "config/chains";

import useSWR from "swr";

import { getContract } from "config/contracts";

import "./StakeV2.css";
import SEO from "components/Common/SEO";
import StatsTooltip from "components/StatsTooltip/StatsTooltip";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { getServerUrl } from "config/backend";
import { callContract, contractFetcher } from "lib/contracts";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { helperToast } from "lib/helperToast";
import { approveTokens } from "domain/tokens";
import { bigNumberify, expandDecimals, formatAmount, formatAmountFree, formatKeyAmount, parseValue } from "lib/numbers";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";
import MOLDAprTooltip from "components/Stake/MOLDAprTooltip";

const { AddressZero } = ethers.constants;

function StakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    active,
    account,
    library,
    stakingTokenSymbol,
    stakingTokenAddress,
    farmAddress,
    rewardRouterAddress,
    stakeMethodName,
    setPendingTxns,
  } = props;
  const [isStaking, setIsStaking] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  const { data: tokenAllowance } = useSWR(
    active && stakingTokenAddress && [active, chainId, stakingTokenAddress, "allowance", account, farmAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  let amount = parseValue(value, 18);
  const needApproval = farmAddress !== AddressZero && tokenAllowance && amount && amount.gt(tokenAllowance);

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return t`Enter an amount`;
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return t`Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: stakingTokenAddress,
        spender: farmAddress,
        chainId,
      });
      return;
    }

    setIsStaking(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());

    callContract(chainId, contract, stakeMethodName, [amount], {
      sentMsg: t`Stake submitted!`,
      failMsg: t`Stake failed.`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsStaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isStaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isApproving) {
      return t`Approving ${stakingTokenSymbol}...`;
    }
    if (needApproval) {
      return t`Approve ${stakingTokenSymbol}`;
    }
    if (isStaking) {
      return t`Staking...`;
    }
    return t`Stake`;
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <div className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">
                <Trans>Stake</Trans>
              </div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              <Trans>Max: {formatAmount(maxAmount, 18, 4, true)}</Trans>
            </div>
          </div>
          <div className="Exchange-swap-section-bottom">
            <div>
              <input
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <div className="PositionEditor-token-symbol">{stakingTokenSymbol}</div>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function UnstakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    library,
    unstakingTokenSymbol,
    rewardRouterAddress,
    unstakeMethodName,
    multiplierPointsAmount,
    reservedAmount,
    bonusMoldInFeeMold,
    setPendingTxns,
  } = props;
  const [isUnstaking, setIsUnstaking] = useState(false);

  let amount = parseValue(value, 18);
  let burnAmount;

  if (
    multiplierPointsAmount &&
    multiplierPointsAmount.gt(0) &&
    amount &&
    amount.gt(0) &&
    bonusMoldInFeeMold &&
    bonusMoldInFeeMold.gt(0)
  ) {
    burnAmount = multiplierPointsAmount.mul(amount).div(bonusMoldInFeeMold);
  }

  const shouldShowReductionAmount = true;
  let rewardReductionBasisPoints;
  if (burnAmount && bonusMoldInFeeMold) {
    rewardReductionBasisPoints = burnAmount.mul(BASIS_POINTS_DIVISOR).div(bonusMoldInFeeMold);
  }

  const getError = () => {
    if (!amount) {
      return t`Enter an amount`;
    }
    if (amount.gt(maxAmount)) {
      return t`Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    setIsUnstaking(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    callContract(chainId, contract, unstakeMethodName, [amount], {
      sentMsg: t`Unstake submitted!`,
      failMsg: t`Unstake failed.`,
      successMsg: t`Unstake completed!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsUnstaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isUnstaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isUnstaking) {
      return t`Unstaking...`;
    }
    return t`Unstake`;
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <div className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">
                <Trans>Unstake</Trans>
              </div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              <Trans>Max: {formatAmount(maxAmount, 18, 4, true)}</Trans>
            </div>
          </div>
          <div className="Exchange-swap-section-bottom">
            <div>
              <input
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <div className="PositionEditor-token-symbol">{unstakingTokenSymbol}</div>
          </div>
        </div>
        {reservedAmount && reservedAmount.gt(0) && (
          <div className="Modal-note">
            You have {formatAmount(reservedAmount, 18, 2, true)} tokens reserved for vesting.
          </div>
        )}
        {burnAmount && burnAmount.gt(0) && rewardReductionBasisPoints && rewardReductionBasisPoints.gt(0) && (
          <div className="Modal-note">
            <Trans>
              Unstaking will burn&nbsp;
              <ExternalLink href="https://docs.mold.trade/">
                {formatAmount(burnAmount, 18, 4, true)} Multiplier Points
              </ExternalLink>
              .&nbsp;
              {shouldShowReductionAmount && (
                <span>Boost Percentage: -{formatAmount(rewardReductionBasisPoints, 2, 2)}%.</span>
              )}
            </Trans>
          </div>
        )}
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function VesterDepositModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    balance,
    vestedAmount,
    averageStakedAmount,
    maxVestableAmount,
    library,
    stakeTokenLabel,
    reserveAmount,
    maxReserveAmount,
    vesterAddress,
    setPendingTxns,
  } = props;
  const [isDepositing, setIsDepositing] = useState(false);

  let amount = parseValue(value, 18);

  let nextReserveAmount = reserveAmount;

  let nextDepositAmount = vestedAmount;
  if (amount) {
    nextDepositAmount = vestedAmount.add(amount);
  }

  let additionalReserveAmount = bigNumberify(0);
  if (amount && averageStakedAmount && maxVestableAmount && maxVestableAmount.gt(0)) {
    nextReserveAmount = nextDepositAmount.mul(averageStakedAmount).div(maxVestableAmount);
    if (nextReserveAmount.gt(reserveAmount)) {
      additionalReserveAmount = nextReserveAmount.sub(reserveAmount);
    }
  }

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return t`Enter an amount`;
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return t`Max amount exceeded`;
    }
    if (nextReserveAmount.gt(maxReserveAmount)) {
      return t`Insufficient staked tokens`;
    }
  };

  const onClickPrimary = () => {
    setIsDepositing(true);
    const contract = new ethers.Contract(vesterAddress, Vester.abi, library.getSigner());

    callContract(chainId, contract, "deposit", [amount], {
      sentMsg: t`Deposit submitted!`,
      failMsg: t`Deposit failed!`,
      successMsg: t`Deposited!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsDepositing(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isDepositing) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isDepositing) {
      return t`Depositing...`;
    }
    return t`Deposit`;
  };

  return (
    <SEO title={getPageTitle("Earn")}>
      <div className="StakeModal">
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title} className="non-scrollable">
          <div className="Exchange-swap-section">
            <div className="Exchange-swap-section-top">
              <div className="muted">
                <div className="Exchange-swap-usd">
                  <Trans>Deposit</Trans>
                </div>
              </div>
              <div
                className="muted align-right clickable"
                onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}
              >
                <Trans>Max: {formatAmount(maxAmount, 18, 4, true)}</Trans>
              </div>
            </div>
            <div className="Exchange-swap-section-bottom">
              <div>
                <input
                  type="number"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
              <div className="PositionEditor-token-symbol">esMOLD</div>
            </div>
          </div>
          <div className="VesterDepositModal-info-rows">
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>Wallet</Trans>
              </div>
              <div className="align-right">{formatAmount(balance, 18, 2, true)} esMOLD</div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>Vault Capacity</Trans>
              </div>
              <div className="align-right">
                <Tooltip
                  handle={`${formatAmount(nextDepositAmount, 18, 2, true)} / ${formatAmount(
                    maxVestableAmount,
                    18,
                    2,
                    true
                  )}`}
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <div>
                        <p className="text-white">
                          <Trans>Vault Capacity for your Account:</Trans>
                        </p>
                        <StatsTooltipRow
                          showDollar={false}
                          label={t`Deposited`}
                          value={`${formatAmount(vestedAmount, 18, 2, true)} esMOLD`}
                        />
                        <StatsTooltipRow
                          showDollar={false}
                          label={t`Max Capacity`}
                          value={`${formatAmount(maxVestableAmount, 18, 2, true)} esMOLD`}
                        />
                      </div>
                    );
                  }}
                />
              </div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>Reserve Amount</Trans>
              </div>
              <div className="align-right">
                <Tooltip
                  handle={`${formatAmount(
                    reserveAmount && reserveAmount.gte(additionalReserveAmount)
                      ? reserveAmount
                      : additionalReserveAmount,
                    18,
                    2,
                    true
                  )} / ${formatAmount(maxReserveAmount, 18, 2, true)}`}
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <>
                        <StatsTooltipRow
                          label={t`Current Reserved`}
                          value={formatAmount(reserveAmount, 18, 2, true)}
                          showDollar={false}
                        />
                        <StatsTooltipRow
                          label={t`Additional reserve required`}
                          value={formatAmount(additionalReserveAmount, 18, 2, true)}
                          showDollar={false}
                        />
                        {amount && nextReserveAmount.gt(maxReserveAmount) && (
                          <>
                            <br />
                            <Trans>
                              You need a total of at least {formatAmount(nextReserveAmount, 18, 2, true)}{" "}
                              {stakeTokenLabel} to vest {formatAmount(amount, 18, 2, true)} esMOLD.
                            </Trans>
                          </>
                        )}
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="Exchange-swap-button-container">
            <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
              {getPrimaryText()}
            </button>
          </div>
        </Modal>
      </div>
    </SEO>
  );
}

function VesterWithdrawModal(props) {
  const { isVisible, setIsVisible, chainId, title, library, vesterAddress, setPendingTxns } = props;
  const [isWithdrawing, setIsWithdrawing] = useState(false);

  const onClickPrimary = () => {
    setIsWithdrawing(true);
    const contract = new ethers.Contract(vesterAddress, Vester.abi, library.getSigner());

    callContract(chainId, contract, "withdraw", [], {
      sentMsg: t`Withdraw submitted.`,
      failMsg: t`Withdraw failed.`,
      successMsg: t`Withdrawn!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsWithdrawing(false);
      });
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <Trans>
          <div>
            This will withdraw and unreserve all tokens as well as pause vesting.
            <br />
            <br />
            esMOLD tokens that have been converted to MOLD will remain as MOLD tokens.
            <br />
            <br />
            To claim MOLD tokens without withdrawing, use the "Claim" button under the Total Rewards section.
            <br />
            <br />
          </div>
        </Trans>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={isWithdrawing}>
            {!isWithdrawing && "Confirm Withdraw"}
            {isWithdrawing && "Confirming..."}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function CompoundModal(props) {
  const {
    isVisible,
    setIsVisible,
    rewardRouterAddress,
    active,
    account,
    library,
    chainId,
    setPendingTxns,
    totalVesterRewards,
    nativeTokenSymbol,
    wrappedTokenSymbol,
  } = props;
  const [isCompounding, setIsCompounding] = useState(false);
  const [shouldClaimMold, setShouldClaimMold] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-mold"],
    true
  );
  const [shouldStakeMold, setShouldStakeMold] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-stake-mold"],
    true
  );
  const [shouldClaimEsMold, setShouldClaimEsMold] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-es-mold"],
    true
  );
  const [shouldStakeEsMold, setShouldStakeEsMold] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-stake-es-mold"],
    true
  );
  const [shouldStakeMultiplierPoints, setShouldStakeMultiplierPoints] = useState(true);
  const [shouldClaimWeth, setShouldClaimWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-weth"],
    true
  );
  const [shouldConvertWeth, setShouldConvertWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-convert-weth"],
    true
  );

  const moldAddress = getContract(chainId, "MOLD");
  const stakedMoldTrackerAddress = getContract(chainId, "StakedMoldTracker");

  const [isApproving, setIsApproving] = useState(false);

  const { data: tokenAllowance } = useSWR(
    active && [active, chainId, moldAddress, "allowance", account, stakedMoldTrackerAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const needApproval = shouldStakeMold && tokenAllowance && totalVesterRewards && totalVesterRewards.gt(tokenAllowance);

  const isPrimaryEnabled = () => {
    return !isCompounding && !isApproving && !isCompounding;
  };

  const getPrimaryText = () => {
    if (isApproving) {
      return t`Approving MOLD...`;
    }
    if (needApproval) {
      return t`Approve MOLD`;
    }
    if (isCompounding) {
      return t`Compounding...`;
    }
    return t`Compound`;
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: moldAddress,
        spender: stakedMoldTrackerAddress,
        chainId,
      });
      return;
    }

    setIsCompounding(true);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    callContract(
      chainId,
      contract,
      "handleRewards",
      [
        shouldClaimMold || shouldStakeMold,
        shouldStakeMold,
        shouldClaimEsMold || shouldStakeEsMold,
        shouldStakeEsMold,
        shouldStakeMultiplierPoints,
        shouldClaimWeth || shouldConvertWeth,
        shouldConvertWeth,
      ],
      {
        sentMsg: t`Compound submitted!`,
        failMsg: t`Compound failed.`,
        successMsg: t`Compound completed!`,
        setPendingTxns,
      }
    )
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsCompounding(false);
      });
  };

  const toggleShouldStakeMold = (value) => {
    if (value) {
      setShouldClaimMold(true);
    }
    setShouldStakeMold(value);
  };

  const toggleShouldStakeEsMold = (value) => {
    if (value) {
      setShouldClaimEsMold(true);
    }
    setShouldStakeEsMold(value);
  };

  const toggleConvertWeth = (value) => {
    if (value) {
      setShouldClaimWeth(true);
    }
    setShouldConvertWeth(value);
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={t`Compound Rewards`}>
        <div className="CompoundModal-menu">
          <div>
            <Checkbox
              isChecked={shouldStakeMultiplierPoints}
              setIsChecked={setShouldStakeMultiplierPoints}
              disabled={true}
            >
              <Trans>Stake Multiplier Points</Trans>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimMold} setIsChecked={setShouldClaimMold} disabled={shouldStakeMold}>
              <Trans>Claim MOLD Rewards</Trans>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldStakeMold} setIsChecked={toggleShouldStakeMold}>
              <Trans>Stake MOLD Rewards</Trans>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimEsMold} setIsChecked={setShouldClaimEsMold} disabled={shouldStakeEsMold}>
              <Trans>Claim esMOLD Rewards</Trans>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldStakeEsMold} setIsChecked={toggleShouldStakeEsMold}>
              <Trans>Stake esMOLD Rewards</Trans>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimWeth} setIsChecked={setShouldClaimWeth} disabled={shouldConvertWeth}>
              <Trans>Claim {wrappedTokenSymbol} Rewards</Trans>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldConvertWeth} setIsChecked={toggleConvertWeth}>
              <Trans>
                Convert {wrappedTokenSymbol} to {nativeTokenSymbol}
              </Trans>
            </Checkbox>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function ClaimModal(props) {
  const {
    isVisible,
    setIsVisible,
    rewardRouterAddress,
    library,
    chainId,
    setPendingTxns,
    nativeTokenSymbol,
    wrappedTokenSymbol,
  } = props;
  const [isClaiming, setIsClaiming] = useState(false);
  const [shouldClaimMold, setShouldClaimMold] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-mold"],
    true
  );
  const [shouldClaimEsMold, setShouldClaimEsMold] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-es-mold"],
    true
  );
  const [shouldClaimWeth, setShouldClaimWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-weth"],
    true
  );
  const [shouldConvertWeth, setShouldConvertWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-convert-weth"],
    true
  );

  const isPrimaryEnabled = () => {
    return !isClaiming;
  };

  const getPrimaryText = () => {
    if (isClaiming) {
      return t`Claiming...`;
    }
    return t`Claim`;
  };

  const onClickPrimary = () => {
    setIsClaiming(true);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    callContract(
      chainId,
      contract,
      "handleRewards",
      [
        shouldClaimMold,
        false, // shouldStakeMold
        shouldClaimEsMold,
        false, // shouldStakeEsMold
        false, // shouldStakeMultiplierPoints
        shouldClaimWeth,
        shouldConvertWeth,
      ],
      {
        sentMsg: t`Claim submitted.`,
        failMsg: t`Claim failed.`,
        successMsg: t`Claim completed!`,
        setPendingTxns,
      }
    )
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsClaiming(false);
      });
  };

  const toggleConvertWeth = (value) => {
    if (value) {
      setShouldClaimWeth(true);
    }
    setShouldConvertWeth(value);
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={t`Claim Rewards`}>
        <div className="CompoundModal-menu">
          <div>
            <Checkbox isChecked={shouldClaimMold} setIsChecked={setShouldClaimMold}>
              <Trans>Claim MOLD Rewards</Trans>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimEsMold} setIsChecked={setShouldClaimEsMold}>
              <Trans>Claim esMOLD Rewards</Trans>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimWeth} setIsChecked={setShouldClaimWeth} disabled={shouldConvertWeth}>
              <Trans>Claim {wrappedTokenSymbol} Rewards</Trans>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldConvertWeth} setIsChecked={toggleConvertWeth}>
              <Trans>
                Convert {wrappedTokenSymbol} to {nativeTokenSymbol}
              </Trans>
            </Checkbox>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default function StakeV2({ setPendingTxns, connectWallet }) {
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();

  const chainName = getChainName(chainId);

  const hasInsurance = true;

  const [isStakeModalVisible, setIsStakeModalVisible] = useState(false);
  const [stakeModalTitle, setStakeModalTitle] = useState("");
  const [stakeModalMaxAmount, setStakeModalMaxAmount] = useState(undefined);
  const [stakeValue, setStakeValue] = useState("");
  const [stakingTokenSymbol, setStakingTokenSymbol] = useState("");
  const [stakingTokenAddress, setStakingTokenAddress] = useState("");
  const [stakingFarmAddress, setStakingFarmAddress] = useState("");
  const [stakeMethodName, setStakeMethodName] = useState("");

  const [isUnstakeModalVisible, setIsUnstakeModalVisible] = useState(false);
  const [unstakeModalTitle, setUnstakeModalTitle] = useState("");
  const [unstakeModalMaxAmount, setUnstakeModalMaxAmount] = useState(undefined);
  const [unstakeModalReservedAmount, setUnstakeModalReservedAmount] = useState(undefined);
  const [unstakeValue, setUnstakeValue] = useState("");
  const [unstakingTokenSymbol, setUnstakingTokenSymbol] = useState("");
  const [unstakeMethodName, setUnstakeMethodName] = useState("");

  const [isVesterDepositModalVisible, setIsVesterDepositModalVisible] = useState(false);
  const [vesterDepositTitle, setVesterDepositTitle] = useState("");
  const [vesterDepositStakeTokenLabel, setVesterDepositStakeTokenLabel] = useState("");
  const [vesterDepositMaxAmount, setVesterDepositMaxAmount] = useState("");
  const [vesterDepositBalance, setVesterDepositBalance] = useState("");
  const [vesterDepositEscrowedBalance, setVesterDepositEscrowedBalance] = useState("");
  const [vesterDepositVestedAmount, setVesterDepositVestedAmount] = useState("");
  const [vesterDepositAverageStakedAmount, setVesterDepositAverageStakedAmount] = useState("");
  const [vesterDepositMaxVestableAmount, setVesterDepositMaxVestableAmount] = useState("");
  const [vesterDepositValue, setVesterDepositValue] = useState("");
  const [vesterDepositReserveAmount, setVesterDepositReserveAmount] = useState("");
  const [vesterDepositMaxReserveAmount, setVesterDepositMaxReserveAmount] = useState("");
  const [vesterDepositAddress, setVesterDepositAddress] = useState("");

  const [isVesterWithdrawModalVisible, setIsVesterWithdrawModalVisible] = useState(false);
  const [vesterWithdrawTitle, setVesterWithdrawTitle] = useState(false);
  const [vesterWithdrawAddress, setVesterWithdrawAddress] = useState("");

  const [isCompoundModalVisible, setIsCompoundModalVisible] = useState(false);
  const [isClaimModalVisible, setIsClaimModalVisible] = useState(false);

  const rewardRouterAddress = getContract(chainId, "RewardRouter");
  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const vaultAddress = getContract(chainId, "Vault");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const moldAddress = getContract(chainId, "MOLD");
  const esMoldAddress = getContract(chainId, "ES_MOLD");
  const bnMoldAddress = getContract(chainId, "BN_MOLD");
  const mlpAddress = getContract(chainId, "MLP");

  const stakedMoldTrackerAddress = getContract(chainId, "StakedMoldTracker");
  const bonusMoldTrackerAddress = getContract(chainId, "BonusMoldTracker");
  const feeMoldTrackerAddress = getContract(chainId, "FeeMoldTracker");

  const stakedMlpTrackerAddress = getContract(chainId, "StakedMlpTracker");
  const feeMlpTrackerAddress = getContract(chainId, "FeeMlpTracker");

  const mlpManagerAddress = getContract(chainId, "MlpManager");

  const stakedMoldDistributorAddress = getContract(chainId, "StakedMoldDistributor");
  const stakedMlpDistributorAddress = getContract(chainId, "StakedMlpDistributor");

  const moldVesterAddress = getContract(chainId, "MoldVester");
  const mlpVesterAddress = getContract(chainId, "MlpVester");

  const vesterAddresses = [moldVesterAddress, mlpVesterAddress];

  const excludedEsMoldAccounts = [stakedMoldDistributorAddress, stakedMlpDistributorAddress];

  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const wrappedTokenSymbol = getConstant(chainId, "wrappedTokenSymbol");

  const walletTokens = [moldAddress, esMoldAddress, mlpAddress, stakedMoldTrackerAddress];
  const depositTokens = [
    moldAddress,
    esMoldAddress,
    stakedMoldTrackerAddress,
    bonusMoldTrackerAddress,
    bnMoldAddress,
    mlpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedMoldTrackerAddress,
    stakedMoldTrackerAddress,
    bonusMoldTrackerAddress,
    feeMoldTrackerAddress,
    feeMoldTrackerAddress,
    feeMlpTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedMoldTrackerAddress,
    bonusMoldTrackerAddress,
    feeMoldTrackerAddress,
    stakedMlpTrackerAddress,
    feeMlpTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [
      `StakeV2:walletBalances:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [walletTokens]),
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `StakeV2:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [`StakeV2:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
    }
  );

  const { data: stakedMoldSupply } = useSWR(
    [`StakeV2:stakedMoldSupply:${active}`, chainId, moldAddress, "balanceOf", stakedMoldTrackerAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const { data: aums } = useSWR([`StakeV2:getAums:${active}`, chainId, mlpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, MlpManager),
  });

  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(library, Vault),
    }
  );

  const { data: esMoldSupply } = useSWR(
    [`StakeV2:esMoldSupply:${active}`, chainId, readerAddress, "getTokenSupply", esMoldAddress],
    {
      fetcher: contractFetcher(library, ReaderV2, [excludedEsMoldAccounts]),
    }
  );

  const { data: vestingInfo } = useSWR(
    [`StakeV2:vestingInfo:${active}`, chainId, readerAddress, "getVestingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [vesterAddresses]),
    }
  );

  const { mldsPrice, mldsPriceFromArbitrum, mldsPriceFromAvalanche } = useMldsPrice(
    chainId,
    { arbitrum: chainId === CFX_TESTNET ? library : undefined },
    active
  );

  let { total: totalMoldSupply } = useTotalMoldSupply();

  let { avax: avaxMoldStaked, arbitrum: arbitrumMoldStaked, total: totalMoldStaked } = useTotalMoldStaked();

  const moldSupplyUrl = getServerUrl(chainId, "/mold_supply");
  const { data: moldSupply } = useSWR([moldSupplyUrl], {
    fetcher: (...args) => fetch(...args).then((res) => res.text()),
  });

  const isMoldTransferEnabled = true;

  let esMoldSupplyUsd;
  if (esMoldSupply && mldsPrice) {
    esMoldSupplyUsd = esMoldSupply.mul(mldsPrice).div(expandDecimals(1, 18));
  }

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedMoldSupply,
    mldsPrice,
    moldSupply
  );

  let hasMultiplierPoints = false;
  let multiplierPointsAmount;
  if (processedData && processedData.bonusMoldTrackerRewards && processedData.bnMoldInFeeMold) {
    multiplierPointsAmount = processedData.bonusMoldTrackerRewards.add(processedData.bnMoldInFeeMold);
    if (multiplierPointsAmount.gt(0)) {
      hasMultiplierPoints = true;
    }
  }
  let totalRewardTokens;
  if (processedData && processedData.bnMoldInFeeMold && processedData.bonusMoldInFeeMold) {
    totalRewardTokens = processedData.bnMoldInFeeMold.add(processedData.bonusMoldInFeeMold);
  }

  let totalRewardTokensAndMlp;
  if (totalRewardTokens && processedData && processedData.mlpBalance) {
    totalRewardTokensAndMlp = totalRewardTokens.add(processedData.mlpBalance);
  }

  const bonusMoldInFeeMold = processedData ? processedData.bonusMoldInFeeMold : undefined;

  let stakedMoldSupplyUsd;
  if (!totalMoldStaked.isZero() && mldsPrice) {
    stakedMoldSupplyUsd = totalMoldStaked.mul(mldsPrice).div(expandDecimals(1, 18));
  }

  let totalSupplyUsd;
  if (totalMoldSupply && !totalMoldSupply.isZero() && mldsPrice) {
    totalSupplyUsd = totalMoldSupply.mul(mldsPrice).div(expandDecimals(1, 18));
  }

  let maxUnstakeableMold = bigNumberify(0);
  if (
    totalRewardTokens &&
    vestingData &&
    vestingData.moldVesterPairAmount &&
    multiplierPointsAmount &&
    processedData.bonusMoldInFeeMold
  ) {
    const availableTokens = totalRewardTokens.sub(vestingData.moldVesterPairAmount);
    const stakedTokens = processedData.bonusMoldInFeeMold;
    const divisor = multiplierPointsAmount.add(stakedTokens);
    if (divisor.gt(0)) {
      maxUnstakeableMold = availableTokens.mul(stakedTokens).div(divisor);
    }
  }

  const showStakeMoldModal = () => {
    if (!isMoldTransferEnabled) {
      helperToast.error(t`MOLD transfers not yet enabled`);
      return;
    }

    setIsStakeModalVisible(true);
    setStakeModalTitle(t`Stake MOLD`);
    setStakeModalMaxAmount(processedData.moldBalance);
    setStakeValue("");
    setStakingTokenSymbol("MOLD");
    setStakingTokenAddress(moldAddress);
    setStakingFarmAddress(stakedMoldTrackerAddress);
    setStakeMethodName("stakeMold");
  };

  const showStakeEsMoldModal = () => {
    setIsStakeModalVisible(true);
    setStakeModalTitle(t`Stake esMOLD`);
    setStakeModalMaxAmount(processedData.esMoldBalance);
    setStakeValue("");
    setStakingTokenSymbol("esMOLD");
    setStakingTokenAddress(esMoldAddress);
    setStakingFarmAddress(AddressZero);
    setStakeMethodName("stakeEsMold");
  };

  const showMoldVesterDepositModal = () => {
    let remainingVestableAmount = vestingData.moldVester.maxVestableAmount.sub(vestingData.moldVester.vestedAmount);
    if (processedData.esMoldBalance.lt(remainingVestableAmount)) {
      remainingVestableAmount = processedData.esMoldBalance;
    }

    setIsVesterDepositModalVisible(true);
    setVesterDepositTitle(t`MOLD Vault`);
    setVesterDepositStakeTokenLabel("staked MOLD + esMOLD + Multiplier Points");
    setVesterDepositMaxAmount(remainingVestableAmount);
    setVesterDepositBalance(processedData.esMoldBalance);
    setVesterDepositEscrowedBalance(vestingData.moldVester.escrowedBalance);
    setVesterDepositVestedAmount(vestingData.moldVester.vestedAmount);
    setVesterDepositMaxVestableAmount(vestingData.moldVester.maxVestableAmount);
    setVesterDepositAverageStakedAmount(vestingData.moldVester.averageStakedAmount);
    setVesterDepositReserveAmount(vestingData.moldVester.pairAmount);
    setVesterDepositMaxReserveAmount(totalRewardTokens);
    setVesterDepositValue("");
    setVesterDepositAddress(moldVesterAddress);
  };

  const showMlpVesterDepositModal = () => {
    let remainingVestableAmount = vestingData.mlpVester.maxVestableAmount.sub(vestingData.mlpVester.vestedAmount);
    if (processedData.esMoldBalance.lt(remainingVestableAmount)) {
      remainingVestableAmount = processedData.esMoldBalance;
    }

    setIsVesterDepositModalVisible(true);
    setVesterDepositTitle(t`MLP Vault`);
    setVesterDepositStakeTokenLabel("staked MLP");
    setVesterDepositMaxAmount(remainingVestableAmount);
    setVesterDepositBalance(processedData.esMoldBalance);
    setVesterDepositEscrowedBalance(vestingData.mlpVester.escrowedBalance);
    setVesterDepositVestedAmount(vestingData.mlpVester.vestedAmount);
    setVesterDepositMaxVestableAmount(vestingData.mlpVester.maxVestableAmount);
    setVesterDepositAverageStakedAmount(vestingData.mlpVester.averageStakedAmount);
    setVesterDepositReserveAmount(vestingData.mlpVester.pairAmount);
    setVesterDepositMaxReserveAmount(processedData.mlpBalance);
    setVesterDepositValue("");
    setVesterDepositAddress(mlpVesterAddress);
  };

  const showMoldVesterWithdrawModal = () => {
    if (!vestingData || !vestingData.moldVesterVestedAmount || vestingData.moldVesterVestedAmount.eq(0)) {
      helperToast.error(t`You have not deposited any tokens for vesting.`);
      return;
    }

    setIsVesterWithdrawModalVisible(true);
    setVesterWithdrawTitle(t`Withdraw from MOLD Vault`);
    setVesterWithdrawAddress(moldVesterAddress);
  };

  const showMlpVesterWithdrawModal = () => {
    if (!vestingData || !vestingData.mlpVesterVestedAmount || vestingData.mlpVesterVestedAmount.eq(0)) {
      helperToast.error(t`You have not deposited any tokens for vesting.`);
      return;
    }

    setIsVesterWithdrawModalVisible(true);
    setVesterWithdrawTitle(t`Withdraw from MLP Vault`);
    setVesterWithdrawAddress(mlpVesterAddress);
  };

  const showUnstakeMoldModal = () => {
    if (!isMoldTransferEnabled) {
      helperToast.error(t`MOLD transfers not yet enabled`);
      return;
    }
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle(t`Unstake MOLD`);
    let maxAmount = processedData.moldInStakedMold;
    if (
      processedData.moldInStakedMold &&
      vestingData &&
      vestingData.moldVesterPairAmount.gt(0) &&
      maxUnstakeableMold &&
      maxUnstakeableMold.lt(processedData.moldInStakedMold)
    ) {
      maxAmount = maxUnstakeableMold;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeModalReservedAmount(vestingData.moldVesterPairAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("MOLD");
    setUnstakeMethodName("unstakeMold");
  };

  const showUnstakeEsMoldModal = () => {
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle(t`Unstake esMOLD`);
    let maxAmount = processedData.esMoldInStakedMold;
    if (
      processedData.esMoldInStakedMold &&
      vestingData &&
      vestingData.moldVesterPairAmount.gt(0) &&
      maxUnstakeableMold &&
      maxUnstakeableMold.lt(processedData.esMoldInStakedMold)
    ) {
      maxAmount = maxUnstakeableMold;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeModalReservedAmount(vestingData.moldVesterPairAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("esMOLD");
    setUnstakeMethodName("unstakeEsMold");
  };

  const renderMultiplierPointsLabel = useCallback(() => {
    return t`Multiplier Points APR`;
  }, []);

  const renderMultiplierPointsValue = useCallback(() => {
    return (
      <Tooltip
        handle={`100.00%`}
        position="right-bottom"
        renderContent={() => {
          return (
            <Trans>
              Boost your rewards with Multiplier Points.&nbsp;
              <ExternalLink href="https://docs.mold.trade/">More info</ExternalLink>.
            </Trans>
          );
        }}
      />
    );
  }, []);

  let earnMsg;
  if (totalRewardTokensAndMlp && totalRewardTokensAndMlp.gt(0)) {
    let moldAmountStr;
    if (processedData.moldInStakedMold && processedData.moldInStakedMold.gt(0)) {
      moldAmountStr = formatAmount(processedData.moldInStakedMold, 18, 2, true) + " MOLD";
    }
    let esMoldAmountStr;
    if (processedData.esMoldInStakedMold && processedData.esMoldInStakedMold.gt(0)) {
      esMoldAmountStr = formatAmount(processedData.esMoldInStakedMold, 18, 2, true) + " esMOLD";
    }
    let mpAmountStr;
    if (processedData.bonusMoldInFeeMold && processedData.bnMoldInFeeMold.gt(0)) {
      mpAmountStr = formatAmount(processedData.bnMoldInFeeMold, 18, 2, true) + " MP";
    }
    let mlpStr;
    if (processedData.mlpBalance && processedData.mlpBalance.gt(0)) {
      mlpStr = formatAmount(processedData.mlpBalance, 18, 2, true) + " MLP";
    }
    const amountStr = [moldAmountStr, esMoldAmountStr, mpAmountStr, mlpStr].filter((s) => s).join(", ");
    earnMsg = (
      <div>
        <Trans>
          You are earning {nativeTokenSymbol} rewards with {formatAmount(totalRewardTokensAndMlp, 18, 2, true)} tokens.
          <br />
          Tokens: {amountStr}.
        </Trans>
      </div>
    );
  }

  return (
    <div className="default-container page-layout">
      <StakeModal
        isVisible={isStakeModalVisible}
        setIsVisible={setIsStakeModalVisible}
        chainId={chainId}
        title={stakeModalTitle}
        maxAmount={stakeModalMaxAmount}
        value={stakeValue}
        setValue={setStakeValue}
        active={active}
        account={account}
        library={library}
        stakingTokenSymbol={stakingTokenSymbol}
        stakingTokenAddress={stakingTokenAddress}
        farmAddress={stakingFarmAddress}
        rewardRouterAddress={rewardRouterAddress}
        stakeMethodName={stakeMethodName}
        hasMultiplierPoints={hasMultiplierPoints}
        setPendingTxns={setPendingTxns}
        nativeTokenSymbol={nativeTokenSymbol}
        wrappedTokenSymbol={wrappedTokenSymbol}
      />
      <UnstakeModal
        setPendingTxns={setPendingTxns}
        isVisible={isUnstakeModalVisible}
        setIsVisible={setIsUnstakeModalVisible}
        chainId={chainId}
        title={unstakeModalTitle}
        maxAmount={unstakeModalMaxAmount}
        reservedAmount={unstakeModalReservedAmount}
        value={unstakeValue}
        setValue={setUnstakeValue}
        library={library}
        unstakingTokenSymbol={unstakingTokenSymbol}
        rewardRouterAddress={rewardRouterAddress}
        unstakeMethodName={unstakeMethodName}
        multiplierPointsAmount={multiplierPointsAmount}
        bonusMoldInFeeMold={bonusMoldInFeeMold}
      />
      <VesterDepositModal
        isVisible={isVesterDepositModalVisible}
        setIsVisible={setIsVesterDepositModalVisible}
        chainId={chainId}
        title={vesterDepositTitle}
        stakeTokenLabel={vesterDepositStakeTokenLabel}
        maxAmount={vesterDepositMaxAmount}
        balance={vesterDepositBalance}
        escrowedBalance={vesterDepositEscrowedBalance}
        vestedAmount={vesterDepositVestedAmount}
        averageStakedAmount={vesterDepositAverageStakedAmount}
        maxVestableAmount={vesterDepositMaxVestableAmount}
        reserveAmount={vesterDepositReserveAmount}
        maxReserveAmount={vesterDepositMaxReserveAmount}
        value={vesterDepositValue}
        setValue={setVesterDepositValue}
        library={library}
        vesterAddress={vesterDepositAddress}
        setPendingTxns={setPendingTxns}
      />
      <VesterWithdrawModal
        isVisible={isVesterWithdrawModalVisible}
        setIsVisible={setIsVesterWithdrawModalVisible}
        vesterAddress={vesterWithdrawAddress}
        chainId={chainId}
        title={vesterWithdrawTitle}
        library={library}
        setPendingTxns={setPendingTxns}
      />
      <CompoundModal
        active={active}
        account={account}
        setPendingTxns={setPendingTxns}
        isVisible={isCompoundModalVisible}
        setIsVisible={setIsCompoundModalVisible}
        rewardRouterAddress={rewardRouterAddress}
        totalVesterRewards={processedData.totalVesterRewards}
        wrappedTokenSymbol={wrappedTokenSymbol}
        nativeTokenSymbol={nativeTokenSymbol}
        library={library}
        chainId={chainId}
      />
      <ClaimModal
        active={active}
        account={account}
        setPendingTxns={setPendingTxns}
        isVisible={isClaimModalVisible}
        setIsVisible={setIsClaimModalVisible}
        rewardRouterAddress={rewardRouterAddress}
        totalVesterRewards={processedData.totalVesterRewards}
        wrappedTokenSymbol={wrappedTokenSymbol}
        nativeTokenSymbol={nativeTokenSymbol}
        library={library}
        chainId={chainId}
      />
      <div className="section-title-block">
        <div className="section-title-icon">
          <img src={Earn} alt="" />
        </div>
        <div className="section-title-content">
          <div className="Page-title">
            <Trans>Earn</Trans>
          </div>
          <div className="Page-description">
            <Trans>
              Stake <ExternalLink href="https://docs.mold.trade/">MOLD</ExternalLink> and{" "}
              <ExternalLink href="https://docs.mold.trade/">MLP</ExternalLink> to earn rewards.
            </Trans>
          </div>
          {earnMsg && <div className="Page-description">{earnMsg}</div>}
        </div>
      </div>
      <div className="StakeV2-content">
        <div className="StakeV2-cards">
          <div className="App-card StakeV2-mold-card">
            <div className="App-card-title">MLDS</div>
            <StyledContent className="App-card-content">
              <div className="App-card-row">
                <div className="label">
                  <Trans>Price</Trans>
                </div>
                <div>
                  {!mldsPrice && "..."}
                  {mldsPrice && (
                    <Tooltip
                      position="right-bottom"
                      className="nowrap"
                      handle={"$" + formatAmount(mldsPrice, USD_DECIMALS, 2, true)}
                      renderContent={() => (
                        <>
                          {/* <StatsTooltipRow
                            label={t`Price on Avalanche`}
                            value={formatAmount(mldsPriceFromAvalanche, USD_DECIMALS, 2, true)}
                          />
                          <StatsTooltipRow
                            label={t`Price on Arbitrum`}
                            value={formatAmount(mldsPriceFromArbitrum, USD_DECIMALS, 2, true)}
                          /> */}
                        </>
                      )}
                    />
                  )}
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <Trans>APR</Trans>
                </div>
                <div>
                  <Tooltip
                    handle={`${formatKeyAmount(processedData, "moldAprTotalWithBoost", 2, 2, true)}%`}
                    position="right-bottom"
                    renderContent={() => (
                      <MOLDAprTooltip processedData={processedData} nativeTokenSymbol={nativeTokenSymbol} />
                    )}
                  />
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <Trans>Staked</Trans>
                </div>
                <div>
                  {formatKeyAmount(processedData, "moldInStakedMold", 18, 2, true)} MLDS ($
                  {formatKeyAmount(processedData, "moldInStakedMoldUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <Trans>Total Supply</Trans>
                </div>
                {!totalMoldSupply && "..."}
                {totalMoldSupply && (
                  <div>
                    {formatAmount(totalMoldSupply, 18, 0, true)} MLDS ($
                    {formatAmount(totalSupplyUsd, USD_DECIMALS, 0, true)})
                  </div>
                )}
              </div>
            </StyledContent>
            <StyledContent className="App-card-content">
              <div className="App-card-row">
                <div className="label">
                  <Trans>Your MLDS Balance</Trans>
                </div>
                <div>
                  {!mldsPrice && "..."}
                  {mldsPrice && (
                    <Tooltip
                      position="right-bottom"
                      className="nowrap"
                      handle={"$" + formatAmount(mldsPrice, USD_DECIMALS, 2, true)}
                      renderContent={() => (
                        <>
                          {/* <StatsTooltipRow
                            label={t`Price on Avalanche`}
                            value={formatAmount(mldsPriceFromAvalanche, USD_DECIMALS, 2, true)}
                          />
                          <StatsTooltipRow
                            label={t`Price on Arbitrum`}
                            value={formatAmount(mldsPriceFromArbitrum, USD_DECIMALS, 2, true)}
                          /> */}
                        </>
                      )}
                    />
                  )}
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <Trans>Your MLDS Rewards</Trans>
                </div>
                <div>
                  <Tooltip
                    handle={`${formatKeyAmount(processedData, "moldAprTotalWithBoost", 2, 2, true)}%`}
                    position="right-bottom"
                    renderContent={() => (
                      <MOLDAprTooltip processedData={processedData} nativeTokenSymbol={nativeTokenSymbol} />
                    )}
                  />
                </div>
              </div>
            </StyledContent>
            <StyledAppOptions className="App-card-options">
              <Link className="default-btn" to="/buy_mlds">
                <Trans>Buy MLDS</Trans>
              </Link>
              {active && (
                <button className="default-btn" onClick={() => showStakeMoldModal()}>
                  <Trans>Stake</Trans>
                </button>
              )}
              {active && (
                <button className="default-btn" onClick={() => showUnstakeMoldModal()}>
                  <Trans>Unstake</Trans>
                </button>
              )}
              {active && (
                <Link className="default-btn" to="/begin_account_transfer">
                  <Trans>Transfer Account</Trans>
                </Link>
              )}
            </StyledAppOptions>
          </div>
          <div className="App-card primary StakeV2-total-rewards-card">
            <div className="App-card-title">
              <Trans>Total Rewards</Trans>
            </div>
            <StyledContent className="App-card-content">
              <div className="App-card-row">
                <div className="label">Stablecoin Rewards</div>
                <div>
                  {formatKeyAmount(processedData, "totalVesterRewards", 18, 4, true)} ($
                  {formatKeyAmount(processedData, "totalVesterRewardsUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <Trans>veMLDS</Trans>
                </div>
                <div>
                  {formatKeyAmount(processedData, "totalEsMoldRewards", 18, 4, true)} ($
                  {formatKeyAmount(processedData, "totalEsMoldRewardsUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <StyledAppOptions className="App-card-options">
                {active && (
                  <button className="default-btn">
                    <Trans>Compound</Trans>
                  </button>
                )}
                {active && (
                  <button className="default-btn">
                    <Trans>Claim</Trans>
                  </button>
                )}
                {/* {!active && (
                  <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                    Connect Wallet
                  </button>
                )} */}
              </StyledAppOptions>
              <div className="App-card-bottom">
                <StyledAppOptions className="App-card-options">
                  {active && (
                    <button
                      className="App-button-option App-card-option"
                      onClick={() => setIsCompoundModalVisible(true)}
                    >
                      <Trans>Compound</Trans>
                    </button>
                  )}
                  {active && (
                    <button className="App-button-option App-card-option" onClick={() => setIsClaimModalVisible(true)}>
                      <Trans>Claim</Trans>
                    </button>
                  )}
                  {!active && (
                    <button className="connect-btn" onClick={() => connectWallet()}>
                      <Trans>Connect Wallet</Trans>
                    </button>
                  )}
                </StyledAppOptions>
              </div>
            </StyledContent>
          </div>
          <div className="App-card StakeV2-mold-card">
            <div className="App-card-title">MLP</div>
            <StyledContent className="App-card-content">
              <div className="App-card-row">
                <div className="label">
                  <Trans>Price</Trans>
                </div>
                <div>
                  {!mldsPrice && "..."}
                  {mldsPrice && (
                    <Tooltip
                      position="right-bottom"
                      className="nowrap"
                      handle={"$" + formatAmount(processedData.mlpBalance, USD_DECIMALS, 2, true)}
                      renderContent={() => (
                        <>
                          {/* <StatsTooltipRow
                            label={t`Price on Avalanche`}
                            value={formatAmount(mldsPriceFromAvalanche, USD_DECIMALS, 2, true)}
                          />
                          <StatsTooltipRow
                            label={t`Price on Arbitrum`}
                            value={formatAmount(mldsPriceFromArbitrum, USD_DECIMALS, 2, true)}
                          /> */}
                        </>
                      )}
                    />
                  )}
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <Trans>APR</Trans>
                </div>
                <div>
                  <Tooltip
                    handle={`${formatKeyAmount(processedData, "moldAprTotalWithBoost", 2, 2, true)}%`}
                    position="right-bottom"
                    renderContent={() => (
                      <MOLDAprTooltip processedData={processedData} nativeTokenSymbol={nativeTokenSymbol} />
                    )}
                  />
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <Trans>Staked</Trans>
                </div>
                <div>
                  {formatKeyAmount(processedData, "moldInStakedMold", 18, 2, true)} MLP ($
                  {formatKeyAmount(processedData, "moldInStakedMoldUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <Trans>Supply</Trans>
                </div>
                {!totalMoldSupply && "..."}
                {totalMoldSupply && (
                  <div>
                    {formatAmount(totalMoldSupply, 18, 0, true)} MLP ($
                    {formatAmount(totalSupplyUsd, USD_DECIMALS, 0, true)})
                  </div>
                )}
              </div>
            </StyledContent>
            <StyledContent className="App-card-content">
              <div className="App-card-row">
                <div className="label">
                  <Trans>Your MLP Balance</Trans>
                </div>
                <div>
                  {!mldsPrice && "..."}
                  {mldsPrice && (
                    <Tooltip
                      position="right-bottom"
                      className="nowrap"
                      handle={"$" + formatAmount(mldsPrice, USD_DECIMALS, 2, true)}
                      renderContent={() => (
                        <>
                          {/* <StatsTooltipRow
                            label={t`Price on Avalanche`}
                            value={formatAmount(mldsPriceFromAvalanche, USD_DECIMALS, 2, true)}
                          />
                          <StatsTooltipRow
                            label={t`Price on Arbitrum`}
                            value={formatAmount(mldsPriceFromArbitrum, USD_DECIMALS, 2, true)}
                          /> */}
                        </>
                      )}
                    />
                  )}
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <Trans>Your MLP Rewards</Trans>
                </div>
                <div>
                  <Tooltip
                    handle={`${formatKeyAmount(processedData, "moldAprTotalWithBoost", 2, 2, true)}%`}
                    position="right-bottom"
                    renderContent={() => (
                      <MOLDAprTooltip processedData={processedData} nativeTokenSymbol={nativeTokenSymbol} />
                    )}
                  />
                </div>
              </div>
            </StyledContent>
            <StyledAppOptions className="App-card-options">
              <Link className="default-btn" to="/buy_mlds">
                <Trans>Buy MLP</Trans>
              </Link>
              {active && (
                <button className="default-btn" onClick={() => showStakeMoldModal()}>
                  <Trans>Stake</Trans>
                </button>
              )}
              {active && (
                <button className="default-btn" onClick={() => showUnstakeMoldModal()}>
                  <Trans>Unstake</Trans>
                </button>
              )}
              {active && (
                <Link className="default-btn" to="/begin_account_transfer">
                  <Trans>Transfer Account</Trans>
                </Link>
              )}
            </StyledAppOptions>
          </div>
          <div className="App-card primary StakeV2-total-rewards-card">
            <div className="App-card-title">
              <Trans>Total Rewards</Trans>
            </div>
            <StyledContent className="App-card-content">
              <div className="App-card-row">
                <div className="label">Stablecoin Rewards</div>
                <div>
                  {formatKeyAmount(processedData, "totalVesterRewards", 18, 4, true)} ($
                  {formatKeyAmount(processedData, "totalVesterRewardsUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <Trans>veMLP</Trans>
                </div>
                <div>
                  {formatKeyAmount(processedData, "totalEsMoldRewards", 18, 4, true)} ($
                  {formatKeyAmount(processedData, "totalEsMoldRewardsUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <StyledAppOptions className="App-card-options">
                {active && (
                  <button className="default-btn">
                    <Trans>Compound</Trans>
                  </button>
                )}
                {active && (
                  <button className="default-btn">
                    <Trans>Claim</Trans>
                  </button>
                )}
                {/* {!active && (
                  <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                    Connect Wallet
                  </button>
                )} */}
              </StyledAppOptions>
              <div className="App-card-bottom">
                <StyledAppOptions className="App-card-options">
                  {active && (
                    <button
                      className="App-button-option App-card-option"
                      onClick={() => setIsCompoundModalVisible(true)}
                    >
                      <Trans>Compound</Trans>
                    </button>
                  )}
                  {active && (
                    <button className="App-button-option App-card-option" onClick={() => setIsClaimModalVisible(true)}>
                      <Trans>Claim</Trans>
                    </button>
                  )}
                  {!active && (
                    <button className="connect-btn" onClick={() => connectWallet()}>
                      <Trans>Connect Wallet</Trans>
                    </button>
                  )}
                </StyledAppOptions>
              </div>
            </StyledContent>
          </div>
        </div>
      </div>

      {/* <div>
        <div className="Tab-title-section">
          <div className="Page-title">
            <Trans>Vest</Trans>
          </div>
          <div className="Page-description">
            <Trans>
              Convert esMOLD tokens to MOLD tokens.
              <br />
              Please read the{" "}
              <ExternalLink href="https://moldio.gitbook.io/mold/rewards#vesting">vesting details</ExternalLink> before
              using the vaults.
            </Trans>
          </div>
        </div>
        <div>
          <div className="StakeV2-cards">
            <div className="App-card StakeV2-mold-card">
              <div className="App-card-title">
                <Trans>MOLD Vault</Trans>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-content">
                <div className="App-card-row">
                  <div className="label">
                    <Trans>Staked Tokens</Trans>
                  </div>
                  <div>
                    <Tooltip
                      handle={formatAmount(totalRewardTokens, 18, 2, true)}
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <>
                            <StatsTooltipRow
                              showDollar={false}
                              label="MOLD"
                              value={formatAmount(processedData.moldInStakedMold, 18, 2, true)}
                            />

                            <StatsTooltipRow
                              showDollar={false}
                              label="esMOLD"
                              value={formatAmount(processedData.esMoldInStakedMold, 18, 2, true)}
                            />
                            <StatsTooltipRow
                              showDollar={false}
                              label="Multiplier Points"
                              value={formatAmount(processedData.bnMoldInFeeMold, 18, 2, true)}
                            />
                          </>
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <Trans>Reserved for Vesting</Trans>
                  </div>
                  <div>
                    {formatKeyAmount(vestingData, "moldVesterPairAmount", 18, 2, true)} /{" "}
                    {formatAmount(totalRewardTokens, 18, 2, true)}
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <Trans>Vesting Status</Trans>
                  </div>
                  <div>
                    <Tooltip
                      handle={`${formatKeyAmount(vestingData, "moldVesterClaimSum", 18, 4, true)} / ${formatKeyAmount(
                        vestingData,
                        "moldVesterVestedAmount",
                        18,
                        4,
                        true
                      )}`}
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <div>
                            <Trans>
                              {formatKeyAmount(vestingData, "moldVesterClaimSum", 18, 4, true)} tokens have been
                              converted to MOLD from the{" "}
                              {formatKeyAmount(vestingData, "moldVesterVestedAmount", 18, 4, true)} esMOLD deposited for
                              vesting.
                            </Trans>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <Trans>Claimable</Trans>
                  </div>
                  <div>
                    <Tooltip
                      handle={`${formatKeyAmount(vestingData, "moldVesterClaimable", 18, 4, true)} MOLD`}
                      position="right-bottom"
                      renderContent={() => (
                        <Trans>
                          {formatKeyAmount(vestingData, "moldVesterClaimable", 18, 4, true)} MOLD tokens can be claimed,
                          use the options under the Total Rewards section to claim them.
                        </Trans>
                      )}
                    />
                  </div>
                </div>
                <div className="App-card-divider"></div>
                <div className="App-card-options">
                  {!active && (
                    <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                      <Trans>Connect Wallet</Trans>
                    </button>
                  )}
                  {active && (
                    <button className="App-button-option App-card-option" onClick={() => showMoldVesterDepositModal()}>
                      <Trans>Deposit</Trans>
                    </button>
                  )}
                  {active && (
                    <button className="App-button-option App-card-option" onClick={() => showMoldVesterWithdrawModal()}>
                      <Trans>Withdraw</Trans>
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="App-card StakeV2-mold-card">
              <div className="App-card-title">
                <Trans>MLP Vault</Trans>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-content">
                <div className="App-card-row">
                  <div className="label">
                    <Trans>Staked Tokens</Trans>
                  </div>
                  <div>{formatAmount(processedData.mlpBalance, 18, 2, true)} MLP</div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <Trans>Reserved for Vesting</Trans>
                  </div>
                  <div>
                    {formatKeyAmount(vestingData, "mlpVesterPairAmount", 18, 2, true)} /{" "}
                    {formatAmount(processedData.mlpBalance, 18, 2, true)}
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <Trans>Vesting Status</Trans>
                  </div>
                  <div>
                    <Tooltip
                      handle={`${formatKeyAmount(vestingData, "mlpVesterClaimSum", 18, 4, true)} / ${formatKeyAmount(
                        vestingData,
                        "mlpVesterVestedAmount",
                        18,
                        4,
                        true
                      )}`}
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <div>
                            <Trans>
                              {formatKeyAmount(vestingData, "mlpVesterClaimSum", 18, 4, true)} tokens have been
                              converted to MOLD from the{" "}
                              {formatKeyAmount(vestingData, "mlpVesterVestedAmount", 18, 4, true)} esMOLD deposited for
                              vesting.
                            </Trans>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <Trans>Claimable</Trans>
                  </div>
                  <div>
                    <Tooltip
                      handle={`${formatKeyAmount(vestingData, "mlpVesterClaimable", 18, 4, true)} MOLD`}
                      position="right-bottom"
                      renderContent={() => (
                        <Trans>
                          {formatKeyAmount(vestingData, "mlpVesterClaimable", 18, 4, true)} MOLD tokens can be claimed,
                          use the options under the Total Rewards section to claim them.
                        </Trans>
                      )}
                    />
                  </div>
                </div>
                <div className="App-card-divider"></div>
                <div className="App-card-options">
                  {!active && (
                    <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                      <Trans>Connect Wallet</Trans>
                    </button>
                  )}
                  {active && (
                    <button className="App-button-option App-card-option" onClick={() => showMlpVesterDepositModal()}>
                      <Trans>Deposit</Trans>
                    </button>
                  )}
                  {active && (
                    <button className="App-button-option App-card-option" onClick={() => showMlpVesterWithdrawModal()}>
                      <Trans>Withdraw</Trans>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Footer />
    </div>
  );
}

const StyledContent = styled.div`
  background: url(${EarnBg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 2rem 2.2rem;
  margin-bottom: 1rem;
`;

const StyledAppOptions = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 2.2rem;
  padding: 0 2.2rem;
  .connect-btn {
    width: 100%;
    border-radius: 1rem;
    border: 1px solid #fa9b00;
    background: none;
    color: #fa9b00;
    padding: 0.8rem 0;
  }
  .default-btn {
    margin-left: 1rem;
  }
`;
