import { ethers } from "ethers";
import { LOCALHOST, MUMBAI, CFX_TESTNET, CFX_MAINNET } from "./chains";

const { AddressZero } = ethers.constants;

export const XGMT_EXCLUDED_ACCOUNTS = [
  "0x330eef6b9b1ea6edd620c825c9919dc8b611d5d5",
  "0xd9b1c23411adbb984b1c4be515fafc47a12898b2",
  "0xa633158288520807f91ccc98aa58e0ea43acb400",
  "0xffd0a93b4362052a336a7b22494f1b77018dd34b",
];

const CONTRACTS = {
  [LOCALHOST]: {
    Vault: "0x5FbDB2315678afecb367f032d93F642f64180aa3",
    Router: "0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9",
    VaultReader: "0x7a2088a1bFc9d81c55368AE168C2C02570cB814F",
    Reader: "0x4A679253410272dd5232B3Ff7cF5dbB88f295319",
    MlpManager: "0x4ed7c70F96B99c776995fB64377f0d4aB3B0e1C1",
    RewardRouter: "0xa85233C63b9Ee964Add6F2cffe00Fd84eb32338f",
    RewardReader: "0x0000000000000000000000000000000000000000",
    NATIVE_TOKEN: "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0",
    MLP: "0x59b670e9fA9D0A427751Af201D676719a970857b",
    MOLD: "0x322813Fd9A801c5507c9de605d63CEA4f2CE6c44",
    ES_MOLD: "0x0000000000000000000000000000000000000000",
    BN_MOLD: "0x0000000000000000000000000000000000000000",
    USDM: "0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512",
    ES_MOLD_IOU: "0x0000000000000000000000000000000000000000",
    StakedMoldTracker: "0x0000000000000000000000000000000000000000",
    BonusMoldTracker: "0x0000000000000000000000000000000000000000",
    FeeMoldTracker: "0x0000000000000000000000000000000000000000",
    StakedMlpTracker: "0x0000000000000000000000000000000000000000",
    FeeMlpTracker: "0x0000000000000000000000000000000000000000",
    StakedMoldDistributor: "0x0000000000000000000000000000000000000000",
    StakedMlpDistributor: "0x0000000000000000000000000000000000000000",
    MoldVester: "0x0000000000000000000000000000000000000000",
    MlpVester: "0x0000000000000000000000000000000000000000",
    OrderBook: "0x0DCd1Bf9A1b36cE34237eEaFef220932846BCD82",
    OrderExecutor: "0x0000000000000000000000000000000000000000",
    OrderBookReader: "0x0000000000000000000000000000000000000000",
    PositionRouter: "0x0000000000000000000000000000000000000000",
    PositionManager: "0x9A676e781A523b5d0C0e43731313A708CB607508",
    TraderJoeMoldAvaxPool: "0x0000000000000000000000000000000000000000",
    ReferralStorage: "0x0000000000000000000000000000000000000000",
    ReferralReader: "0x0000000000000000000000000000000000000000",
    ShortsTracker: "0xA51c1fc2f0D1a1b8494Ed1FE312d7C3a78Ed91C0",
    VaultPriceFeed: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9",
  },
  [MUMBAI]: {
    Vault: "0xc54aa813eF930Aa3F3fc4C646e134a53D2d08277",
    Router: "0xDf6B69098507cbbE21f57BC7a58790D75A61AAdA",
    VaultReader: "0xdB6C38B998f2f8cF3f69806C48D0608Aa8980693",
    Reader: "0xA38860181D69566544EcC5839E0541CDf4e0d3eB",
    MlpManager: "0xe9A191E984422Eed9C1D46f287E9819c2d1BdB7C",
    RewardRouter: "0x69DC763488380051BC2B0420c8d15e8B588887dc",
    RewardReader: "0x0000000000000000000000000000000000000000",
    NATIVE_TOKEN: "0x09Ed1597f5803360189F39E3348Ea9C342Fd0233",
    MLP: "0x74D2848c45e9A52B53cDCCD2F66A04866E4CBFd6",
    MOLD: "0x6ea5fCF392AA7a092Bfc60EeC8625fbC9E39B74e",
    ES_MOLD: "0x0000000000000000000000000000000000000000",
    BN_MOLD: "0x0000000000000000000000000000000000000000",
    USDM: "0xe36A1CBd26e8e507f0Eb8252f952F196FE95FD5b",
    ES_MOLD_IOU: "0x0000000000000000000000000000000000000000",
    StakedMoldTracker: "0x0000000000000000000000000000000000000000",
    BonusMoldTracker: "0x0000000000000000000000000000000000000000",
    FeeMoldTracker: "0x0000000000000000000000000000000000000000",
    StakedMlpTracker: "0x0000000000000000000000000000000000000000",
    FeeMlpTracker: "0x0000000000000000000000000000000000000000",
    StakedMoldDistributor: "0x0000000000000000000000000000000000000000",
    StakedMlpDistributor: "0x0000000000000000000000000000000000000000",
    MoldVester: "0x0000000000000000000000000000000000000000",
    MlpVester: "0x0000000000000000000000000000000000000000",
    OrderBook: "0xeF10b34cEd3cFefaB6c0Da20d6615739b4ECc2Dd",
    OrderExecutor: "0x0000000000000000000000000000000000000000",
    OrderBookReader: "0x0000000000000000000000000000000000000000",
    PositionRouter: "0x0000000000000000000000000000000000000000",
    PositionManager: "0x388Ca3664Cb1635aE8348AB2A1Bb6C0652f5E693",
    TraderJoeMoldAvaxPool: "0x0000000000000000000000000000000000000000",
    ReferralStorage: "0x0000000000000000000000000000000000000000",
    ReferralReader: "0x0000000000000000000000000000000000000000",
    ShortsTracker: "0x165a116e1da7cf312085505475311d448Cd20890",
    VaultPriceFeed: "0x621Fc00Dd6Fc50204dcCaF84b03Cc991D5a2A218",
  },
  [CFX_TESTNET]: {
    Vault: "0x2AD24c89CbB32f9c36DE652ED26be45d197111dA",
    Router: "0x8E344E5Bea13d1CfAea88e3Df84fa6588AB0d480",
    Reader: "0x4827f098e8b88eC6732431c405f7dcfa93e0F217",
    MlpManager: "0x673682b6F39e0AcC9102d96D306913F844200AF5",
    RewardRouter: "0xc0feF370137787eEC54a046CeA0E96316A22FAC3",
    NATIVE_TOKEN: "0x1FC2A828d0cd3549BCADDFb50644E9C0cc5F062B",
    OrderBook: "0x0970cF407cf613E95671BACeE03BB65c815cDe36",
    OrderBookReader: "0xCc3Eb606cE6c3351E3657317b1B422C923C90Cbe",
    PositionManager: "0x01DC272f6992C1b43f98455097174EF3fB274899",
    MLP: "0x3D361d2ad0834865F5adC29729d2DDdEa6A9EB8f",
    MOLD: "0x3433c8A85dCB1b38ea7ba4d98FadB8a10D3eC9C7",
    MUSD: "0xfFA978356Be535C28871f9113A98DEA430e59B16",
    ShortsTracker: "0x32ED7C94c426C27e06392e1108e1f8D2ebc679D6",
    VaultPriceFeed: "0xbdb62A53E73f7a3F64781594788C47193220D650",

    NFT: "0xdE2C5bab5e649a0333ca2FDEcEA747879ee12Bc9",

    USDM: "0x0000000000000000000000000000000000000000",
    MlpVester: "0x0000000000000000000000000000000000000000",
  },
  [CFX_MAINNET]: {
    Vault: AddressZero,
    Router: AddressZero,
    Reader: AddressZero,
    MlpManager: AddressZero,
    RewardRouter: AddressZero,
    NATIVE_TOKEN: AddressZero,
    OrderBook: AddressZero,
    OrderBookReader: AddressZero,
    PositionManager: AddressZero,
    MLP: AddressZero,
    MOLD: AddressZero,
    MUSD: AddressZero,
    ShortsTracker: AddressZero,
    VaultPriceFeed: AddressZero,

    NFT: "0xdE2C5bab5e649a0333ca2FDEcEA747879ee12Bc9",

    USDM: "0x0000000000000000000000000000000000000000",
    MlpVester: "0x0000000000000000000000000000000000000000",
  },
};

export function getContract(chainId: number, name: string): string {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }

  if (!CONTRACTS[chainId][name]) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
  }

  return CONTRACTS[chainId][name];
}
