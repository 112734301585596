import { ethers } from "ethers";
import { getContract } from "./contracts";
import { LOCALHOST, MUMBAI, CFX_TESTNET, CFX_MAINNET } from "./chains";
import { Token } from "domain/tokens";
import mlpLogo from "img/l_MLPlogo.png";
import MSFTLogo from "img/MSFT.png";
import TSLALogo from "img/TSLA.png";

export const TOKENS: { [chainId: number]: Token[] } = {
  [LOCALHOST]: [
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "WETH",
      symbol: "WETH",
      address: "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0",
      decimals: 18,
      isWrapped: true,
      baseSymbol: "ETH",
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Dai",
      symbol: "DAI",
      decimals: 18,
      address: "0xa82fF9aFd8f496c3d6ac40E2a0F282E47488CFc9",
      isShortable: true,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/9956/thumb/4943.png?1636636734",
    },
    {
      name: "WMATIC",
      symbol: "WMATIC",
      address: "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0",
      decimals: 18,
      baseSymbol: "Matic",
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
  ],
  [MUMBAI]: [
    {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isTempHidden: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "WMATIC",
      symbol: "WMATIC",
      address: getContract(MUMBAI, "NATIVE_TOKEN"),
      decimals: 18,
      isWrapped: true,
      isShortable: true,
      baseSymbol: "Matic",
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "WETH",
      symbol: "WETH",
      address: "0xFD2808d7D994111CBC188D3A11Fbc107c8274a9D",
      decimals: 18,
      isShortable: true,
      baseSymbol: "ETH",
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Dai",
      symbol: "DAI",
      decimals: 18,
      address: "0x6fb5E203c9E218629428Ca86B0336C93C846B493",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/9956/thumb/4943.png?1636636734",
    },
    {
      name: "WBTC",
      symbol: "WBTC",
      decimals: 8,
      address: "0x6ae590a5710A9493DA8b13fFb624A597282d9B52",
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
    },
  ],
  [CFX_TESTNET]: [
    {
      name: "USDT",
      symbol: "USDT",
      decimals: 18,
      address: "0x9d37F1dcaa0721838932ade35ab61dc5f8B78Fe7",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/9956/thumb/4943.png?1636636734",
    },
    {
      name: "WBTC",
      symbol: "WBTC",
      decimals: 8,
      address: "0x0a4b34334EE3468f5D9E32C214905E3070817b12",
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
    },
    {
      name: "WETH",
      symbol: "WETH",
      decimals: 18,
      address: "0xb8fa6A7a156C99d0571e26951Ba41dF47F34D9a5",
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
    },
    {
      name: "CFX",
      symbol: "CFX",
      address: getContract(CFX_TESTNET, "NATIVE_TOKEN"),
      decimals: 18,
      isWrapped: true,
      isShortable: true,
      baseSymbol: "CFX",
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    /* {
      name: "LTC",
      symbol: "LTC",
      decimals: 18,
      address: "0xcb4982d6E595cd7F45e94ead35629f7061b5f85c",
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/2/small/litecoin.png?1547033580",
    },
    {
      name: "DOGE",
      symbol: "DOGE",
      decimals: 18,
      address: "0x7961150922fBd87c65c219D417356124Db51872e",
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/5/small/dogecoin.png?1547792256",
    },
    {
      name: "ARB",
      symbol: "ARB",
      decimals: 18,
      address: "0xe0e78CE085C9d8ed54927480B4aFECE9d5DB89e4",
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/16547/small/photo_2023-03-29_21.47.00.jpeg?1680097630",
    },
    {
      name: "FIL",
      symbol: "FIL",
      decimals: 18,
      address: "0x30f89256e337a3ad8A8Da460164309c8aF09e56e",
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/12817/small/filecoin.png?1602753933",
    },
    {
      name: "MSFT",
      symbol: "MSFT",
      decimals: 18,
      address: "0xdB50bC3d3229022Be91e99bab81F0C7EaCcb2bad",
      isShortable: true,
      imageUrl: MSFTLogo,
    },
    {
      name: "TSLA",
      symbol: "TSLA",
      decimals: 18,
      address: "0x150C52A0E78023500967E6Bacf1Ca46d6917459b",
      isShortable: true,
      imageUrl: TSLALogo,
    }, */
    {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isTempHidden: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
  ],
  [CFX_MAINNET]: [
    {
      name: "USDT",
      symbol: "USDT",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/9956/thumb/4943.png?1636636734",
    },
    // {
    //   name: "WBTC",
    //   symbol: "WBTC",
    //   decimals: 8,
    //   address: "0x0a4b34334EE3468f5D9E32C214905E3070817b12",
    //   isShortable: true,
    //   imageUrl: "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
    // },
    {
      name: "WETH",
      symbol: "WETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
    },
    // {
    //   name: "CFX",
    //   symbol: "CFX",
    //   address: getContract(CFX_TESTNET, "NATIVE_TOKEN"),
    //   decimals: 18,
    //   isWrapped: true,
    //   isShortable: true,
    //   baseSymbol: "CFX",
    //   imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    // },
    {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isTempHidden: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
  ],
};

export const ADDITIONAL_TOKENS: { [chainId: number]: Token[] } = {
  [MUMBAI]: [
    {
      name: "MOLD LP",
      symbol: "MLP",
      decimals: 18,
      address: getContract(MUMBAI, "MLP"), // address of fsMLP token because user only holds fsMLP
      imageUrl: mlpLogo,
    },
  ],
  [CFX_TESTNET]: [
    {
      name: "MOLD LP",
      symbol: "MLP",
      decimals: 18,
      address: getContract(CFX_TESTNET, "MLP"), // address of fsMLP token because user only holds fsMLP
      imageUrl: mlpLogo,
    },
  ],
};

export const PLATFORM_TOKENS: { [chainId: number]: { [symbol: string]: Token } } = {
  [LOCALHOST]: {
    MLP: {
      name: "MOLD LP",
      symbol: "MLP",
      decimals: 18,
      address: getContract(LOCALHOST, "MLP"), // address of fsMLP token because user only holds fsMLP
      imageUrl: "https://github.com/mold-io/mold-assets/blob/main/MOLD-Assets/PNG/MLP_LOGO%20ONLY.png?raw=true",
    },
  },
  [MUMBAI]: {
    MLP: {
      name: "MOLD LP",
      symbol: "MLP",
      decimals: 18,
      address: getContract(MUMBAI, "MLP"), // address of fsMLP token because user only holds fsMLP
      imageUrl: "",
    },
  },
  [CFX_TESTNET]: {
    MLP: {
      name: "MOLD LP",
      symbol: "MLP",
      decimals: 18,
      address: getContract(CFX_TESTNET, "MLP"), // address of fsMLP token because user only holds fsMLP
      imageUrl: "",
    },
  },
  [CFX_MAINNET]: {
    MLP: {
      name: "MOLD LP",
      symbol: "MLP",
      decimals: 18,
      address: getContract(CFX_MAINNET, "MLP"), // address of fsMLP token because user only holds fsMLP
      imageUrl: "",
    },
  },
};

export const ICONLINKS = {
  [LOCALHOST]: {
    MLP: {
      arbitrum: "https://testnet.arbiscan.io/token/0xb4f81Fa74e06b5f762A104e47276BA9b2929cb27",
    },
  },
  [MUMBAI]: {
    MLP: {
      mumbai: `https://mumbai.polygonscan.com/token/${getContract(MUMBAI, "MLP")}`,
    },
  },
  [CFX_TESTNET]: {
    MLP: {
      cfx: "",
    },
  },
  [CFX_MAINNET]: {
    MLP: {
      cfx: "",
    },
  },
};

export const MLP_POOL_COLORS = {
  WETH: "#ec1c79",
  CFX: "#2082a2",
  WBTC: "#fff",
  USDT: "#50af95",
};

export const TOKENS_MAP: { [chainId: number]: { [address: string]: Token } } = {};
export const TOKENS_BY_SYMBOL_MAP: { [chainId: number]: { [symbol: string]: Token } } = {};
export const WRAPPED_TOKENS_MAP: { [chainId: number]: Token } = {};
export const NATIVE_TOKENS_MAP: { [chainId: number]: Token } = {};

const CHAIN_IDS = [LOCALHOST, MUMBAI, CFX_TESTNET, CFX_MAINNET];

for (let j = 0; j < CHAIN_IDS.length; j++) {
  const chainId = CHAIN_IDS[j];
  TOKENS_MAP[chainId] = {};
  TOKENS_BY_SYMBOL_MAP[chainId] = {};
  let tokens = TOKENS[chainId];

  if (ADDITIONAL_TOKENS[chainId]) {
    tokens = tokens.concat(ADDITIONAL_TOKENS[chainId]);
  }

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    TOKENS_MAP[chainId][token.address] = token;

    TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token;
  }
}

for (const chainId of CHAIN_IDS) {
  for (const token of TOKENS[chainId]) {
    if (token.isWrapped) {
      WRAPPED_TOKENS_MAP[chainId] = token;
    } else if (token.isNative) {
      NATIVE_TOKENS_MAP[chainId] = token;
    }
  }
}

export function getWrappedToken(chainId: number) {
  return WRAPPED_TOKENS_MAP[chainId];
}

export function getNativeToken(chainId: number) {
  return NATIVE_TOKENS_MAP[chainId];
}

export function getTokens(chainId: number) {
  return TOKENS[chainId];
}

export function isValidToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  return address in TOKENS_MAP[chainId];
}

export function getToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  if (!TOKENS_MAP[chainId][address]) {
    localStorage.clear();
    window.location.reload();
    throw new Error(`Incorrect address "${address}" for chainId ${chainId}`);
  }
  return TOKENS_MAP[chainId][address];
}

export function getTokenBySymbol(chainId: number, symbol: string) {
  const token = TOKENS_BY_SYMBOL_MAP[chainId][symbol];
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`);
  }
  return token;
}

export function getWhitelistedTokens(chainId: number) {
  return TOKENS[chainId].filter((token) => token.symbol !== "USDM");
}

export function getVisibleTokens(chainId: number) {
  return getWhitelistedTokens(chainId).filter((token) => !token.isWrapped && !token.isTempHidden);
}
