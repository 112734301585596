import { createClient } from "./utils";
import { SUBGRAPH_URLS } from "config/subgraph";
import { LOCALHOST, CFX_TESTNET } from "config/chains";

export const chainlinkClient = createClient(SUBGRAPH_URLS.common.chainLink);

export const arbitrumGraphClient = createClient(SUBGRAPH_URLS[CFX_TESTNET].stats);
export const arbitrumReferralsGraphClient = createClient(SUBGRAPH_URLS[CFX_TESTNET].referrals);
export const nissohGraphClient = createClient(SUBGRAPH_URLS[CFX_TESTNET].nissohVault);

export const avalancheGraphClient = createClient(SUBGRAPH_URLS[LOCALHOST].stats);
export const avalancheReferralsGraphClient = createClient(SUBGRAPH_URLS[LOCALHOST].referrals);

export function getMoldGraphClient(chainId: number) {
  if (chainId === LOCALHOST) {
    return chainlinkClient;
  } else if (chainId === CFX_TESTNET) {
    return chainlinkClient;
  }

  throw new Error(`Unsupported chain ${chainId}`);
}
