import React, { useEffect, useRef } from "react";

let tvScriptLoadingPromise;

export default function TradingViewWidget({ symbol }) {
  const onLoadScriptRef = useRef();

  if (["WBTC", "WETH", "WAVAX", "WMATIC"].includes(symbol)) {
    symbol = symbol.substr(1);
  } else if (symbol === "BTC.b") {
    symbol = "BTC";
  }

  if (symbol !== "MSFT" && symbol !== "TSLA") {
    symbol += "USD";
  }

  useEffect(() => {
    onLoadScriptRef.current = createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement("script");
        script.id = "tradingview-widget-loading-script";
        script.src = "https://s3.tradingview.com/tv.js";
        script.type = "text/javascript";
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }

    tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

    return () => (onLoadScriptRef.current = null);

    function createWidget() {
      if (document.getElementById("tradingview") && "TradingView" in window) {
        new window.TradingView.widget({
          width: "100%",
          height: "100%",
          autosize: true,
          symbol: `PYTH:${symbol}`,
          interval: "60",
          timezone: "Etc/UTC",
          theme: "dark",
          style: "1",
          locale: "en",
          toolbar_bg: "#f1f3f6",
          enable_publishing: false,
          allow_symbol_change: true,
          container_id: "tradingview",
        });
      }
    }
  }, [symbol]);

  return (
    <div className="ExchangeChart-bottom-content App-box App-box-border">
      <div id="tradingview" />
    </div>
  );
}
