import { LOCALHOST, CFX_TESTNET, CFX_MAINNET } from "config/chains";
import cfx from "img/ic_cfx_24.svg";

import MLPIcon from "img/l_MLPlogo.png";

const ICONS = {
  [LOCALHOST]: {
    network: cfx,
    mlp: MLPIcon,
  },
  [CFX_TESTNET]: {
    network: cfx,
    mlp: MLPIcon,
  },
  [CFX_MAINNET]: {
    network: cfx,
    mlp: MLPIcon,
  },
  common: {
    mold: MLPIcon,
    mlp: MLPIcon,
  },
};

export function getIcon(chainId: number | "common", label: string) {
  if (chainId in ICONS) {
    if (label in ICONS[chainId]) {
      return ICONS[chainId][label];
    }
  }
}
export function getIcons(chainId: number | "common") {
  if (!chainId) return;
  if (chainId in ICONS) {
    return ICONS[chainId];
  }
}
